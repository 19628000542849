/* ==========================================
              MODAL CONTAINER
==========================================*/
.modal-container {
    position: absolute;
}

.modal-overley {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    ;
    z-index: 99;
}

.modal-overley--center {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
}

// .modal-overlay--right {
//     position: fixed;
//     right: 1rem;
//     bottom: 1rem;
//     height: 100vh;
//     width: 100vw;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-color: rgba(0, 0, 0, 0.7);
//     z-index: 99;
// }