%accordion-button-icon {
    height: 38px;
    width: 38px;
    content: "";
    right: 20px;
    top: 10px;
    background-size: cover;
    position: absolute;
}

.custom-accessible-accordion {

    .accordion__item {
        border-top: none !important;
        position: relative;

        &:not(:last-child) {
            margin-bottom: 14px;
        }
    }

    .accordion__heading {
        border-radius: 5px;

    }

    .accordion__button {
        font-size: 16px;
        font-weight: 500;
        box-shadow: 0 0 3px #ccc;
        border: 2px solid transparent;
        padding-right: 60px;

        z-index: 10;
        position: relative;

        &:focus {
            border: 2px solid #1f7999;
            border-image-source: linear-gradient(to right, #67d77d, #1f7999);
            border-image-slice: 2;
            box-shadow: 0 0 11px #ccc;
        }

        &:hover {
            background-color: #f4f4f4
        }

        &:before {
            display: none;
        }



        &:after {
            @extend %accordion-button-icon;
            background: url("../../Assets/icon/expand.png") center;
        }

        &[aria-expanded="true"]:after {
            @extend %accordion-button-icon;
            background: url("../../Assets/icon/expanded.png") center;
        }
    }

    .accordion__panel {
        font-size: 14px;
        background-color: #f5f5f5;
    }
}