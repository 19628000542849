.whats-new {
  background: #18948C;
  display: grid;
  grid-template-columns: 50% auto;
  align-items: center;
  overflow: hidden;

  @include respondTo(tablet) {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
  }
}

.whats-new__content {
  width: 100%;
  position: relative;
  padding: 73px 120px;

  @include respondTo(tablet) {
    padding: 24px 16px 0;
  }

  &::before {
    content: '';
    background-image: url(../../Assets/icon/blink.png);
    background-repeat: no-repeat;
    position: absolute;
    top: 7rem;
    left: -5rem;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '';
    background-repeat: no-repeat;
    background-image: url(../../Assets/icon/blink.png);
    position: absolute;
    bottom: 5rem;
    left: 30rem;
    width: 100%;
    height: 100%;

    @include respondTo(tablet) {
      bottom: 1rem;
      left: 17rem;
      background-size: 36px 53px;
    }
  }
}

.whats-new__card {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  max-width: 520px;
  overflow: hidden;
  margin-bottom: 20px;

  @include respondTo(tablet) {
    margin-top: 14px;
    margin-bottom: 0;
  }

  >img {
    width: 100%;
    height: 252px;
    object-fit: cover;

    @include respondTo(tablet) {
      height: 153px;
    }
  }
}

.whats-new__card-text {
  padding: 16px 24px;

  @include respondTo(tablet) {
    padding: 12px;
  }
}

.text__whats-new--title {
  font-weight: bold;
  font-size: 30px;
  line-height: 113%;
  color: #FBFEFE;

  @include respondTo(tablet) {
    font-size: 18px;
  }
}

.text__whats-new-card--title {
  font-weight: 600;
  font-size: 24px;
  line-height: 113%;
  color: rgba($color: #052523, $alpha: .66);
  margin-bottom: 8px;

  @include respondTo(tablet) {
    font-size: 18px;
  }
}

.text__whats-new--info {
  font-size: 15px;
  line-height: 147.5%;
  letter-spacing: 0.02em;
  color: #F7F8F8;
  margin-top: 16px;
  position: relative;

  @include respondTo(tablet) {
    font-size: 12px;
    line-height: 18px;
  }
}

.text__whats-new-card--info {
  font-size: 14px;
  line-height: 147.5%;
  letter-spacing: 0.02em;
  color: #031615;

  @include respondTo(tablet) {
    font-size: 12px;
  }
}