.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding: 99px 443px;
	z-index: 1;

	@media #{$tabletMQ} {
		padding: 0;
	}

	&__background {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: -1;
		border-radius: 0 0 0 20px;

		@media #{$tabletMQ} {
			width: 40%;
		}
	}

	&__card {
		background: #ffffff;
		box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
		border-radius: 20px;
		padding: 40px 106px;
		width: 35rem;
		position: relative;
		z-index: 1;

		@media #{$tabletMQ} {
			padding: 46px 16px;
			width: 100%;
			box-shadow: none;
			border-radius: 0;
		}
	}

	&__title {
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 24px;
		letter-spacing: 0.03em;
		color: #000000;
		text-align: center;
		margin-bottom: 42px;
	}

	// New Design
	&__sso {
		display: flex;
		align-items: center;
		flex-direction: column;

		&__title {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 14px;
			text-align: center;
			letter-spacing: 0.01em;
			color: #999999;
		}

		&__content {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 22px;

			&__google {
				display: grid;
				place-items: center;
				border: 1px solid #afb6b6;
				border-radius: 10px;
				padding: 13px 15px;
				// margin-right: 31px;
				cursor: pointer;

				.button-login {
					box-shadow: none !important;
					border: none !important;
					padding: 0px !important;

					>span {
						padding: 0 !important;
						margin: 0 !important;
					}
				}
			}

			&__facebook {
				display: grid;
				place-items: center;
				border: 1px solid #afb6b6;
				border-radius: 10px;
				padding: 13px 15px;
				cursor: pointer;

				button {
					width: 25px;
				}

				.button-login {
					border: none;
					padding: 0;
				}
			}
		}

		&__divider {
			margin-top: 25px;
			display: flex;
			align-content: space-between;
			align-items: center;
			margin-bottom: 31px;

			>div {
				width: 147.5px;
				border: 1px solid #f2f3f3;
			}

			>span {
				margin: 0 14px;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				text-align: center;
				letter-spacing: 0.01em;
				color: #333333;
				opacity: 0.5;
			}
		}
	}

	&__with {
		display: flex;
		align-items: center;

		margin-bottom: 24px;
		cursor: pointer;

		&__sso {
			border: 1px solid #17948c;
			box-sizing: border-box;
			border-radius: 10px;
			padding: 3px 19px;
			width: 100%;
			display: flex;
			align-items: center;

			&.google {
				button {
					width: 100%;
					box-shadow: none !important;

					div {
						display: flex;
					}
				}
			}
		}

		&__title {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 12px;
			letter-spacing: 0.01em;
			color: #333333;
		}
	}

	&__info {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 17px;
		text-align: center;
		letter-spacing: 0.01em;
		color: rgba($color: #333333, $alpha: 0.5);
		margin-bottom: 28px;
	}

	&__form {
		input {
			width: 100%;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			display: flex;
			align-items: center;
			letter-spacing: 0.01em;
			padding: 14px 18px;

			&::placeholder {
				color: #17948c;
			}
		}

		&__email {
			background: #f2f3f3;
			border-radius: 10px;
		}

		&__password {
			background: #f2f3f3;
			border-radius: 10px;
			display: flex;
			align-items: center;
			margin-bottom: 11px;

			svg {
				cursor: pointer;
				margin-right: 9px;
			}
		}

		&__error {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 10px;
			line-height: 124%;
			letter-spacing: 0.03em;
			color: #eb5757;
			margin-bottom: 10px;
			margin-left: 5px;
		}
	}

	&__forget {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		display: flex;
		letter-spacing: 0.03em;
		color: #17948c;
		cursor: pointer;
		margin-bottom: 32px;
		z-index: 1;
	}

	&__signup {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 18px;

		span {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 14px;
			letter-spacing: 0.01em;
			color: #031615;
		}

		button {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 14px;
			letter-spacing: 0.01em;
			color: #17948c;
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		margin-top: 32px;

		&__title {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 0.03em;
			color: #031615;
			margin-bottom: 20px;
		}

		&__cta {
			display: flex;
			justify-content: center;

			a {
				cursor: pointer;
				margin: 0 12px;

				@media #{$tabletMQ} {
					width: 40%;
				}

				&:nth-child(1) {
					margin-right: 24px;
				}
			}
		}
	}

	&__modal {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		background: #ffffff;
		border-radius: 20px;
		padding: 54px 52px 43px;
		width: 55rem;

		@media #{$tabletMQ} {
			width: 20rem;
			padding: 30px 20px;
		}

		&__profpict {
			margin-bottom: 18px;

			img {
				width: 100px;
				height: 100px;
				border: 1px solid #17948c;
				border-radius: 100%;
				object-fit: cover;
			}
		}

		&__title {
			font-style: normal;
			font-weight: 600;
			font-size: 21px;
			line-height: 31px;
			text-align: center;
			letter-spacing: 0.03em;
			color: #000000;
			margin-bottom: 16px;
		}

		&__membership {
			display: flex;
			align-items: center;
			justify-content: center;

			background: #d1f1ef;
			border-radius: 20px;
			padding: 8px;
			margin-bottom: 32px;

			img {
				width: 26px;
				height: 26px;
				object-fit: contain;
			}

			@media #{$tabletMQ} {
				margin-bottom: 16px;
			}

			div {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				text-align: center;
				letter-spacing: 0.01em;
				color: #0a423f;
				margin-left: 12px;
			}
		}

		&__info {
			font-style: normal;
			font-weight: 600;
			font-size: 21px;
			line-height: 31px;
			text-align: center;
			letter-spacing: 0.03em;
			color: #000000;
			margin-bottom: 52px;

			@media #{$tabletMQ} {
				font-size: 13px;
				line-height: 23px;
				margin-bottom: 24px;
			}
		}

		>span {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			text-align: center;
			letter-spacing: 0.03em;
			color: #4f4f4f;
			margin: 8px 0 -4px;

			@media #{$tabletMQ} {
				font-size: 11px;
				line-height: 15px;
				margin: 8px 0 0;
			}
		}

		>button {
			width: fit-content;
			padding: 12px 71px;

			@media #{$tabletMQ} {
				font-size: 12px;
				line-height: 20px;
				padding: 8px 20px;
			}
		}
	}
}