.empty-state-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;

    >img {
        width: 150px;
        height: 150px;
    }

    >div:nth-of-type(1) {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: #031615;
    }

    >div:nth-of-type(2) {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.3px;
        color: #4F4F4F;
    }
}