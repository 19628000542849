.campaign {
  position: relative;
  width: 785px;
  z-index: 60;

  @include respondTo(tablet) {
    width: 343px;
  }

  svg {
    position: absolute;
    right: 0;
    top: -1rem;
    transform: translateY(-36px);
    cursor: pointer;
  }
}

.campaign__card {
  background: #FFFFFF;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  position: relative;

  @include respondTo(tablet) {
    flex-direction: column;
  }

  img {
    position: absolute;
    width: 28rem;
    bottom: -1.5rem;
    left: -5rem;
    object-fit: contain;

    @include respondTo(tablet) {
      width: 15rem;
      top: 4.5rem;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.campaign__content {
  display: flex;
  flex-direction: column;
  padding: 29px 32px 38px;

  @include respondTo(tablet) {
    padding: 8px 16px 21px;
  }
}

.campaign__input {
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 15px 17px;
  margin-bottom: 13px;
  width: 100%;
  font-size: 15px;
  line-height: 123.5%;
  color: #828282;

  @include respondTo(tablet) {
    text-align: center;
    margin-bottom: 0;
    padding: 13px 17px;
  }
}

.campaign__signup-button {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;

  @include respondTo(tablet) {
    padding: 18px 24px;
  }

  button {
    margin-left: 0;
  }
}

.campaign__button {
  background: #17948C;
  border-radius: 9px;
  padding: 13px 18px;
  font-weight: bold;
  font-size: 18px;
  line-height: 113%;
  color: #FFFFFF;
  width: 100%;

  @include respondTo(tablet) {
    padding: 13px 14px;
    margin-left: 0;
    width: 100%;
    margin-top: 13px;
  }
}

.campaign__minimized {
  position: fixed;
  right: 5rem;
  bottom: 5rem;
  background: #FFFFFF;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 18px;
  padding-left: 5.7rem;
  width: fit-content;
  z-index: 99;
  cursor: pointer;

  @include respondTo(tablet) {
    right: 0.3rem;
    bottom: 5.5rem;
    padding: 15px;
    padding-left: 4.5rem;
  }

  >img {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-45%);
    width: 100px;
    height: 100px;

    @include respondTo(tablet) {
      transform: translateY(-30%);
      width: 78px;
      height: 78px;
    }
  }
}

.text__minimized--title,
.text__minimized--bold {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 103%;
  color: #000000;
  text-transform: uppercase;

  @include respondTo(tablet) {
    font-size: 16px;
    line-height: 24px;
  }
}

.text__minimized--bold {
  color: #16948C;
}

.text__modal-title {
  font-weight: bold;
  font-size: 48px;
  line-height: 103%;
  color: #000000;
  text-transform: uppercase;
  padding: 57px 14px 120px 302px;

  @include respondTo(tablet) {
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    padding: 0;
    padding-top: 194px;
  }
}

.text__modal-title--bold {
  color: #16948C;
}

.text__modal-info {
  font-weight: 500;
  font-size: 16px;
  line-height: 133.5%;
  color: #19291A;
  margin-bottom: 19px;

  @include respondTo(tablet) {
    font-size: 14px;
    padding: 0 15px;
    text-align: center;
    margin-bottom: 53px;
  }
}

.text__modal-discount {
  font-weight: bold;
  color: #2814A6;
  margin-top: -10px;
  margin-bottom: 19px;
}

.text__modal-label {
  display: block;
  font-weight: 500;
  font-size: 15px;
  line-height: 123.5%;
  color: #031615;
  margin-bottom: 7px;

  @include respondTo(tablet) {
    text-align: center;
  }
}


.successCampaign {
  position: relative;
  width: 580px;
  background: #FFFFFF;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  // overflow: hidden;

  @include respondTo(tablet) {
    width: 343px;
  }

  >svg {
    position: absolute;
    right: 0;
    top: -3rem;
    cursor: pointer;

    @include respondTo(tablet) {
      right: 0;
      top: 0;
      transform: translate(0, -36px);
    }

    path {
      fill: #E0E0E0;
    }

    img {
      margin: 23px 0;

      @include respondTo(tablet) {
        width: 220px;
      }

    }

    button {
      font-weight: bold;
      font-size: 18px;
      line-height: 113%;
      color: #FFFFFF;
      background: #17948C;
      border-radius: 9px;
      padding: 13px 86px;
      margin-top: 43px;
    }
  }

  .successCampaign__header {
    padding: 1rem;
  }

  .successCampaign__body {
    background: #17948C;
    border-radius: 0 0 20px 20px;
    padding: 1.5rem 6rem 2rem;

    @include respondTo(tablet) {
      padding: 27px 12px;
      overflow: hidden;
    }
  }

  .successCampaign__voucher {
    padding: 14px 30px 19px;
    background: #FFFFFF;
    border: 1px solid #D0E9E8;
    position: relative;

    &::before {
      content: '';
      background: #17948C;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      content: '';
      background: #17948C;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
    }
  }

  .successCampaign__voucher-code {
    background: #E7F8FE;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #127670;
    padding: 11px 16px;
    width: 100%;
    margin-right: 7px;
  }

  .successCampaign__voucher-action {
    margin-top: 8px;

    button {
      background: #FFFFFF;
      border: 1px solid #DBE1E1;
      box-sizing: border-box;
      border-radius: 10px;
      font-weight: 600;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.01em;
      color: #17948C;
      padding: 10px 20px;
    }
  }

  .successCampaign__tab-wrapper {
    padding-bottom: 14px;
    border-bottom: 1px solid #DBE1E1;
    margin-top: 24px;

    h5 {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
    }

    h5.active {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        height: 3px;
        width: 100%;
        background: #DBE1E1;
        bottom: -1rem;
        left: 0;
      }
    }
  }

  .successCampaign__content {
    padding: 17px 2rem;
    counter-reset: counter-name;


    p {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      font-weight: normal;
      margin: 0;
      position: relative;

      &::before {
        counter-increment: counter-name;
        content: counter(counter-name) ('. ');
        position: absolute;
        left: -1.5rem;
        display: grid;
        place-items: center;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .successCampaign__download {
    margin-top: 7px;

    a:first-of-type {
      margin-right: 12px;
    }

    img {
      width: 114px;
      height: 33px;
    }
  }

  .successCampaign__alert {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9.75px 24px;
    background: rgba($color: #00FF47, $alpha: 0.5);
    border-radius: 41px;
    margin-top: 24px;

    p {
      margin: 0;
      font-weight: 500;
      font-size: 10px;
      line-height: 22px;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      text-align: center;
      width: 100%;
    }
  }

  .text__modalSuccess-title {
    font-weight: bold;
    font-size: 50px;
    line-height: 75px;
    text-align: center;
    color: #000000;

    @include respondTo(tablet) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .text__modalSuccess-info {
    font-weight: 500;
    font-size: 14px;
    line-height: 113%;
    text-align: center;
    color: #19291A;
    margin: 0;

    @include respondTo(tablet) {
      font-size: 14px;
      padding: 0 48px;
    }
  }

  .text__voucher-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #323232;
  }

  .campaign__error {
    position: absolute;
    left: 0;
    bottom: -1.5rem;
    font-size: 10px;
    font-style: italic;
    color: #F14C4C;
  }
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}