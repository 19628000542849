.dooliveFit {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 60px 112px;

   @include respondTo(tablet) {
      padding: 60px 16px;
      flex-direction: column;
   }
}

.dooliveFit__content {
   padding-right: 4rem;

   @include respondTo(tablet) {
      padding: 0;
   }
}

.dooliveFit--title {
   display: flex;
   align-items: center;

   @include respondTo(tablet) {
      margin-bottom: 8px;
   }

   svg {
      margin-left: 20px;

      @include respondTo(tablet) {
         margin-left: 8px;
      }
   }
}

.dooliveFit__hero {
   img {
      width: 100%;
   }
}

.text__dooliveFit--title {
   font-weight: bold;
   font-size: 30px;
   line-height: 64px;
   color: #0A423F;

   @include respondTo(tablet) {
      font-size: 18px;
      line-height: 113%;
      margin-bottom: 14px;
   }
}

.text__dooliveFit--info {
   font-size: 18px;
   line-height: 32px;
   color: #0A423F;

   @include respondTo(tablet) {
      font-size: 12px;
      line-height: 147.5%;
      margin: 12px 0 27px;
   }
}