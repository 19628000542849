.home {
	@include respondTo(tablet) {
		overflow: hidden;
	}
}

.home-banner {
	position: relative;
	width: 100%;
	min-height: 570px;


	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 65%;
		height: 100%;
		z-index: 2;
		background: linear-gradient(89.71deg, rgba(14, 99, 93, 0.7) 2.75%, rgba(101, 145, 143, 0.96) 38.87%, rgba(196, 196, 196, 0) 77.94%);

		@include respondTo(tablet) {
			background: linear-gradient(105deg, rgba(14, 99, 93, 0.7) 2.75%, rgba(101, 145, 143, 0.96) 38.87%, rgba(196, 196, 196, 0) 77.94%);
			width: 120%;
		}

	}

	>img {
		width: 100%;
		min-height: 570px;
		object-fit: cover;

		@include respondTo(tablet) {
			height: 618px;
			object-position: -23rem;
		}
	}
}

.home-banner__text {
	position: absolute;
	z-index: 4;
	top: 104px;
	left: 120px;

	@include respondTo(tablet) {
		top: 93px;
		left: 16px;
		padding-right: 14px;
	}
}

.banner-text__title {
	font-weight: bold;
	font-size: 50px;
	line-height: 113%;
	color: #FFFFFF;

	@include respondTo(tablet) {
		font-size: 36px;
	}

	span {
		display: block;
	}
}

.banner-text__sub {
	font-weight: 500;
	font-size: 20px;
	line-height: 147.5%;
	letter-spacing: 0.02em;
	color: #FFFFFF;
	margin: 14px 0 42px;

	@include respondTo(tablet) {
		font-size: 14px;
		margin: 6px 0 23px;
	}
}

.banner-text__info {
	font-weight: bold;
	font-size: 40px;
	line-height: 113%;
	color: #FFFFFF;
	margin-bottom: 59px;
	display: flex;

	@include respondTo(tablet) {
		font-size: 24px;
		margin-bottom: 20px;
	}

	span {
		display: inline-block;
		margin-left: 0;
		position: relative;
		padding: 0 13px 12px;

		@include respondTo(tablet) {
			margin-left: 10px;
			padding: 0 3px 6px;
		}

		&::before {
			content: '';
			border-bottom: 4px solid rgba($color: #16948C, $alpha: 0.4);
			animation: animate ease-in infinite 14s;
		}

		@keyframes animate {
			0% {
				content: 'Yoga';
				opacity: 0;
			}

			5% {
				content: 'Yoga';
				opacity: 1;
			}

			19% {
				content: 'Yoga';
				opacity: 0;
			}

			20% {
				content: 'Zumba';
				opacity: 0;
			}

			25% {
				content: 'Zumba';
				opacity: 1;
			}

			39% {
				content: 'Zumba';
				opacity: 0;
			}

			40% {
				content: 'Online Classes';
				opacity: 0;
			}

			45% {
				content: 'Online Classes';
				opacity: 1;
			}

			59% {
				content: 'Online Classes';
				opacity: 0;
			}

			60% {
				content: 'Healthy Food';
				opacity: 0;
			}

			65% {
				content: 'Healthy Food';
				opacity: 1;
			}

			79% {
				content: 'Healthy Food';
				opacity: 0;
			}

			80% {
				content: 'Private Training';
				opacity: 0;
			}

			85% {
				content: 'Private Training';
				opacity: 1;
			}

			99% {
				content: 'Private Training';
				opacity: 0;
			}

			100% {
				content: 'Yoga';
				opacity: 0;
			}
		}
	}
}

.home-banner__action {
	button {
		margin-right: 21px;
		background: #16948C;
		border-radius: 15px;
		padding: 17px 43px;
		font-weight: bold;
		font-size: 18px;
		line-height: 113%;
		color: #FFFFFF;

		@include respondTo(tablet) {
			padding: 12px 14px;
		}
	}

	img {
		width: 114px;
		height: 33px;

		&:nth-of-type(1) {
			margin-right: 4px;
		}
	}

	@include respondTo(tablet) {
		a:not(:first-of-type) {
			display: none;
		}
	}
}

.home__video {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 94px 133px;
	background-color: #093B38;

	@include respondTo(tablet) {
		padding: 18px 16px;
		flex-direction: column;
	}


	&__content {
		max-width: clamp(486px, 50%, 650px);
		color: #FAFAFA;
		margin-bottom: 12px;


		h3 {
			font-weight: bold;
			font-size: 30px;
			line-height: 113%;
			margin-bottom: 18px;

			@include respondTo(tablet) {
				font-size: 18px;
				line-height: 113%;
			}
		}

		>span {
			font-size: 15px;
			line-height: 147.5%;
			letter-spacing: 0.02em;

			@include respondTo(tablet) {
				font-size: 12px;
				line-height: 147.5%;
				letter-spacing: 0.02em;
			}
		}
	}

	&__player {
		width: 491px;
		height: 281px;
		border-radius: 30px;

		@include respondTo(tablet) {
			width: 100%;
			height: 324px;
		}

		@include respondTo(phone) {
			width: 100%;
			height: 196px;
		}

		>* {
			width: inherit;
			height: inherit;
			object-fit: cover;
			border-radius: inherit;
		}

		iframe {
			width: inherit;
			height: inherit;
			object-fit: cover;
			border-radius: inherit;
		}


	}



}

.home__feature {
	display: flex;
	background-color: #FFFFFF;

	@include respondTo(tablet) {
		flex-direction: column;
	}

	&__statistic {
		display: flex;
		flex-direction: column;

		max-width: 50%;
		padding: 94px 133px;

		background-color: #16948C;
		border-radius: 0px 0px 157px 0px;
		color: #FAFAFA;

		@include respondTo(tablet) {
			padding: 18px 16px 48px;
			flex-direction: column;
			max-width: unset;
			width: 100%;
			border-radius: 0px 0px 80px 0px;

		}

		>h3 {
			font-weight: bold;
			font-size: 30px;
			line-height: 113%;
			margin-bottom: 22px;

			@include respondTo(tablet) {
				font-size: 18px;
				line-height: 113%;
			}

		}

		>span {
			font-size: 15px;
			line-height: 147.5%;
			letter-spacing: 0.02em;
			margin-bottom: 65px;

			@include respondTo(tablet) {
				font-size: 12px;
				line-height: 147.5%;
				letter-spacing: 0.02em;

				margin-bottom: 12px;
			}
		}

		&__grid {
			display: grid;
			grid-gap: 33px;
			grid-template-columns: repeat(2, 1fr);
			width: fit-content;

			@include respondTo(tablet) {
				grid-template-columns: repeat(4, 1fr);
				width: 100%;
				grid-gap: unset;
			}

			@include respondTo(small--phone) {
				grid-template-columns: repeat(2, 1fr);
				width: 100%;
				grid-gap: unset;
			}


			&__item {
				display: flex;
				align-items: center;

				@include respondTo(desktop) {
					&:nth-child(odd) {
						border-right: 1px solid #FAFAFA;
						padding: 12px 68px 12px 0;
					}

					&:nth-child(even) {
						padding: 12px 68px 12px;
					}
				}

				@media screen and (max-width: 991px) and (min-width: 331px) {
					padding: 18px;

					&:not(:last-child) {
						border-right: 1px solid #FAFAFA;
					}
				}

				@include respondTo(small--phone) {
					padding: 18px;

					&:nth-child(odd) {
						border-right: 1px solid #FAFAFA;
					}
				}

				>svg {
					margin-right: 21px;
					min-width: 32px;
					min-height: 32px;

					@include respondTo(tablet) {
						display: none;
					}
				}

				&__value {
					width: 100%;
					display: flex;
					flex-direction: column;

					>span {
						text-align: center;
					}

					>span:nth-child(1) {
						font-weight: bold;
						font-size: 35px;
						line-height: 113%;
						text-align: center;

						@include respondTo(tablet) {
							font-size: 24px;
							line-height: 113%;
						}
					}

					>span:nth-child(2) {
						font-size: 15px;
						line-height: 147.5%;
						text-align: center;
						letter-spacing: 0.02em;

						@include respondTo(tablet) {
							font-size: 11px;
							line-height: 147.5%;
							letter-spacing: 0.02em
						}

					}
				}
			}
		}

	}

	&__benefit {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #FFFFFF;

		padding: 89px 0;
		width: 100%;

		@include respondTo(tablet) {
			padding: 34px 16px;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-evenly;
		}


		&__item {
			display: flex;
			align-items: center;

			width: 420px;

			@include respondTo(tablet) {
				flex-direction: column;
				align-items: center;
			}

			@include respondTo(desktop) {
				&:not(:last-child) {
					margin-bottom: 44px;
				}
			}

			>img {
				width: 67px;
				height: 67px;
				object-fit: cover;
				margin-right: 18px;

				@include respondTo(tablet) {
					width: 50px;
					height: 50px;
					margin-right: unset;
					margin-bottom: 8px;
				}
			}

			&__text {
				display: flex;
				flex-direction: column;


				>span:nth-child(1) {
					font-weight: bold;
					font-size: 20px;
					line-height: 113%;
					color: #19291A;


					@include respondTo(tablet) {
						font-weight: 600;
						font-size: 12px;
						line-height: 12px;
						text-align: center;
					}
				}

				>span:nth-child(2) {
					font-size: 12px;
					line-height: 18px;
					letter-spacing: 0.03em;
					color: #4F4F4F;

					@include respondTo(tablet) {
						display: none;
					}
				}
			}
		}
	}
}

.home__product {
	display: flex;
	flex-direction: column;
	padding: 94px 133px;
	background-color: #FFFFFF;

	@include respondTo(tablet) {
		padding: 0 0 18px;
	}

	>h3 {
		font-weight: bold;
		font-size: 30px;
		line-height: 113%;
		color: #19291A;
		margin-bottom: 16px;

		@include respondTo(tablet) {
			margin-bottom: 8px;
			font-size: 18px;
			line-height: 113%;
			padding: 0 16px 0;
		}
	}

	>span {
		font-size: 15px;
		line-height: 147.5%;
		letter-spacing: 0.02em;
		color: #0A423F;

		@include respondTo(tablet) {
			padding: 0 16px 0;
		}
	}

	>div {
		display: flex;
		margin-top: 28px;


		@include respondTo(tablet) {
			flex-direction: column;
		}

		@include respondTo(desktop) {
			flex-direction: column;
		}

		@include respondTo(large) {
			flex-direction: row;
		}
	}

	&__desc {
		display: flex;
		flex-direction: column;
		z-index: 2;

		&__item {
			background: #F2F4F3;
			border: 1px solid #BDBDBD;
			box-sizing: border-box;
			border-radius: 15px;
			padding: 18px;
			margin-bottom: 36px;

			@include respondTo(tablet) {
				border-radius: unset;
			}

			>div:nth-child(1) {
				display: flex;
				align-items: center;
				margin-bottom: 35px;

				>img {
					width: 47px;
					height: 47px;
					object-fit: cover;
					margin-right: 14px;

				}


				>span {
					font-weight: bold;
					font-size: 30px;
					line-height: 113%;
					color: #4F4F4F;

					@include respondTo(tablet) {
						font-size: 18px;
						line-height: 113%;
					}
				}

				>a {
					margin-left: auto;
					padding: 13px 19px;
					background: #16948C;
					border-radius: 10px;

					font-weight: bold;
					font-size: 18px;
					line-height: 113%;
					color: #FFFFFF;


					@include respondTo(tablet) {
						font-size: 12px;
						line-height: 113%;
					}
				}
			}


			&__grid {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 12px 22px;

				@include respondTo(tablet) {
					padding: 2px 2px 12px;
					overflow-x: auto;
					grid-template-columns: unset;
					grid-auto-flow: column;

				}

				@include respondTo(desktop) {
					padding: 2px 2px 12px;
					overflow-x: auto;
					grid-template-columns: unset;
					grid-auto-flow: column;
				}

				@include respondTo(large) {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 12px 22px;
					grid-auto-flow: unset;
				}


				&__content {
					background: #FFFFFF;
					box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
					border-radius: 10px;

					display: flex;
					padding: 18px;
					width: 289px;
					transform: scale(0.98);

					@include respondTo(tablet) {
						padding: 8px;
						max-width: 224px;
					}


					>img {
						align-items: flex-start;
						width: 32px;
						height: 32px;
						margin-right: 8px;

						@include respondTo(tablet) {
							width: 24px;
							height: 24px;
						}
					}

					>div {
						display: flex;
						flex-direction: column;
						max-width: 207px;

						>span {
							cursor: default;
						}

						>span:nth-of-type(1) {
							font-weight: bold;
							font-size: 18px;
							line-height: 20px;
							font-feature-settings: 'liga'off;
							color: #4F4F4F;
							margin-bottom: 8px;

							@include respondTo(tablet) {
								line-height: 20px;
								font-size: 14px;
							}
						}

						>span:nth-of-type(2) {
							font-size: 12px;
							line-height: 16px;
							letter-spacing: 0.03em;
							color: #4F4F4F;

							@include respondTo(tablet) {
								font-size: 12px;
								line-height: 18px;
								font-weight: 500;

							}
						}
					}
				}

				&__content--active {
					@extend .home__product__desc__item__grid__content;
					transition: 0.5s;
					transform: scale(1);
					background-color: #D1F1EF;
				}
			}
		}
	}

	&__img {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;

		> .image:nth-child(1) {
			position: absolute;
			top: 300px;

		}

		> .image:nth-child(2) {
			align-self: flex-start;
			z-index: 2;

			@include respondTo(tablet) {
				width: 100%;
			}
		}
	}
}