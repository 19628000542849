.detailBookingDekstop {
	display: flex;
	justify-content: space-between;
	padding: 46px 112px;

	&__paymentInfo {
		width: 65%;

		&__banner {
			margin-bottom: 32px;
			display: flex;
			border-radius: 20px;
			height: 229px;
			background-color: #fff;

			&__image {
				border-radius: 20px;
				width: 100%;
			}

			&__slide {
				border-radius: 20px;
				display: none;
			}
		}

		&__content {
			background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
			border-radius: 20px;

			@media #{$tabletMQ} {
				margin-top: -25px;
				z-index: 2;
				box-shadow: none;
				position: relative;
			}

			&__wrap {
				padding: 32px 48px;

				&__paymentTitle {
					@media #{$tabletMQ} {
						display: none;
					}

					font-style: normal;
					font-weight: 600;
					font-size: 24px;
					line-height: 36px;
					letter-spacing: 0.03em;
					color: #000000;
					margin-bottom: 47px;
				}

				&__booking {
					display: flex;
					align-items: center;
					justify-content: space-between;

					> p {
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 17px;
						letter-spacing: 0.01em;
						color: #495050;
					}

					> div {
						font-style: normal;
						font-weight: normal;
						font-size: 18px;
						line-height: 24px;
						letter-spacing: 0.03em;
						color: #828282;
					}

					&__bookingGopay {
						background: #ffffff;
						box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
						border-radius: 20px;
						padding: 40px 32px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						> img {
							width: 280px;
							height: 273px;
						}

						> p {
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 19px;
							text-align: center;
							letter-spacing: 0.03em;
							color: #333333;
							margin-bottom: 38px;
						}
					}
				}

				&__paymentStatus {
					margin-top: 28px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					> p {
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 17px;
						letter-spacing: 0.01em;
						color: #495050;
					}

					> div {
						background: #fad1d1;
						border-radius: 15px;
						padding: 8px 12px;

						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 27px;
						text-align: right;
						letter-spacing: 0.03em;
						color: #eb5757;
					}
				}

				&__paymentSuccess {
					margin-top: 28px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					&__line {
						width: 100%;
						height: 0px;
						left: 113px;
						border: 1px solid #afb6b6;
						margin-top: 35px;
						margin-bottom: 22px;
					}

					> p {
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 17px;
						letter-spacing: 0.01em;
						color: #495050;
					}

					> div {
						background: #d5f6e3;
						border-radius: 15px;
						padding: 8px 12px;

						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 27px;
						text-align: right;
						letter-spacing: 0.03em;
						color: #27ae60;
					}

					&__sectionTitle {
						font-family: Poppins;
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						align-items: center;
						letter-spacing: 0.03em;
						color: #000000;
						margin-bottom: 20px;

						&__row {
							display: flex;
							justify-content: space-between;
							margin-bottom: 20px;
						}

						&__title {
							font-family: Poppins;
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 19px;
							display: flex;
							align-items: center;
							letter-spacing: 0.03em;
							color: #000000;
						}

						&__greyTitle {
							font-family: Poppins;
							font-style: normal;
							font-weight: normal;
							font-size: 18px;
							line-height: 24px;
							text-align: right;
							letter-spacing: 0.03em;
							color: #828282;
						}
					}
				}

				&__paymentCanceled {
					margin-top: 28px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					> p {
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 17px;
						letter-spacing: 0.01em;
						color: #495050;
					}

					> div {
						background: #fcefcf;
						border-radius: 15px;
						padding: 8px 12px;

						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 27px;
						text-align: right;
						letter-spacing: 0.03em;
						color: #604606;
					}
				}

				&__paymentBeforeTitle {
					margin-top: 32px;
					margin-bottom: 12px;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #495050;
				}

				&__countdown {
					width: 100%;
					background: #fad1d1;
					border-radius: 15px;
					padding: 16px 18px;
					display: flex;
					justify-content: flex-end;
					align-items: center;

					> h3 {
						display: none;
					}

					> p {
						display: flex;
						align-items: center;
						justify-content: space-between;
						// margin-left: 50%;
						margin: auto;

						> div {
							font-style: normal;
							font-weight: 600;
							font-size: 24px;
							line-height: 17px;
							letter-spacing: 0.01em;
							color: #eb5757;
							margin: 0 4px;
						}
					}

					> div {
						background: rgba(250, 63, 63, 0.15);
						border: 1px solid #eb5757;
						box-sizing: border-box;
						border-radius: 7px;
						padding: 6px 24px;

						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 124%;
						text-align: center;
						letter-spacing: 0.03em;
						color: #eb5757;
						cursor: pointer;
					}
				}

				&__amount {
					margin-top: 32px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					> p {
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 17px;
						letter-spacing: 0.01em;
						color: #495050;
					}

					> div {
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 17px;
						letter-spacing: 0.01em;
						color: #17948c;
					}
				}

				&__transferTitle {
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 19px;
					letter-spacing: 0.01em;
					color: #495050;
					margin-top: 32px;
				}

				&__infoBank {
					margin-bottom: 32px;
					display: flex;
					margin-top: 12px;
					justify-content: space-between;
					align-items: center;
					background: rgba(29, 186, 175, 0.15);
					border-radius: 10px;
					padding: 13px 16px;

					> div {
						> p {
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 17px;
							letter-spacing: 0.01em;
							color: #031615;
						}
					}

					> button {
						background: rgba(29, 186, 175, 0.16);
						border: 1px solid #1dbaaf;
						box-sizing: border-box;
						border-radius: 7px;
						padding: 6px 11px;

						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 124%;
						letter-spacing: 0.03em;
						color: #1dbaaf;
					}
				}
			}

			&__howToPay {
				background: #f7f8f8;
				border-radius: 0px 0px 20px 20px;
				padding: 40px 48px;

				&__title {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 27px;

					> p {
						font-weight: 500;
						font-size: 18px;
						line-height: 14px;
						letter-spacing: 0.03em;
						color: #333333;
					}

					> img {
						cursor: pointer;
					}
				}

				&__step {
					background-color: #fff;
					padding: 19px 45px;
					border-radius: 10px;

					> span {
						margin-bottom: 10px;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.01em;
						color: #000000;
					}

					> table {
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 17px;
						letter-spacing: 0.01em;
						color: #000000;
					}

					> button {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 14px;
						letter-spacing: 0.03em;
						color: #17948c;
					}
				}

				&__button {
					margin-top: 32px;
					display: flex;
					justify-content: flex-end;
					align-items: center;

					> button {
						background: #17948c;
						border-radius: 10px;
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 24px;
						text-align: center;
						letter-spacing: 0.03em;
						color: #ffffff;
						padding: 12px 62px;
					}
				}

				&__button--disabled {
					margin-top: 32px;
					display: flex;
					justify-content: flex-end;
					align-items: center;

					> button {
						background: #c9cfce;
						border-radius: 10px;
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 24px;
						text-align: center;
						letter-spacing: 0.03em;
						color: #ffffff;
						padding: 12px 62px;
					}
				}
			}
		}
	}

	&__booking {
		display: flex;
		flex-direction: column;
		width: 33%;

		&__bookingInfo {
			background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
			border-radius: 20px;
			padding: 31px 23px;
			height: '100%';
			margin-bottom: 22px;

			&__guestTitle {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
				letter-spacing: 0.03em;
				color: #333333;
			}

			&__guestContent {
				margin-top: 12px;
				background: #ffffff;
				border: 1px solid #e0e0e0;
				box-sizing: border-box;
				border-radius: 10px;
				padding: 2px 18px 18px 18px;
				margin-bottom: 32px;

				&__item {
					display: flex;
					align-items: center;
					margin-top: 12px;

					> p {
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 17px;
						display: flex;
						align-items: center;
						letter-spacing: 0.01em;
						color: #000000;
					}

					> div {
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 17px;
						display: flex;
						align-items: center;
						letter-spacing: 0.01em;
						color: #828282;
					}
				}
			}

			&__classTitle {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
				letter-spacing: 0.03em;
				color: #333333;
			}

			&__classContent {
				margin-top: 12px;
				background: #ffffff;
				border: 1px solid #e0e0e0;
				box-sizing: border-box;
				border-radius: 10px;
				padding: 18px;
				display: flex;
				align-items: center;

				&__icon {
					width: 62px;
					height: 62px;
					background: #e9fcfa;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 24px;
					border-radius: 62px;
				}

				&__info {
					> p {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
						mix-blend-mode: normal;
					}

					&__venue {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #828282;
						mix-blend-mode: normal;
						margin-top: 12px;
						margin-bottom: 15px;
					}

					&__time {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-bottom: 15px;

						> img {
							margin-left: -4px;
							margin-right: 9px;
						}

						> p {
							margin-right: 7px;
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 14px;
							letter-spacing: 0.03em;
							color: #2a2a2a;
						}

						> div {
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							line-height: 18px;
							letter-spacing: 0.01em;
							color: #2a2a2a;
							mix-blend-mode: normal;
							opacity: 0.4;
						}
					}

					&__date {
						display: flex;
						align-items: center;
						justify-content: flex-start;

						> img {
							margin-right: 12px;
						}

						> p {
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 14px;
							letter-spacing: 0.03em;
							color: #2a2a2a;
						}
					}
				}
			}
		}

		&__trainerContact {
			background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
			border-radius: 20px;
			padding: 25px 23px;
			height: 73px;
			margin-bottom: 22px;
			display: flex;
			justify-content: space-between;

			&__leftContent {
				display: flex;
				justify-content: space-between;
			}

			&__rightContent {
				align-self: center;
			}

			&__trainerTitle {
				font-family: Poppins;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				color: #17948C;
				margin-left: 15px;
				align-self: center;
			}
		}

		&__buttonBook {
			margin-bottom: 100px;
		}

		&__live {
			width: 100%;
			height: 78px;
			padding: 14px 18px;
			background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
			border-radius: 20px;
			margin-bottom: 22px;

			&__row {
				display: flex;
				justify-content: space-between;
				align-items: center;

				&__title {
					font-family: Poppins;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 27px;
					letter-spacing: 0.03em;
					color: #333333;
				}

				&__link {
					width: 447px;
					height: 44px;
					background: #f7f8f8;
					border: 1px solid #c9cfce;
					box-sizing: border-box;
					border-radius: 10px;
					padding: 12px 18px;
					display: flex;
					justify-content: space-between;
					margin: 0 21px;

					> p {
						font-family: Poppins;
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 17px;
						letter-spacing: 0.01em;
						color: #17948c;

						height: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1; /* number of lines to show */
						-webkit-box-orient: vertical;
					}
				}

				&__button {
					background: #17948c;
					border-radius: 15px;
					width: 164px;
					padding: 12px;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					text-align: center;
					letter-spacing: 0.03em;
					color: #ffffff;
				}
				
				>a{
					margin-left: 10px;
					text-decoration: none;

					&:hover{
						color: #fff;
					}
				}
			}
		}

		&__bookingGopay {
			background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
			border-radius: 20px;
			padding: 40px 32px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-bottom: 23px;

			> img {
				width: 280px;
				height: 273px;
			}

			> p {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 19px;
				text-align: center;
				letter-spacing: 0.03em;
				color: #333333;
				margin-bottom: 38px;
			}
		}

		&__gopayInfo {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 20px;
			padding: 0 24px;

			> p {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 19px;
				text-align: center;
				letter-spacing: 0.03em;
				color: #333333;
			}
		}

		&__howToPay {
			&__title {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 27px;

				> p {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 14px;
					letter-spacing: 0.03em;
					color: #333333;
				}

				> img {
					cursor: pointer;
				}
			}

			&__step {
				background-color: #fff;
				padding: 19px 45px;
				border-radius: 10px;

				> span {
					margin-bottom: 10px;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 19px;
					letter-spacing: 0.01em;
					color: #000000;
				}

				> table {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #000000;
				}

				> button {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 14px;
					letter-spacing: 0.03em;
					color: #17948c;
				}
			}

			&__button {
				margin-top: 32px;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				> button {
					background: #17948c;
					border-radius: 10px;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					text-align: center;
					letter-spacing: 0.03em;
					color: #ffffff;
					padding: 12px 62px;
				}
			}
		}

		&__gopayInfo {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 20px;
			padding: 0 24px;

			> p {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 19px;
				text-align: center;
				letter-spacing: 0.03em;
				color: #333333;
			}
		}
	}
}
