.promosEvents{
    display: flex;
    margin-bottom: 0px;

    @include respondTo(tablet){
        flex-direction: column;
        margin-bottom: 0;
    }

    // General
    >div{
        width: 50%;

        @include respondTo(tablet){
            width:  100%;
            padding: 16px;
        }
    }

    .promosEventsHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22px;
        
        @include respondTo(tablet){
            margin-bottom: 13px;
        }

        >h3{
            color: #041D1C;
            font-weight: bold;
            font-size: 30px;

            @include respondTo(tablet){
                font-size: 18px;
            }
        }

        >button{
            background-color: rgba(255, 255, 255, 0.5);
            border: 1px solid #DBE1E1;
            border-radius: 10px;
            padding: 10px 16px;
            
            text-align: center;
            color: #17948C;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .promosEventsContent{
        display: flex;

        >.loadingState-promoEvent{
            display: flex;
            overflow-x: hidden;

            >div{
                margin-right: 10px;
            }
        }

        >.emptyState-promoEvent{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 100%;
            padding-top: 10px;

            >p{
                margin-top: 24px;
                color: #0A423F;
                font-size: 15px;
                font-weight: 600;
            }
        }

        >.slide-navigation{
            width: 200px;
            filter: blur(6px) !important;
            margin-left: -32px;

            @include respondTo(tablet){
                display: none;
            }
        }


        >.slick-slider{
            padding-top: 10px;
            padding-right: 30px;
            
            @include respondTo(tablet){
                padding-right: 0;
                margin: 0 -10px;
            }

            .slick-prev{
                display: none !important;
            }
            .slick-next{
                height: 57px;
                width: 57px;
                border-radius: 57px;
                background-color:#fff;
                opacity: 90%;
                position: absolute;
                right: 0;
                top: 96px;
                padding: 13px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
                
                &::before{
                    content: "";
                    display: block;
                    height: 100%;
                    background: url('../../Assets/icon/arrow-next.svg');
                    background-repeat: no-repeat;
                }

                @include respondTo(tablet){
                    display: none !important;
                }
            }
            
            >.slick-list{
                margin-bottom: 24px;
                padding-left: 0 !important;
                
                @include respondTo(tablet){
                    margin-left: 0 !important;
                }

                >.slick-track{
                    .slick-slide{
                        padding: 0 20px;

                        @include respondTo(tablet){
                            padding: 0 10px;
                        }
                    }
                }
            }

            >.slick-dots{
                @include respondTo(tablet){
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                }

                >li{
                    background-color: #D1F1EF !important;
                    width: 9px !important;
                    height: 8px !important;
                    border-radius: 50% !important;
                }

                >.slick-active{
                    width: 15px !important;
                    height: 8px !important;
                    border-radius: 4px !important;
                    background-color: #17948C !important;
                }

            }
        }

        .promosEventsItemCard{
            background-color: #fff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
            margin-bottom: 6px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            >img{
                height: 114px;
                background-color: #ddd;
                object-fit: cover;
                width: 100%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            
            >section{
                height: 114px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;

                background-color: #ddd;
                
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;

                padding: 6px 13px;

                background-size: cover !important;
                background-position: center !important;

                >div{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    >div{
                        background-color: #fff;
                        width: 31px;
                        height: 31px;
                        border-radius: 31px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    >span{
                        margin-left: 9px;
                        color: #fff;
                        font-size: 12px;
                    }
                }
                
            }

            >div{
                height: 63px;
            }
        }
    }

    // Specifict
    &__promos{
        background-color: #A2D4D1;
        padding: 57px 0;
        padding-left: 133px;
        padding-right: 29px;

        .promosEventsContent{
            >.slide-navigation{
              background-color: #A2D4D1;
            }

            .promosEventsItemCard{
                >div{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    >section{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        margin: 0 15px;

                        >div{
                            // margin-top: 10px;

                            >h4{
                                font-weight: 600;
                                font-size: 12px;
                                color: #17948C;

                                >span{
                                    color: #666;
                                    font-weight: 400;
                                }
                            }

                            >p{
                                background-color: #FAD1D1;
                                text-align: center;
                                color:#EB5757;
                                padding: 2px 8px;
                                border-radius: 29px;
                                font-size: 9px;
                                width: 75px;
                                margin-top: 5px;

                            }
                        }

                        >button{
                            padding: 5px 20px;
                            border: 1px solid #DBE1E1;
                            border-radius: 10px;

                            text-align: center;
                            font-weight: 600;
                            font-size: 12px;
                            color: #17948C;

                        }
                    }

                    >div{
                        width: 18px;
                        height: 28px;
                        background-color: #9ccdca;
                    }

                    >.clipLeft{
                        margin-left: -4px;
                        border-bottom-right-radius: 26px;
                        border-top-right-radius: 26px;

                        box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.2);
                    }

                    >.clipRight{
                        margin-right: -4px;
                        border-bottom-left-radius: 26px;
                        border-top-left-radius: 26px;

                        box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.2);
                    }

                }
            }
        }
    }

    &__events{
        padding: 57px 75px;
        background-color:#F5F9F9;
        
        .promosEventsContent{
            >.slide-navigation{
                background-color: #F5F9F9;
            }

            .promosEventsItemCard{
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;

                >div{
                    padding: 10px 15px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    
                    >h4{
                        color: #323232;
                        font-size: 14px;
                        font-weight: 600;

                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                    }   

                    >div{
                        margin-top: 6px;
                        display: flex;
                        align-items: center;

                        >span{
                            margin-left: 9px;

                            color: #17948C;
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }   
                }
            }
        }
    }
}