.fitPage {
  background: #FFFFFF;
}

.fitInfo {
  background: #17948C;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10rem;

  @include respondTo(tablet) {
    padding: 18px 16px;
    margin-bottom: 4rem;
  }

  >img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    @include respondTo(tablet) {
      width: 74px;
      height: 80px;
    }
  }
}

.fitInfo__stats {
  overflow: visible;
}

.fitInfo__header {
  margin-top: 73px;
  margin-bottom: 40px;
  position: relative;

  @include respondTo(tablet) {
    margin-top: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 90vw;
    height: 1%;
    background: #D1F1EF;
    left: 50%;
    right: 0;
    bottom: -1rem;
    transform: translateX(-50%);
  }
}

.fitInfo__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -30%);

  @include respondTo(tablet) {
    bottom: 0;
    transform: translate(-50%, 50%);
  }
}

.fitInfo__poly {
  position: relative;

  @include respondTo(tablet) {
    >img {
      width: 119px;
      height: 119px;
    }
  }
}

.fitInfo__polygon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.fitInfo--title {
  font-weight: bold;
  font-size: 36px;
  line-height: 113%;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 13px;

  @include respondTo(tablet) {
    font-size: 18px;
    text-align: start;
    margin-bottom: 8px;
  }
}

.fitInfo--info {
  font-size: 15px;
  line-height: 147.5%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FAFAFA;
  width: 50%;
  transform: translateX(50%);

  @include respondTo(tablet) {
    font-size: 12px;
    width: 100%;
    text-align: start;
    transform: translate(0);
  }
}

.fitInfo--stats-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 64px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 38px;
  padding-bottom: 130px;

  @include respondTo(tablet) {
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 15px;
  }
}

.fitInfo--stat-number {
  font-weight: 800;
  font-size: 30px;
  line-height: 64px;
  text-align: center;
  color: #0A423F;

  @include respondTo(tablet) {
    font-size: 18px;
    line-height: 10px;
  }
}

.fitInfo--stat-name {
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #17948C;

  @include respondTo(tablet) {
    font-size: 12px;
  }
}