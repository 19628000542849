.dirumahaja {
  display: grid;
  grid-template-columns: auto 800px;
  column-gap: 80px;
  align-items: center;
  padding: 72px 80px 64px 165px;
  overflow: hidden;

  @include respondTo(tablet) {
    display: flex;
    flex-direction: column-reverse;
    padding: 16px;
    position: relative;
    padding-bottom: 5rem;
  }
}

.dirumahaja__hero {
  margin: 0 auto;
  position: relative;

  @include respondTo(tablet) {
    margin-top: 42px;
  }

  img, .lazyImageWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include respondTo(tablet) {
      width: 100%;
    }
  }
  
}

.dirumahaja__hero-background {
  border-radius: 50%;
  width: 540px;
  height: 540px;
  background: #D1F1EF;

  @include respondTo(tablet) {
    width: 293px;
    height: 293px;
  }
}

.dirumahaja__content {
  width: 100%;
}

.dirumahaja__content-cards {
  display: flex;
  max-width: 40rem;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
  }

  >div:first-child {
    margin-right: 38px;
  }

  @include respondTo(tablet) {
    display: none;
  }
}

.dirumahaja__card-img {
  position: relative;
  width: fit-content;
  background: rgba($color: #0B0D17, $alpha: .2);
  border-radius: 20px;

  img, .lazyImageWrapper {
    &:nth-of-type(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.dirumahaja__card {
  max-width: 350px;
  position: relative;
}

.dirumahaja__card-wrapper {
  width: fit-content;
}

.text__dirumahaja-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #17948C;

  @include respondTo(tablet) {
    font-size: 12px;
    letter-spacing: 1px;
  }
}

.text__dirumahaja-sub {
  font-weight: bold;
  font-size: 30px;
  line-height: 54px;
  color: #052523;

  @include respondTo(tablet) {
    font-size: 18px;
    line-height: 27px;
  }
}

.text__dirumahaja-info {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #18191F;
  width: 70%;

  @include respondTo(tablet) {
    display: none;
  }
}

.dirumahaja__card-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #17948C;
  margin-top: 16px;
}

.dirumahaja__card-info {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #0A423F;
  margin-top: 8px;
}

.dirumahaja__btn {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #17948C;

  @include respondTo(tablet) {
    font-size: 18px;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.dirumahaja__card-more {
  background: #17948C;
  border-radius: 20px;
  padding: 0 32px;
  height: 196px;
  width: 167px;
  margin-left: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    display: flex;
    margin: auto;
  }
}