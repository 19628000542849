.layout {
	display: flex;
	flex-direction: column;
}

.layout__header {
	display: flex;
	align-items: center;
	padding: 18px 112px 13px 112px;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	background: #ffffff;
	z-index: 90;

	@media #{$phoneMQ} {
		padding: 12px 13px;
	}

	>.logo {
		display: flex;
		flex-grow: 1;
		justify-content: center;
		width: 189px;
		height: 48.54px;

		@media #{$phoneMQ} {
			flex-grow: unset;
			justify-content: left;
			width: 154px;
			height: 39.55px;
		}

		img {
			width: 140.18px;
			height: 36px;
		}
	}

	&__guest-view {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.015em;
		margin-left: auto;
		position: absolute;
		right: 112px;

		@media #{$phoneMQ} {
			right: 12px;
		}

		&__login {
			color: #343434;
		}

		&__signup {
			color: #17948c;
			margin-left: 35px;

			@media #{$phoneMQ} {
				display: none;
			}
		}
	}

	&__profile {
		display: flex;
		align-items: center;
		margin-left: auto;
		position: absolute;
		right: 112px;

		@media #{$phoneMQ} {
			right: 12px;
		}

		&__info {
			display: flex;
			flex-direction: column;
			position: relative;

			&__inner {
				display: flex;
				align-items: center;
				position: relative;

				&__name {
					text-align: right;
					font-weight: 600;
					font-size: 14px;
					line-height: 21px;
					letter-spacing: 0.015em;
					color: #000000;
					text-transform: capitalize;

					@media #{$tabletMQ} {
						display: none;
					}
				}

				svg {
					cursor: pointer;
					margin-right: 15px;
					margin-left: 15px;
					width: 18px;
					height: 18px;

					path {
						fill: #17948C
					}
				}
			}

			&__outer {
				position: absolute;
				background: #FFFFFF;
				box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
				padding: 23px 0;
				border-radius: 10px;
				right: -12px;
				top: 42px;
				min-width: 234px;


				&:before {
					content: '';
					position: absolute;
					width: 0;
					height: 0;
					bottom: 100%;
					right: 24px;
					border: 15px solid transparent;
					border-top: none;

					border-bottom-color: #fff;
					filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
				}

				>a {
					width: 100%;
					padding: 8px 12px;
					cursor: pointer;

					font-size: 12px;
					line-height: 17px;

					display: flex;
					align-items: center;
					text-align: center;
					letter-spacing: 0.01em;

					display: flex;
					align-items: center;
					justify-content: space-between;

					&:hover {
						background-color: #D1F1EF
					}

					svg {
						width: 14px;
						height: 14px;

						path {
							fill: #000000
						}
					}
				}
			}

			>.sign-out {
				cursor: pointer;
				text-align: right;
				font-weight: normal;
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 0.015em;
				color: #17948c;

				>button {
					display: none !important;
				}
			}
		}

		>img {
			width: 53px;
			height: 53px;
			border-radius: 50%;
			object-fit: cover;
		}
	}
}

.layout__footer--desktop {
	z-index: 90;
	background: #17948c;
	padding: 32px 133px 48px 133px;
	position: relative;

	@media #{$phoneMQ} {
		display: none;
	}

	@media #{$tabletMQ} {
		display: none;
	}

	>.background-yoga {
		width: 418px;
		height: 354px;
		position: absolute;
		right: 0;
		bottom: 0;
	}

	&__card {
		z-index: 1;
		position: relative;
		display: flex;
		background-color: #0a423f;
		max-width: 776px;
		border-radius: 23px;
		padding: 18px;

		>*:not(:last-child) {
			margin-right: 21px;
		}

		>img {
			align-self: flex-start;
		}

		>.info {
			>div:not(:last-child) {
				margin-bottom: 18px;
			}

			>div:nth-child(1) {
				font-weight: 600;
				font-size: 21px;
				line-height: 24px;
				color: #e9fcfa;
			}

			>div:nth-child(2) {
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
				letter-spacing: 0.01em;
				color: #ffffff;
			}

			>div:nth-child(3) {
				font-weight: 500;
				font-size: 14px;
				line-height: 19px;
				letter-spacing: 0.03em;
				color: #ffffff;

				>span:nth-child(2) {
					color: #4bddd4;
				}
			}
		}

		>.download {
			display: flex;
			flex-direction: column;
			align-self: flex-end;
			margin-top: 96px;


			>span {
				margin-bottom: 8px;
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
				letter-spacing: 0.01em;
				color: #ffffff;
			}

			>div {
				display: flex;

				>a>img:first-child {
					margin-right: 20px;
				}
			}
		}
	}
}

.layout__footer--mobile {
	z-index: 90;
	background: #17948c;
	padding: 25px 12px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media #{$desktopMQ} {
		display: none;
	}

	&__card {
		display: flex;
		width: 100%;
		background-color: #073d39;
		padding: 22px 19px;
		border-radius: 23px;
		margin-bottom: 19px;

		>img {
			margin-right: 16px;
			align-self: flex-start;
		}

		>.info {
			display: flex;
			flex-direction: column;

			>div:nth-child(1) {
				font-style: normal;
				font-weight: bold;
				font-size: 21px;
				line-height: 22px;
				color: #e9fcfa;
				margin-bottom: 14px;
			}

			>div:nth-child(2) {
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
				letter-spacing: 0.01em;
				color: #ffffff;
				margin-bottom: 48px;
			}

			>div:nth-child(3) {
				font-weight: 500;
				font-size: 14px;
				line-height: 19px;
				letter-spacing: 0.03em;
				color: #ffffff;

				>span:nth-child(2) {
					color: #4bddd4;
				}
			}
		}
	}

	>.download {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		>span {
			font-weight: 500;
			font-size: 14px;
			line-height: 19px;
			text-align: center;
			letter-spacing: 0.03em;
			color: #ffffff;
			margin-bottom: 14px;
		}

		>div {
			display: flex;
			justify-content: space-between;

			>a {
				width: 48%;

				>img {
					width: 100%;
				}
			}
		}
	}
}

.layout__footer--non-cta {
	display: flex;
	justify-content: center;
	padding: 12px 15px 213px;

	@media #{$phoneMQ} {
		font-size: 12px;
		line-height: 17px;
		padding: 12px 10px 56px;
	}

	background-color: white;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: 0.01em;
	color: #17948c;
}