.header {
  padding: 16px 13px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  >img {
    width: fit-content;
  }
}

.header__title {
  font-weight: 800;
  margin-left: 5px;
  font-size: 21px;
  letter-spacing: 0.02em;

  @include respondTo(tablet) {
     font-size: 18px;
     letter-spacing: 0.01em;
  }
}

.success {
    display: flex;
    justify-content: center;  
    min-height: calc(100vh - 72px);
  }
  
  .success__wrapper {
    display: grid;
    place-items: center;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    .success_img {
      display: flex;
      flex-direction: row;
      margin: 30px auto;

    @include respondTo(tablet) {
        padding: 10px 50px;
        >a {
          img {
            width: 100px;
          }
      }

      a:nth-child(3){
        img {
          padding: 0px 0px 25px 0px;
        }
      }
    }
  }

  @include respondTo(tablet) {
      top: 45%;
  }
  
    @media (max-width: 1000px) {
      .success_img {
        img{
          width: fit-content;
        }
      }
    }
  
    @include respondTo(tablet) {
      img {
        width: 263px;
      }
    }
  }
  
  .success__content {
    margin-top: 10px;
  
    h3 {
      font-weight: bold;
      font-size: 50px;
      line-height: 113%;
      text-align: center;
      // color: $color-dark-02;
  
      @include respondTo(tablet) {
        font-size: 21px;
        line-height: 24px;
      }
    }
  
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 147.5%;
      text-align: center;
      letter-spacing: 0.02em;
      // color: $color-dark-02;
      width: 25rem;
  
      @include respondTo(tablet) {
        font-size: 14px;
        line-height: 14px;
        width: 17rem;
        margin-top: 8px;
      }
    }
  }
  
  .success__action {
    background: #17948C;
    border-radius: 15px;
    margin-top: 20px;
  
    @include respondTo(tablet) {
      margin-top: 0;
    }
  
    button {
      font-weight: bold;
      font-size: 20px;
      line-height: 147.5%;
      letter-spacing: 0.02em;
      color: white;
      padding: 12px 80px;
  
      &:hover {
        border-radius: 15px;
        // background-color: $color-primary-dark;
      }
  
      @include respondTo(tablet) {
        border-radius: 12px;
        font-size: 14px;
        line-height: 24px;
        padding: 8px 16px;
      }
    }
  }