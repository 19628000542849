/* ==========================================
              MODAL CONTAINER
==========================================*/
.modalContainer {
	position: absolute;
}

.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: hsla(100, 4%, 15%, 0.185);
	z-index: 99;
}

.modalOverlayCenter {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: hsla(100, 4%, 15%, 0.185);
	z-index: 99;
}

.modalOverlayRight {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: flex-end;
	background-color: rgba(38, 40, 37, 0.185);
	z-index: 99;
}

.shareModal {
	padding: 24px 49px 42px;
	background: #ffffff;
	border-radius: 10px;
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	@media #{$tabletMQ} {
		margin: 0 2rem;
	}

	>button {
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		width: fit-content;
		height: fit-content;
		padding: 12px 65px;
	}

	&__header {
		font-style: normal;
		font-weight: 600;
		font-size: 21px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.015em;
		color: #555555;
		margin-bottom: 55px;

		svg {
			position: absolute;
			right: 1rem;
			top: 1rem;
		}
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		height: 12rem;
		margin-bottom: 32px;

		button {
			width: 33.3%;
			line-height: 24px;
		}
	}
}

.cancelPaymentModal {
	width: 370px;
	padding: 24px 49px 42px;
	background: #ffffff;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	>span {
		font-style: normal;
		font-weight: 600;
		font-size: 36px;
		letter-spacing: 0.03em;
		color: #000000;
		text-align: center;
		margin-bottom: 32px;
	}

	>p {
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 27px;
		letter-spacing: 0.03em;
		color: #000000;
		margin-bottom: 32px;
		text-align: center;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 32px;

		>p {
			font-style: normal;
			font-weight: 600;
			font-size: 24px;
			line-height: 27px;
			letter-spacing: 0.03em;
			color: #000000;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 15rem;
		}

		>div {
			display: flex;
			align-items: center;
			margin-bottom: 16px;

			>svg {
				width: 12%;
				margin-right: 10px;
			}

			>span {
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 27px;
				letter-spacing: 0.03em;
				color: #000000;
			}
		}

		>span {
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 27px;
			letter-spacing: 0.03em;
			color: #000000;
		}
	}

	&__button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		>div {
			color: #eb5757;
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 27px;
			letter-spacing: 0.03em;
			width: 60%;
			cursor: pointer;
		}

		>button {
			width: 40%;
		}
	}
}

.paymentDetailAccordion {
	padding: 40px 22px 0;

	>.title {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 0.01em;
		color: #000000;
		margin-bottom: 19px;
	}

	.content {
		&__wrapper {
			display: flex;
			margin-bottom: 7px;

			.amount {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: rgba($color: #000000, $alpha: 0.5);
				margin-right: 16px;
			}

			.price {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				text-align: right;
				letter-spacing: 0.01em;
				color: #000000;
			}

			.voucher {
				color: #358b9d;
			}

			&__desc {
				margin-right: auto;

				.title {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #000000;
				}

				.text {
					font-style: normal;
					font-weight: normal;
					font-size: 10px;
					line-height: 16px;
					letter-spacing: 0.015em;
					color: rgba($color: #000000, $alpha: 0.5);
				}
			}
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-style: normal;

		border-top: 1px solid rgba($color: #bdbdbd, $alpha: 0.3);
		padding: 16px 0;
		font-weight: 600;
		font-size: 12px;
		line-height: 17px;
		letter-spacing: 0.03em;
		color: #000000;
	}
}