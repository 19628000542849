.doocoach{
    &__banner{
        background: url('../../Assets/doocoach/banner-bg.png');
        height: 568px;
        background-size: cover;
        padding: 210px 128px;
        
        @include respondTo(tablet) {
            background-position: top;
            padding: 157px 16px;
        }

        &__action{
            color: #fff;

            >h2{
                width: 40%;
                font-size: 50px;
                font-weight: bold;
                line-height: 56px;
                
                @include respondTo(tablet){
                    font-size: 36px;
                    line-height: 41px;
                    width: 100%;
                }
            }
            >p{
                margin-top: 13px;
                margin-bottom: 0;
                font-weight: 600;
                line-height: 24px;
                font-size: 21px;

                @include respondTo(tablet){
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                }
            }

            >button{
                margin-top: 28px;
                border-radius: 15px;
                text-align: center;
                padding: 14px 32px;
                background: #409FBF;
                font-weight: 600;
                width: 348px;

                font-size: 20px;
                line-height: 24px;

                @include respondTo(tablet) {
                    display: block;
                    margin-top: 18px;
                    margin-bottom: 30px;
                    width: 210px;
                    padding: 14px 32px;
                    font-size: 16px;
                }
            }
        }
    }

    &__info{
        height: 220px;
        background: linear-gradient(to right, #2C6F86, #173C49);
        padding: 0 135px 42px 135px;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        @include respondTo(tablet){
            padding: 0 16px 18px 16px;
            height: auto;
        }

        >img{
            margin-top: -36px;
            width: 127px;
            height: 127px;
            
            @include respondTo(tablet){
                margin-top: -26px;
                width: 48.18px;
                height: 52px;
            }
        }

        >h3{
            color: #fff;
            font-size: 30px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 12px;
            line-height: 34px;

            @include respondTo(tablet){
                font-size: 18px;
                line-height: 28px;
                font-weight: 600;
                margin-bottom: 6px;
            }
        }
        
        >p{
            text-align: center;
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;

            @include respondTo(tablet){
                font-size: 12px;
                font-weight: 500;
            }
        }
    }

    &__salsalivefit{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 72px 121px;
        background: linear-gradient(#0E2831, #1B4655);
        
        @include respondTo(tablet){
            padding: 32px 27px;
        }

        &__divider{
            height: 8px;
            width: 155px;
            background-color: rgba(31, 120, 153, 0.43);

            @include respondTo(tablet){
                height: 3.76px;
            }
    
        }

        >h3{
            color: #fff;
            font-size: 30px;
            line-height: 34px;
            margin-top: 72px;
            margin-bottom: 12px;
            font-weight: 700;
            
            @include respondTo(tablet){
                text-align: center;
                margin-top: 24px;
                font-size: 18px;
                line-height: 27px;
            }

        }

        >p{
            color: #fff;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            margin: 0;
            
            @include respondTo(tablet){
                font-weight: 400;
                text-align: center;
                font-size: 12px;
                line-height: 18px;
            }
        }

        >img{
            margin-top: 32px;
            margin-bottom: 72px;
            width: 100%;
            
            @include respondTo(tablet){
                margin-top: 24px;
                margin-bottom: 24px;
                border-radius: 10px;
            }
        }
    }

    &__onlineConsultation{
        background: #F4FCFF;
        padding: 72px 124px 72px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        @include respondTo(tablet){
            flex-direction: column-reverse;
            padding: 40px 16px;
        }

        &__consultant{
            display: flex;
            align-content: center;
            justify-content: flex-start;
            margin-right: 42px;
            
            @include respondTo(tablet){
                margin-right: 0;
            }

            >img{
                width: 712px;
                @include respondTo(tablet){
                    width: 100%;
                }
            }
        }

        &__info{
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include respondTo(tablet){
                margin-bottom: 40px;
            }

            >h3{
                color: #409FBF;
                font-size: 50px;
                font-weight: bold;
                line-height: 56px;
                margin-bottom: 12px;

                @include respondTo(tablet){
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 28px;
                    margin-bottom: 2px;
                }
            }
            
            >h5{
                color: #409FBF;
                font-weight: 600;
                line-height: 24px;
                font-size: 21px;
                margin-bottom: 24px;

                @include respondTo(tablet){
                    font-size: 12px;
                    line-height: 21px;
                    font-weight: 500;
                    margin-bottom: 12px;
                }
            }

            >p{
                color: #666666;
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                margin: 0;
                
                @include respondTo(tablet){
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

    &__howItWorks{
        background: #F4FCFF;
        padding: 72px 0 72px 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respondTo(tablet){
            flex-direction: column;
            padding: 40px 16px;
        }

        &__info{
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            >h3{
                color: #409FBF;
                font-size: 50px;
                font-weight: bold;
                line-height: 56px;

                @include respondTo(tablet){
                    font-size: 18px;
                    line-height: 20px;
                }

            }

            >p{
                margin-top: 16px;
                color: #666666;
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;

                @include respondTo(tablet){
                    margin-top: 8px;
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            &__step{
                margin-top: 40px;
                
                @include respondTo(tablet){
                    margin-top: 24px;
                }

                >.--selected{
                    background-color: #E7F8FE;
                }

                >div{
                    display: flex;
                    align-items: center;
                    background-color: #fff;
                    border: 1px solid #52AFCE;
                    border-radius: 15px;
                    margin-bottom: 32px;
                    padding: 24px 32px;
                    cursor: pointer;
                    
                    @include respondTo(tablet){
                        margin-bottom: 12px;
                        padding: 12px;
                    }
    
                    >img{
                        margin-right: 28px;
                        
                        @include respondTo(tablet){
                            margin-right: 12px;
                            height: 24px;
                            width: 24px;
                        }
                    }

                    >span{
                        color: #323232;
                        font-size: 14px;
                        line-height: 22px;
                        font-weight: 500;
                        
                        @include respondTo(tablet){
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }

        &__app{
            display: flex;
            align-content: center;
            justify-content: flex-end;
            margin-left: 97px;
            
            @include respondTo(tablet){
                margin-left: 0;
                margin-top: 31px;
            }

            >img{
                @include respondTo(tablet){
                    width: 100%;
                }
            }
        }
    }

    &__whatIsGuaranteed{
        background: #F4FCFF;
        padding: 72px 0;
        
        @include respondTo(tablet){
            padding: 24px 16px;
        }

        &__content{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 118px;
            
            @include respondTo(tablet){
                padding-right: 0;
                flex-direction: column-reverse;
            }

            &__image{
                display: flex;
                align-content: center;
                justify-content: flex-start;
                margin-right: 62px;

                @include respondTo(tablet){
                    margin-right: 0;
                }

                >img{
                    @include respondTo(tablet){
                        width: 100%;
                    }
                }

            }

            &__info{
                >h3{
                    color: #409FBF;
                    font-weight: bold;
                    font-size: 50px;
                    line-height: 56px;

                    @include respondTo(tablet){
                        font-size: 18px;
                        line-height: 20px;
                    }
    
                }
    
                >p{
                    margin-top: 16px;
                    color: #666666;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;

                    @include respondTo(tablet){
                        margin-top: 8px;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

                >div{
                    margin-top: 40px;
                    padding: 0 10px;
                    
                    @include respondTo(tablet){
                        margin-top: 24px;
                    }

                    >div{
                        display: flex;
                        margin-bottom: 12px;
                        padding: 24px 32px;
                        
                        @include respondTo(tablet){
                            margin-bottom: 12px;
                            padding: 12px 8px;
                        }

                        >img{
                            margin-right: 25px;
                            height: 35.25px;
                            width: 35.25px;
                            
                            @include respondTo(tablet){
                                height: 17.63px;
                                width: 17.63px;
                                margin-right: 12px;
                            }
                        }

                        >div{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;

                            >h4{
                                color: #666666;
                                font-size: 21px;
                                line-height: 24px;
                                font-weight: 600;

                                @include respondTo(tablet){
                                    font-size: 14px;
                                    line-height: 28px;
                                }
                            }

                            >p{
                                margin-top: 12px;
                                margin-bottom: 0;
                                color: #666666;
                                font-size: 14px;
                                line-height: 21px;
                                
                                
                                @include respondTo(tablet){
                                    margin-top: 4px;
                                    font-size: 12px;
                                    line-height: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__action{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 60px;

            @include respondTo(tablet){
                display: none;
            }

            >button{
                background-color: #409FBF;
                border-radius: 15px;
                color: #fff;
                padding: 24px 158px;
                font-size: 24px;
                line-height: 24px;
                font-weight: 600;
            }
        }
    }

    .healthy{
        background: #3E9BBB !important;
    }

}

.doocoach-subscribe{
    background: #134D62 !important;

    >form{
        >div{
            >.subscribe__button{
                background: #1F7899 !important;
            }
        }
    }
}


.doocoach-footer{
    background-color: #0D3544 !important;
}