%contact-input {
    &:focus {
        border: 1px solid #67d77d;
    }
}

%contact-input--error {
    border: 1px solid #f44336 !important;
}

.contact-us {
    position: relative;
    padding-bottom: 100px;

    * {
        z-index: 2;
    }

    &__bg {
        z-index: 1;
    }

    &__header {
        position: relative;
        display: flex;
        width: 100%;
        min-height: 50vh;

        @include respondTo(phone) {
            min-height: 255px;
        }

        @include respondTo(tablet) {
            min-height: 255px;
        }

        @include respondTo(desktop-4k) {
            min-height: 55vh;
        }

        >span {
            font-weight: 400;
            padding-left: 30px;
            font-size: 41px;
            text-transform: uppercase;
            color: #fff;
            margin: 100px 200px;

            @include respondTo(tablet) {
                margin: 100px 15px 25px;
            }
        }

        >img {
            position: absolute;
            width: 100%;

            @include respondTo(tablet) {
                min-height: 250px;
                object-fit: cover;
            }
        }
    }
}

.contact-us__main-container {
    display: flex;

    @include respondTo(tablet) {
        flex-direction: column;
    }

    >:nth-child(1) {
        width: 75%;

        @include respondTo(tablet) {
            width: 100%;
        }
    }

    >:nth-child(2) {
        width: calc(25% - 21px);

        @include respondTo(tablet) {
            width: 100%;
        }

    }
}

.contact-us__main {
    display: flex;
    position: relative;

    &__background {
        position: absolute;
        right: 0;
        top: 0;
        height: 800px;

        @include respondTo(tablet) {
            height: 400px;
            bottom: 0;
            top: unset;
        }
    }



    &__form {
        &__success {
            width: 100%;
            color: #155724;
            background-color: #d4edda;

            position: relative;
            padding: 8px 21px;
            margin-bottom: 14px;
            border: 1px solid transparent;
            border-radius: .25rem;
        }

        &__error {
            margin-bottom: 6px;
            padding: 6px 10px;
            border-radius: 5px;
            background: #f44336;
            color: #fff;
            font-size: 12px;
        }

        &__field--error {
            @extend %contact-input--error;
        }

        &__title {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 16px;
        }

        &__userType-container {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;

            @include respondTo(tablet) {
                flex-direction: column;

            }

            >*:not(:last-child) {
                margin-right: 12px;

                @include respondTo(tablet) {
                    margin-right: unset;
                    margin-bottom: 6px;
                }
            }
        }

        &__userType {
            padding: 10px 30px 10px 20px;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 500;
            position: relative;
            border: 1px solid #d1d1d1;
            background-color: rgb(239, 239, 239);
            text-align: left;


            svg {
                display: none;
            }
        }

        &__userType--active {
            @extend .contact-us__main__form__userType;
            background: #1e1e1e;
            color: #fff;
            display: flex;

            svg {
                display: inline;
                position: absolute;
                right: 6px;
                top: 9px;
                margin-left: 21px;

                path {
                    fill: #67d77d;
                }
            }
        }

        &__inputs {
            margin-bottom: 14px;
            display: flex;

            >*:not(:last-child) {
                margin-right: 14px;

                @include respondTo(tablet) {
                    margin-right: unset;
                    margin-bottom: 6px;

                }
            }

            @include respondTo(tablet) {
                flex-direction: column;
            }

            >input {
                padding: 10px;
                width: calc(25% - 14px);
                border-radius: 4px;
                border: 1px solid #d7d7d7;
                @extend %contact-input;

                @include respondTo(tablet) {
                    width: 100%;
                }
            }

            &__type {
                display: flex;
                align-items: center;
                padding: 10px;
                width: 50%;
                border-radius: 4px;
                border: 1px solid #d7d7d7;

                @include respondTo(tablet) {
                    width: 100%;
                }

                span {
                    color: #aaa;
                    font-weight: 400;
                    font-size: 13px;
                    margin-right: 14px;
                }

                >select {
                    cursor: pointer;
                    flex-grow: 1;
                    font-size: 14px;
                    border: none;
                    background: transparent;
                }
            }
        }

        &__message {
            max-width: 100%;
            width: 100%;
            height: 289px;

            padding: 10px;
            border-radius: 4px;
            border: 1px solid #d7d7d7;
        }

        &__submit {
            background: -webkit-linear-gradient(180deg, #1f7999, #67d77d);
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            padding: 2px 22px;
            border-radius: 4px;
            margin-top: 14px;
            height: 42px;
        }

    }

    &__info {
        margin-left: 21px;
        align-self: center;
        font-size: 17px;
        line-height: 26px;

        @include respondTo(tablet) {
            margin-left: unset;
            margin-top: 50px;
        }

        >div {
            display: flex;
            flex-direction: column;

            &:not(:last-child) {
                margin-bottom: 28px;
            }
        }
    }
}