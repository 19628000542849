.userProfile {
	position: relative;

	&__header {
		background: #17948c;
		padding: 69px 0 94px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		font-style: normal;
		font-weight: 600;
		font-size: 36px;
		line-height: 54px;
		letter-spacing: 0.015em;
		color: #ffffff;

		@media #{$tabletMQ} {
			padding: 32px 0 59px;
			font-size: 24px;
			line-height: 36px;
		}

		img {
			margin-bottom: 26px;
			width: 202px;
			height: 202px;
			border-radius: 100%;
			object-fit: cover;

			@media #{$tabletMQ} {
				width: 101px;
				height: 101px;
			}
		}
	}

	&__content {
		box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
		border-radius: 50px 50px 0 0;
		margin-top: -4rem;
		background-color: #ffffff;
		padding-bottom: 46px;

		display: flex;
		flex-direction: column;
		align-items: center;

		@media #{$tabletMQ} {
			margin-top: -3rem;
		}

		>.title {
			font-style: normal;
			font-weight: 600;
			font-size: 36px;
			line-height: 54px;
			letter-spacing: 0.015em;
			color: #052523;
			padding: 59px 0 48px;

			@media #{$tabletMQ} {
				padding: 32px 0;
				font-size: 24px;
				line-height: 36px;
			}
		}

		&__body {
			@media #{$tabletMQ} {
				padding: 0 16px;
				width: 100%;
			}

			.title {
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 36px;
				letter-spacing: 0.03em;
				margin-bottom: 32px;

				@media #{$tabletMQ} {
					font-size: 18px;
					line-height: 24px;
					margin-bottom: 18px;
				}

				&.today {
					color: #17948c;
				}

				&.upcoming {
					margin-top: 24px;
					color: rgba($color: #000000, $alpha: 0.6);
				}
			}

			&__wrapper {
				background: #ffffff;
				min-width: 595px;
				padding: 22px 24px;
				box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
				border-radius: 10px;
				margin-bottom: 24px;

				@media #{$tabletMQ} {
					min-width: auto;
					width: 100%;
				}
			}

			&__card {
				display: flex;
				align-items: center;
				justify-content: space-between;

				&.date {
					margin-top: 12px;
				}
			}

			&__paymentType {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 19px;

				svg {
					cursor: pointer;

					path {
						fill: #828282;
					}
				}

				.text {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 21px;
					letter-spacing: 0.03em;

					@media #{$tabletMQ} {
						font-size: 12px;
						line-height: 18px;
					}

					&.success {
						color: #27ae60;
					}

					&.pending {
						color: #eb5757;
					}

					&.cancel {
						color: #828282;
					}
				}
			}
		}
	}
}

.venue__info {
	&__capsule {
		border: 1px solid #828282;
		box-sizing: border-box;
		border-radius: 20px;
		width: 82px;
		height: 33px;
		display: flex;
		justify-content: center;
		align-content: center;

		&__title {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			color: #828282;
			align-self: center;
		}
	}

	&__classname {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 19px;
		letter-spacing: 0.03em;
		color: #000000;
		margin-bottom: 8px;

		@media #{$tabletMQ} {
			font-size: 14px;
			line-height: 21px;
		}
	}

	&__name {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		letter-spacing: 0.03em;
		color: #2a2a2a;

		@media #{$tabletMQ} {
			font-size: 12px;
			line-height: 18px;
		}
	}

	&__partner {
		margin-left: 0;
	}

	&__date {
		.date {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			text-align: right;
			letter-spacing: 0.01em;
			color: #17948c;

			@media #{$tabletMQ} {
				font-size: 12px;
			}
		}

		.time {
			font-style: normal;
			font-weight: 600;
			font-size: 24px;
			line-height: 17px;
			letter-spacing: 0.03em;
			text-align: right;
			color: #333333;
			margin-top: 8px;

			@media #{$tabletMQ} {
				font-size: 18px;
			}
		}
	}
}

.noBooking {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 294px;
	margin-top: 159px;

	@media #{$tabletMQ} {
		padding-bottom: 194px;
		margin-top: 44px;
	}

	button {
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.03em;
		color: #FFFFFF;
		padding: 12px 55px;
		background: #17948C;
		border-radius: 15px;
		margin-top: 69px;

		@media #{$tabletMQ} {
			font-size: 12px;
			padding: 5px 16px;
		}
	}

	.title {
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 17px;
		text-align: center;
		letter-spacing: 0.03em;
		color: #798685;
		margin-bottom: 32px;

		@media #{$tabletMQ} {
			font-size: 12px;
		}
	}

	.text {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 27px;
		text-align: center;
		letter-spacing: 0.01em;
		color: #798685;
		padding: 0 30rem;

		@media #{$tabletMQ} {
			font-size: 12px;
			line-height: 17px;
			padding: 0 61px;
		}
	}
}

.userProfile__tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 88px 0 0;
	margin-bottom: 38px;
	width: 45%;
	border-bottom: 1px solid #C4C4C4;

	@media #{$tabletMQ} {
		width: 97%;
	}
}

.userProfile__tab {
	font-weight: 600;
	font-size: 18px;
	line-height: 14px;
	text-align: center;
	letter-spacing: 0.03em;
	color: #828282;
	padding-bottom: 13px;

	&:not(:last-child) {
		margin-right: 50px;
	}

	@media #{$tabletMQ} {
		font-size: 14px;

		&:not(:last-child) {
			margin-right: 25px;
		}
	}
}

.userProfile__cards {
	background: #FBFEFE;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
	min-width: 595px;
	position: relative;

	&:not(:last-child) {
		margin-bottom: 15px;
	}

	@media #{$tabletMQ} {
		min-width: auto;

		&:not(:last-child) {
			margin-bottom: 5px;
		}
	}
}

.userProfile__tab--active {
	color: #17948C;
	border-bottom: 4px solid #17948C;
}

.userProfile__cards-membership {
	padding: 13px 21px;
}

.userProfile__cards-package {
	padding: 25px 24px;
}

.userProfile__button {
	position: absolute;
	padding: 5px 16px;
	background: #17948C;
	border-radius: 10px;
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	letter-spacing: 0.03em;
	color: #FFFFFF;
	cursor: pointer;

	@media #{$tabletMQ} {
		position: relative;
		width: 100%;
	}
}

.userProfile__button-membership {
	bottom: 27px;
	right: 24px;

	@media #{$tabletMQ} {
		right: 0;
		bottom: 0;
	}
}

.userProfile__button-package {
	bottom: 30px;
	right: 36px;

	@media #{$tabletMQ} {
		right: 0;
		bottom: 0;
	}
}

.userProfile__membership-title {
	display: flex;
	justify-content: space-between;
	margin-bottom: 14px;
}

.userProfile__text--title {
	font-weight: 600;
	font-size: 18px;
	line-height: 19px;
	letter-spacing: 0.03em;
	color: #031615;
}

.userProfile__text--venue {
	font-size: 14px;
	line-height: 21px;
	align-items: center;
	letter-spacing: 0.01em;
	color: #4F4F4F;
}

.userProfile__text--info {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.03em;
	color: #031615;
}

.userProfile__text--price {
	font-weight: 600;
	font-size: 18px;
	line-height: 19px;
	letter-spacing: 0.03em;
	color: #17948C;

	span {
		font-size: 11px;
		line-height: 24px;
		letter-spacing: 0.03em;
		color: #17948C;
	}
}

.userProfile__text--status {
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.03em;
	margin-top: 8px;
}

.text__status--cancel2 {
	color: #828282;
}

.userProfile__membership-info {
	&:not(:last-child) {
		margin-bottom: 20px;
	}

	span {
		font-size: 12px;
		line-height: 17px;
		letter-spacing: 0.01em;
		color: #031615;
	}
}

.userProfile__membership-count {
	border-radius: 10px;
	width: 42px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.03em;
	color: #FAFAFA;
	margin-right: 12px;
}

.userProfile__membership-count--blue {
	background: #1F7899;
}

.userProfile__membership-count--green {
	background: #66D57B;
}

.modal-detail {
	background: #FFFFFF;
	border-radius: 20px;
	position: relative;
	padding: 62px 68px 55px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 500px;
	height: 500px;

	svg {
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
	}

	@media #{$tabletMQ} {
		width: 300px;
		height: 300px;
		padding: 10px 20px;

		img {
			width: 75px;
		}
	}
}

.modal-detail__title {
	margin: 42px 0 35px;
	width: 70%;

	@media #{$tabletMQ} {
		width: 100%;
		margin: 20px 0 10px;
	}
}

.modal-detail__info {
	margin-bottom: 14px;

	@media #{$tabletMQ} {
		margin-bottom: 5px;
	}
}

.modal-detail__social {
	width: 80%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media #{$tabletMQ} {
		width: 60%;
	}
}

.text__modal-detail--title {
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: 0.03em;
	color: #323232;
	text-align: center;

	@media #{$tabletMQ} {
		font-size: 14px;
	}
}

.text__modal-detail--info {
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0.01em;
	color: #666666;

	@media #{$tabletMQ} {
		font-size: 11px;
	}
}

.userProfile__quota {
	background: #D1F1EF;
	border-radius: 10px;
	width: 65%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 14px 16px;

	@media #{$tabletMQ} {
		width: 100%;
		margin-bottom: 20px;
	}
}

.userProfile__text--quota {
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.01em;
	color: #031615;
}

.userProfile__text--amount {
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 0.01em;
	color: #1DBAAF;
}