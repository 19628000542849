.tag-seo {
    position: relative;
    padding: 100px;
    display: flex;
    flex-direction: column;

    @include respondTo(tablet) {
        padding: 32px 16px;
        overflow: hidden;
    }

    >*:not(img) {
        z-index: 2;
    }


    >img {
        position: absolute;
        align-self: center;
        opacity: 0.3;
        z-index: 1;
    }

    >h1,
    h2 {
        font-size: 30px;
        font-weight: bold;
        line-height: 64px;
        text-align: center;
        font-feature-settings: 'liga'off;
        color: #18191F;

        margin-bottom: 29px;

        @include respondTo(tablet) {
            font-weight: bold;
            font-size: 18px;
            line-height: 113%;
            margin: 29px 0;
        }
    }

    &__content {

        &__text {
            font-size: 18px;
            line-height: 32px;
            font-feature-settings: 'liga'off;
            color: #323232;
            text-align: justify;

            @include respondTo(tablet) {
                font-size: 12px;
                line-height: 22px;
            }
        }

        @include respondTo(tablet) {
            font-size: 12px;
            line-height: 18px;
        }

        &--show {
            opacity: 1;
            transition: all .5s linear;
        }

        &--hide {
            overflow: hidden;
            transition: all .5s linear;
            height: auto;
            max-height: 0;
            opacity: 0;
        }
    }

    &__action-read {
        display: flex;
        align-self: center;
        align-items: center;

        width: fit-content;
        margin-top: 21px;

        cursor: pointer;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        font-feature-settings: 'liga'off;
        color: #17948C;

        @include respondTo(tablet) {
            font-size: 16px;
        }

        >svg {
            min-width: 24px;
            min-height: 24px;
            margin-left: 8px;

            path {
                fill: #17948C;
            }

            animation: rotate-180-cw .5s cubic-bezier(.25, .46, .45, .94) both;
            transform: rotate(180deg);

            @keyframes rotate-180-cw {
                0% {
                    transform: rotate(0)
                }

                100% {
                    transform: rotate(180deg)
                }
            }
        }
    }
}