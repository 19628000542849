.banner-profile {
    position: relative;
    display: flex;
    min-height: 570px;


    >img {
        width: 100%;
        object-fit: cover;
    }

    .imgBanner {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content {
        position: absolute;
        padding: 279px 128px;
        color: #FFFFFF;


        @include respondTo(tablet) {
            padding: 255px 75px 179px;
        }

        @include respondTo(phone) {
            padding: 157px 16px 75px;
        }

        >h2 {
            font-weight: bold;
            font-size: 50px;
            line-height: 113%;

            @include respondTo(tablet) {
                font-size: 36px;
                font-weight: bold;
            }
        }

        >div {
            font-weight: 600;
            font-size: 20px;
            line-height: 147.5%;
            letter-spacing: 0.02em;

            @include respondTo(tablet) {
                font-weight: 500;
                font-size: 14px;

            }
        }
    }
}