.event {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
}

.event-main {
    display: flex;
    flex-direction: column;
    z-index: 10;
    margin-top: -6rem;
}

.event-main__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding: 51px 48px;
    background: #ffffff;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 50px 50px 0 0;


    @include respondTo(tablet) {
        padding: 25px 16px 54px;
        border-radius: 20px 20px 0 0;
        box-shadow: unset;
    }

    &__title {
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        letter-spacing: 0.015em;
        color: #000000;
        margin-bottom: 19px;
        margin-right: 28px;

        @include respondTo(tablet) {
            font-size: 21px;
            line-height: 24px;
        }
    }

    &__location {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;

        svg {
            width: 32px;
            height: 32px;
        }

        @include respondTo(tablet) {
            font-size: 12px;
            line-height: 21px;

            svg {
                width: 16px;
                height: 18px;
                margin-right: 9px;
            }
        }
    }

    &__share {
        display: flex;
        align-items: center;
        cursor: pointer;

        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.03em;
        color: #17948C;



        svg {
            width: 32px;
            height: 32px;
            margin-right: 12px;
        }

    }


}

.event-main__content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    width: 100%;
    padding: 92px 16px;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 130px), 1fr));
    grid-column-gap: 130px;

    @include respondTo(tablet) {
        grid-template-columns: 1fr;
        grid-column-gap: unset;
        padding: unset;
        background-color: #FFFFFF;
    }

    &__about {

        @include respondTo(tablet) {
            margin-bottom: 24px;
            padding: 0 16px;
        }

        >div:nth-child(1) {
            font-weight: 500;
            font-size: 18px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #031615;
            margin-bottom: 16px;
        }

        &__desc {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.01em;
            color: #828282;
            white-space: pre-line;
            max-width: 100%;

            @include respondTo(tablet) {
                font-size: 12px;
            }

            &__collapse {
                @extend .event-main__content__about__desc;

                @include respondTo(tablet) {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                }
            }
        }

        &__collapsible {
            display: none;

            @include respondTo(tablet) {
                display: flex;
                flex-direction: column;
                align-items: center;

                margin-top: 17px;

                >span {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 17px;
                    letter-spacing: 0.01em;
                    color: #17948C;
                }

                >img {
                    width: 24px;
                    height: 24px;
                }
            }
        }

    }

    &__list {
        display: flex;
        flex-direction: column;

        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 40px 32px;

        @include respondTo(tablet) {
            box-shadow: unset;
            padding: unset;
        }


        &__title {
            width: fit-content;
            margin: 0 auto;
            padding: 0 24px 13px;
            border-bottom: 4px solid #17948C;

            font-weight: 600;
            font-size: 18px;
            line-height: 14px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #17948C;
            z-index: 2;
        }

        &__event {
            margin-top: -3px;
            z-index: 1;
            border-top: 1px solid #C4C4C4;
        }

        &__event__sports {
            padding: 23px 0 15px;
            display: flex;
            overflow-x: auto;
            max-width: 80vw;
            @include globalCustomScroll;

            @include respondTo(tablet) {
                padding: 24px 21px 23px;
                border-bottom: 1px solid #C4C4C4;
                max-width: 100vw;
                padding: 24px 21px 14px;
            }

            &__sport {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #17948C;

                margin-right: 15px;
                white-space: nowrap;

                padding: 8px 18px;
                background: #FFFFFF;
                border: 1px solid #17948C;
                border-radius: 22px;

                &:hover {
                    background: #17948C;
                    color: #FFFFFF;
                    transition: .5s;
                }
            }

            &__sport--active {
                @extend .event-main__content__list__event__sports__sport;
                background: #17948C;
                color: #FFFFFF;
            }
        }

        &__event__items {
            margin-top: 29px;

            &__item {
                display: flex;
                margin-bottom: 31px;

                @include respondTo(tablet) {
                    flex-direction: column;
                    align-items: center;
                    padding: 0 16px;
                }

                &__date {
                    margin-right: 28px;
                    width: 63px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @include respondTo(tablet) {
                        margin-bottom: 22px;
                    }

                    >span {
                        margin-bottom: 5px;
                        font-size: 12px;
                        line-height: 14px;
                        text-align: center;
                        letter-spacing: 0.03em;
                        color: #323232;
                        margin-bottom: 5px;
                    }

                    >div {
                        display: flex;
                        flex-direction: column;
                        background: #D0E9E8;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        width: 63px;
                        height: 63px;

                        >span:nth-child(1) {
                            font-weight: bold;
                            font-size: 24px;
                            line-height: 14px;
                            text-align: center;
                            letter-spacing: 0.03em;
                            color: #041D1C;
                            margin-bottom: 4px;
                        }

                        >span:nth-child(2) {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 14px;
                            text-align: center;
                            letter-spacing: 0.03em;
                            color: #041D1C;
                        }
                    }
                }

                &__card {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: 25px 27px;
                    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    text-align: unset;
                    transform: scale(.99);

                    &:hover {
                        transform: scale(1);
                        transition: .5s;
                    }
                }

                &__card__info {
                    display: flex;
                    flex-direction: column;
                    margin-right: 8px;

                    >span:nth-child(1) {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 19px;
                        letter-spacing: 0.03em;
                        color: #000000;
                        margin-bottom: 16px;

                        @include respondTo(tablet) {
                            font-size: 14px;
                        }
                    }

                    >span:nth-child(2) {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: 0.01em;
                        color: #031615;
                        margin-bottom: 2px;

                        @include respondTo(tablet) {
                            font-size: 12px;
                        }
                    }

                    >span:nth-child(3) {
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: 0.01em;
                        color: #4F4F4F;
                        margin-bottom: 8px;

                        @include respondTo(tablet) {
                            font-size: 12px;
                        }
                    }

                    >span:nth-child(4) {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 14px;
                        letter-spacing: 0.03em;
                        color: #17948C;

                        @include respondTo(tablet) {
                            font-size: 14px;
                        }
                    }

                }

                &__card__addition {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    >img {
                        width: 32px;
                        align-self: flex-end;

                        @include respondTo(tablet) {
                            width: 28px;
                        }
                    }

                    >div {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;

                        >div {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            margin-bottom: 6px;

                            @include respondTo(tablet) {
                                flex-direction: column;
                                align-items: flex-end;
                                margin-bottom: 2px;
                            }

                            >img {
                                width: 32px;
                                height: 32px;
                                margin-right: 13px;

                                @include respondTo(tablet) {
                                    margin-right: unset;
                                    width: 24px;
                                    height: 24px;
                                }
                            }

                            >span {
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 14px;
                                text-align: right;
                                letter-spacing: 0.03em;
                                color: #2A2A2A;

                                @include respondTo(tablet) {
                                    font-size: 14px;
                                }
                            }
                        }

                        >span {
                            font-size: 14px;
                            line-height: 21px;
                            text-align: right;
                            color: #2A2A2A;
                            mix-blend-mode: normal;
                            opacity: 0.4;
                            white-space: nowrap;

                            @include respondTo(tablet) {
                                font-size: 12px;
                            }
                        }

                        >button {
                            margin-top: 27px;
                            width: fit-content;
                            padding: 5px 21px;
                            background: #17948C;
                            border-radius: 10px;
                            color: #FFFFFF;

                            font-weight: 600;
                            font-size: 18px;
                            line-height: 24px;
                            letter-spacing: 0.03em;

                            @include respondTo(tablet) {
                                display: none;
                            }
                        }
                    }

                }
            }

        }
    }
}

.event-main__content__list__event__items--loader {
    padding: 0 16px;
    width: 100%;
    margin-bottom: 31px;

    >span>span {
        height: 150px;
        width: 100%;
        border-radius: 10px;
    }
}