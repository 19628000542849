.corporate{
    &__banner{
        background: url('../../Assets//corporate/bg-jumbotron.png');
        // height: 768px;
        background-size: cover;
        color: white;
        
        @include respondTo(tablet) {
            background: url('../../Assets//corporate/bg-jumbotron_mobile.png') no-repeat;
            background-size: contain;
        }

        &__action{
            padding: 150px 128px 100px 128px;
            color: #fff;

            >h2{
                width: 50%;
                font-size: 50px;
                font-weight: bold;
                line-height: 56px;

                @include respondTo(tablet){
                    font-size: 19px;
                    line-height: 31px;
                    width: 100%;
                }
            }
            >p{
                width: 50%;
                margin-top: 1.5rem;
                margin-bottom: 0;
                font-weight: 500;
                line-height: 24px;
                font-size: 15px;

                @include respondTo(tablet){
                    margin-top: 1rem;
                    width: fit-content;
                    background: cover;
                    font-size: 11px;
                    line-height: 21px;
                    font-weight: 500;
                }
            }

            >button{
                margin-top: 24px;
                border-radius: 15px;
                text-align: center;
                padding: 10.5px 32px;
                background: #F96D01;
                font-weight: 600;
                width: 260px;

                font-size: 15px;

                @include respondTo(tablet) {
                    display: block;
                    margin-top: 18px;
                    width: fit-content;
                    padding: 8px 16px;
                    font-size: 12px;
                    border-radius: 12px;
                }
            }
            @include respondTo(tablet) {
                padding: 20px 30px 20px 30px;
            }
        }

        &__wrapper {
            width: 100%;
            background: linear-gradient(0deg, #232D30, #232D30), rgba(196, 196, 196, 0.056);
            backdrop-filter: blur(83.928px);
            height: 115px;
            display: flex;
            justify-content: space-between;

            >div {
                padding: 0px 100px 0px 100px;
                margin: 0px auto;

                >span {
                    position: relative;
                    right: 50px;
                    top: 40px;
                }
                
                >h3 {
                    font-size: 15px;
                    font-weight: 500;
                }

                >p {
                    margin: 10px 0px 10px 0px;
                    font-size: 13px;
                    font-weight: 400;
                }
            }

            @include respondTo(tablet) {
                padding: 0px;
                height: fit-content;
                display: flex;
                justify-content: space-evenly;
                flex-direction: column;
                font-size: 12px;

                >div {
                    padding: 0px 10px 0px 60px;
                    margin: 0px;
                    >span {
                        position: relative;
                        right: 50px;
                        top: 40px;
                    }
                    
                    >h3 {
                        font-size: 15px;
                        font-weight: 500;
                    }
    
                    >p {
                        margin: 10px 0px 10px 0px;
                        font-size: 13px;
                        font-weight: 400;
                    }
                }

                &__line {
                    width: fit-content;
                    height: 10px;
                    >img {
                        position: relative;
                        right: 20px;
                        width: 100%;
                    }
                }
            }
        }
    }

    &__clients{
        border: 1px solid transparent;
        background: url("../../Assets//corporate/Section_2_rectangle.png");
        width: 90%;
        margin: 50px auto;
        
        @include respondTo(tablet){
            margin: 0px auto;
            width: 100%;
            padding: 32px 20px;
        }

        &__slider{
            display: block;

            >img {
                margin: 0px auto;
            }

            @include respondTo(tablet){
                >img {
                    width: 90%;
                }
            }
    
        }

        >h3{
            margin: 0px auto;
            width: 20%;
            color: black;
            font-size: 29px;
            line-height: 34px;
            text-align: center;
            margin-top: 60px;
            font-weight: 700;
            border-bottom: 3px solid #1DBAAF;
            
            @include respondTo(tablet){
                width: 40%;
                text-align: center;
                margin-top: 24px;
                font-size: 18px;
                line-height: 27px;
            }

        }

        >p{
            color: black;
            width: 50%;
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            margin: 20px auto;
            
            @include respondTo(tablet){
                width: 100%;
                font-weight: 400;
                text-align: center;
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    &__slider {
        border: 1px solid transparent;
        height: 50px;
    }

    &__manageCompany{
        display: flex;
        justify-content: space-around;

        &__info {
            padding: 50px 80px 50px 120px;

        &__forHR{
            color: #999999;
            font-size: 14px;
            font-weight: 600px;
            line-height: 19px;
            margin-bottom: 8px;
        }

        &__header{
            font-style: normal;
            color: black;
            font-size: 29px;
            font-weight: bold;
            margin-bottom: 18px;
            border-bottom: 3px solid #1DBAAF;
        }

        &__message{
            color: #666666;
            font-size: 14px;
            font-weight: 400px;
            margin-bottom: 12px;
        }

        &__checkMessage{
            color: black;
            font-weight: 500;
            font-size: 13px;
            margin-bottom: 24px;
        }
        
        >button{
            color: white;
            border-radius: 15px;
            text-align: center;
            padding: 10.5px 32px;
            background: #F96D01;
            font-weight: 600;
            // width: 260px;

            font-size: 15px;

            @include respondTo(tablet) {
                display: block;
                width: fit-content;
                padding: 8px 16px;
                font-size: 13px;
            }
        }
        @include respondTo(tablet) {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            padding: 0px;
            font-size: 13px;

            &__forHR {
                font-size: 12px;
            }

            &__header{
                font-size: 19px;
            }

            &__checkMessage{
                margin-bottom: 0px;
            }
        }
    }
   
    @include respondTo(tablet){
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            padding: 14px 10px;
            font-size: 14px;
            
        &__image {
            >img {
                width: 100%;
            }
        }
    }
}

    &__gainAccess{
        width: 90%;
        padding: 0px 50px;
        margin: 0px auto;
        display: flex;
        justify-content: space-around;
        color: white;
        background: url("../../Assets/corporate/gainAccess_rectangle.png");

        &__image {
            position: relative;
            padding: 100px 70px 0px 0px;
            width: fit-content;
        }

        &__info {
            padding: 100px 0px 0px 50px;

        &__forEmployee{
            color: #999999;
            font-size: 13px;
            font-weight: 600px;
            line-height: 19px;
            margin-bottom: 8px;
        }

        &__header{
            font-style: normal;
            color: black;
            font-size: 29px;
            font-weight: bold;
            margin-bottom: 18px;
        }

        &__title{
            color: #666666;
            font-size: 13px;
            font-weight: normal;
            margin-bottom: 16px;

            &__image {
                padding: 10px 0px;
            }
        }

        &__chooseSport{
            color: black;
            font-weight: 500;
            font-size: 13px;
            margin-bottom: 24px;
        }

        &__infoMessage{
            font-weight: 600;
            font-size: 13px;
            color: #999999;
            position: relative;
            top: -4px;
            left: 12px;
        }
        &__button {
        >button{
            color: white;
            border-radius: 15px;
            text-align: center;
            padding: 10.5px 32px;
            background: #F96D01;
            font-weight: 600;
            font-size: 15px;
            margin-bottom: 18px;
        }
    }
}

        @include respondTo(tablet){
            flex-direction: column-reverse;
            width: 100%;
            padding: 10px 0px 0px 20px;

            &__image {
                padding: 50px 0px 0px 0px;
                width: fit-content;

                >img {
                    width: 100%;
                }
            }
    
            &__info {
                padding: 50px 0px 0px 0px;
    
            &__forEmployee{
                color: #999999;
                font-size: 14px;
                font-weight: 600px;
                line-height: 19px;
                margin-bottom: 8px;
            }
    
            &__header{
                font-style: normal;
                color: black;
                font-size: 19px;
                font-weight: bold;
                margin-bottom: 18px;
            }
    
            &__title{
                color: #666666;
                font-size: 14px;
                font-weight: normal;
                margin-bottom: 16px;
    
                &__image {
                    padding: 10px 0px 0px 0px;

                    >img {
                        width: 20%;
                    }
                }
            }
    
            &__chooseSport{
                color: black;
                font-weight: 500;
                font-size: 13px;
                margin-bottom: 24px;
            }
    
            &__infoMessage{
                font-weight: 600;
                font-size: 13px;
                color: #999999;
                width: fit-content;
                top: -4px;
                left: 0px;
                padding: 0px 50px 0px 50px;
            }

            &__button {
                text-align: center;

                >button {
                    margin-top: 18px;
                    margin-bottom: 30px;
                    width: fit-content;
                    padding: 8px 16px;
                    font-size: 13px;
                }
            }
        }
    }
}

    &__wrapperHowItWorks {
        margin-top: 100px;

        >h3{
            margin: 0px auto;
            width: 25%;
            color: black;
            font-size: 29px;
            line-height: 34px;
            text-align: center;
            margin-top: 60px;
            font-weight: 700;
            border-bottom: 3px solid #1DBAAF;
            
            @include respondTo(tablet){
                text-align: center;
                margin-top: 24px;
                font-size: 18px;
                line-height: 27px;
                width: 50%;
            }
        }

    &__howItWorks {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 30px 270px;

        &__left {
            width: 50%;

        &__imageGroup {
            z-index: 1;
            width: fit-content;
            margin-top: 20px;

            >img {
                width: 110%;
            }
        }

        &__leftMessage {
            width: 90%;
            padding: 20px 0px;
            margin-top: 40px;

            >h3 {
                font-weight: bold;
                font-size: 15px;
            }

            >p {
                font-size: 13px;
                color: #666666;
                margin-top: 20px;
            }
        }

        &__invoices {
            width: fit-content;
            margin: 50px 0px;
        }
    }

    &__right {
        padding: 0px 20px;
        width: 50%;

    &__imageGroup {
        z-index: 1;
        width: fit-content;
        margin-top: 100px;
    }

    &__rightMessage {
        width: 90%;
        margin-top: 50px;

        >h3 {
            font-weight: bold;
            font-size: 15px;
        }

        >p {
            font-size: 13px;
            color: #666666;
            margin-top: 20px;
        }
    }

    &__rightMessage2 {
        width: 90%;
        margin-top: 60px;

        >h3 {
            font-weight: bold;
            font-size: 15px;
        }

        >p {
            font-size: 13px;
            color: #666666;
            margin-top: 20px;
        }
    }
}

    &__separateLine {
        text-align: center;
        padding: 50px 20px;
        width: fit-content;

        &__background {
            padding: 10px 30px;
            position: absolute;
            z-index: 0;
        }

        &__part {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            position: relative;
            z-index: 1;
        }
    }

        >img {
            width: 100%;
        }
        
        @include respondTo(tablet) {
            padding: 0px 0px 10px 0px;
            display: flex;
            justify-content: flex-start;

            &__part {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                position: relative;
                margin-top: 20px;
                z-index: 1;
            }

            &__left {
            &__leftMessage {
                padding: 0px;
                margin-top: 10px;

                >p {
                    margin-top: 0px;
                }

                >img {
                    height: 80%;
                    width: 80%;
                    }
                }

                &__leftMessageEnglish {
                    padding: 0px;
                    margin-top: 60px;

                    >h3 {
                        font-weight: bold;
                        font-size: 15px;
                    }
    
                    >p {
                        margin-top: 0px;
                    }
    
                    >img {
                        height: 80%;
                        width: 80%;
                    }
                }
            }

            &__right {
                width: 80%;
                padding: 0px;
                &__rightMessage {
                    >p {
                        font-size: 13px;
                        color: #666666;
                        margin-top: 5px;
                    }
                }

                &__rightMessage2 {
                    width: 90%;
                    margin-top: 40px;
            
                    >h3 {
                        font-weight: bold;
                        font-size: 15px;
                    }
            
                    >p {
                        font-size: 13px;
                        color: #666666;
                        margin-top: 5px;
                        
                    }

                    >img {
                        height: 50%;
                        width: 80%;
                    }
                }

                &__rightMessage2English {
                    width: 90%;
                    margin-top: 50px;
            
                    >h3 {
                        font-weight: bold;
                        font-size: 15px;
                    }
            
                    >p {
                        font-size: 13px;
                        color: #666666;
                        margin-top: 5px;
                        
                    }

                    >img {
                        height: 50%;
                        width: 80%;
                    }
                }
            }
        }
    }
}

    &__emailForm {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: url("../../Assets/corporate/Rectangle_email.png");
        background-repeat: no-repeat;

        &__header {
            margin: 50px auto;
            color: white;
            text-align: center;

        >h3 {
            margin: 0px auto;
            width: 60%;
            font-weight: bold;
            font-size: 29px;
        }

        >p {
            width: 80%;
            margin: 0px auto;
            font-size: 13px;
            margin-top: 20px;
        }
    }

    &__wrapper {
        border: 1px solid transparent;
        background: #FFFFFF;
        border-radius: 12px;
        margin: 20px auto 100px auto;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        width: 700px;
        height: 464px;
        padding: 0px 0px 100px 0px;

        >h4{
            margin: 20px auto;
            font-weight: bold;
            font-size: 23px;
            width: fit-content;
        }
    }

    &__field {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 20px;

        >textarea {
            border-radius: 5px;
            padding: 10px;
            resize: none;
            margin-bottom: 20px;
        }

        >button{
            color: white;
            border-radius: 15px;
            text-align: center;
            padding: 10.5px 32px;
            background: #F96D01;
            font-weight: 600;
            font-size: 15px;
            margin-bottom: 18px;

            @include respondTo(tablet) {
                display: flex;
                justify-content: center;
                margin: 20px auto;
                width: 240px;
                padding: 8px 16px;
                font-size: 13px;
                text-align: center;
                border-radius: 12px;

                >span {
                    margin: 20px auto;
                }
            }
        }
    }
    @include respondTo(tablet) {
        &__header {
            margin: 20px auto;
            >h3 {
                font-size: 19px;
                width: 100%;
                padding: 20px;
            }

            >p{
                margin-top: 0px;
                padding: 20px;
                font-size: 13px;
                width: 100%;
            }
        }

        &__wrapper {
            margin: 0px auto 50px auto;
            padding: 20px;
            width: 350px;
            min-height: 700px;
        }

        &__field {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 20px;
            width: 100%;
    
            >textarea {
                margin: 10px 15px 0px 15px;
                padding: 10px;
            }
        }
    }
}
}

.corporate-contact {
    display: flex;
    border-radius: 16px;
    height: 550px;
    width: 50vw;

    @include respondTo(tablet) {
        width: 90vw;
    }

    >* {
        width: 100%;
        background-color: #fff;
    }

    &__img {
        border-radius: 16px 0 0 16px;
        position: relative;
        background-image: url('../../Assets/corporate/bgc-modal.png');
        display: flex;
        justify-content: center;
        align-items: center;

        @include respondTo(tablet) {
            display: none;
        }

        img {
            position: absolute;
            height: 100%;
        }

        div {
            color: #fff;
            font-size: 39px;
            width: 80%;
            text-align: center;
        }
    }

    &__form {
        background-color: #ffffff;
        border-radius: 0 16px 16px 0;
        display: flex;
        flex-direction: column;
        color: #222;
        padding: 15px;

        @include respondTo(tablet) {
            border-radius: 16px;
        }

        >img {
            align-self: flex-end;
            cursor: pointer;
        }

        &__input {
            display: flex;
            flex-direction: column;
            padding: 0 25px;

            &__info {

                &--success {
                    width: 100%;
                    font-size: 10px;
                    color: #155724;
                    background-color: #d4edda;
                    margin-bottom: 14px;

                    padding: 6px 10px;
                    border-radius: 5px;
                    border: 1px solid transparent;

                }

                &--error {
                    width: 100%;
                    font-size: 10px;
                    color: #fff;
                    background: #f44336;
                    margin-bottom: 14px;

                    padding: 6px 10px;
                    border-radius: 5px;

                }
            }

            >span:nth-of-type(1) {
                font-size: 31px;
                font-weight: 400;
            }

            >span:nth-of-type(2) {
                margin-bottom: 15px;
            }

            >input {
                padding: 14px 16px;
                margin-bottom: 10px;
                border-radius: 5px;
                border: 1px solid #444;
                width: 100%;
            }

            >button {
                color: #fff;
                font-size: 14px;
                width: 100%;
                border-radius: 5px;
                background: -webkit-linear-gradient(180deg, #3e7ec0, #36bec3);
                padding: 14px 10px;
                text-align: center;
                display: flex;
                justify-content: center;
            }
        }
    }
}

.doocoach-subscribe{
    background: #134D62 !important;

    >form{
        >div{
            >.subscribe__button{
                background: #1F7899 !important;
            }
        }
    }
}


.doocoach-footer{
    background-color: #0D3544 !important;
}