.notFound {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  padding: 14rem;
  background: #0A423F;

  h3 {
    font-size: 64px;
    font-weight: bold;
    color: #ffffff
  }

  p {
    font-size: 16px;
    color: #ffffff
  }

  button {
    background: #17948C;
    border-radius: 10px;
    color: #ffffff;
    font-size: 16px;
    padding: .5rem 1rem;
  }
}