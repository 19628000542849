.partner {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 47px 150px;

   @include respondTo(tablet) {
      padding: 32px 0;
   }
}

.partner__cards {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
   column-gap: 19px;
   row-gap: 29px;
   width: 100%;
   place-items: center;

   @include respondTo(tablet) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 10px;
      row-gap: 15px;
      overflow: scroll;
      padding-bottom: 2em;
   }
}

.partner__card {
   width: 161px;
   height: 81px;
   padding: 16px;
   background: rgba($color: #FFFFFF, $alpha: .1);
   border: 1px solid #CCCCCC;
   border-radius: 15px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   overflow: hidden;

   img {
      filter: gray;
      /* IE6-9 */
      -webkit-filter: grayscale(1);
      /* Google Chrome, Safari 6+ & Opera 15+ */
      filter: grayscale(1);
      /* Microsoft Edge and Firefox 35+ */
      object-fit: none;

      @include respondTo(tablet) {
         filter: none;
         width: 100%;
         height: 100%;
         object-fit: contain;
      }
   }


   &:hover {
      img {
         -webkit-filter: grayscale(0);
         filter: none;
      }
   }

   @include respondTo(tablet) {
      width: 95px;
      height: 47px;
      padding: 8px;
   }
}

.partner__card-more {
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 15px;
   line-height: 32px;
   text-align: center;
   color: #0A423F;

   @include respondTo(tablet) {
      font-size: 12px;
      line-height: 14px;
   }
}

.text__partner-title {
   font-size: 30px;
   font-weight: bold;
   line-height: 64px;
   text-align: center;
   color: #18191F;
   margin-bottom: 29px;

   @include respondTo(tablet) {
      font-size: 18px;
      line-height: 27px;
   }
}

.partner__button {
   background: #17948C;
   border-radius: 10px;
   font-weight: bold;
   font-size: 18px;
   line-height: 113%;
   color: #FFFFFF;
   padding: 13px 18px;
   margin-top: 25px;

   @include respondTo(tablet) {
      font-size: 14px;
      padding: 8px 12px;
      margin-top: 0;
   }
}