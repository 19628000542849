.button {
   &__primary {
      background: #17948C;
      border-radius: 15px;
      width: 100%;
      padding: 12px 0;

      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #FFFFFF;
   }

   &__primary--disabled {
      background: #C9CFCE;
      border-radius: 15px;
      width: 100%;
      padding: 12px 0;

      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #FFFFFF;
   }

   &__secondary {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #17948C;
   }

   &__outer {
      border: 1px solid #17948C;
      box-sizing: border-box;
      border-radius: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #17948C;
      padding: 5px 16px;
   }
}