.privacy-policy {

    header {
        display: grid;
        place-items: center;
        background: rgba($color: #17948C, $alpha: 0.6);
        padding: 66px 0 90px;
        position: relative;
    }

    svg {
        position: absolute;
        left: 5rem;
        bottom: 0;

        @include respondTo(tablet) {
            left: -0.5rem;
            bottom: -2rem;
            z-index: 1;
            width: 50%;
        }
    }

    button {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #A2FFF9;
        cursor: pointer;

        @include respondTo(tablet) {
            font-size: 12px;
            line-height: 18px;
            z-index: 3;
        }
    }
}

.privacy-policy__content {
    position: relative;
    z-index: 1;
    margin-top: -3rem;
    padding: 59px 15rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 50px 50px 0 0;

    @include respondTo(tablet) {
        padding: 22px 22px;
        border-radius: 20px 20px 0 0;
        box-shadow: none;
        z-index: 3;
    }
}

.privacy-policy__terms {
    position: relative;
    background: #F5F9F9;
    z-index: 1;
    display: grid;
    place-items: center;
    padding: 64px 15rem;

    @include respondTo(tablet) {
        padding: 0;
        padding-bottom: 65px;
    }
}

.privacy-policy__card {
    background: #FFFFFF;
    border: 1px solid #DBE1E1;
    border-radius: 10px;
    padding: 31px 32px;
    display: grid;
    place-items: center;

    @include respondTo(tablet) {
        padding: 20px 0 0;
        border: none;
        border-radius: 0;
    }

    h4 {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: #17948C;
        position: relative;
        text-align: center;
        width: 100%;
        z-index: 3;

        @include respondTo(tablet) {
            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                background: #F5F9F9;
                width: 100%;
                height: 10rem;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: 40%;
            height: 3px;
            background: #17948C;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);

            @include respondTo(tablet) {
                width: 100%;
            }
        }
    }
}

.privacy-policy__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 97px;
    margin-top: 64px;

    @include respondTo(tablet) {
        grid-template-columns: 1fr;
        background: #FFFFFF;
        border: 1px solid #DBE1E1;
        width: 100%;
        padding: 20px 30px;
        margin-top: 34px;
        z-index: 3;
    }
}

.privacy-text__title {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    color: #FFFFFF;

    @include respondTo(tablet) {
        font-size: 24px;
        line-height: 36px;
        z-index: 3;
    }
}

.privacy-text__subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #031615;
    margin: 40px 0 25px;

    @include respondTo(tablet) {
        font-weight: 500;
        font-size: 14px;
        margin: 12px 0;
    }
}

.privacy-text__content {
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #323232;

    @include respondTo(tablet) {
        font-size: 12px;
    }
}

.privacy-text__link {
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    color: #17948C;
    cursor: pointer;

    @include respondTo(tablet) {
        font-size: 12px;
    }
}

.terms-text__subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #031615;
    margin-bottom: 8px;
}

.terms-text__content {
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    color: #323232;
}

.terms-text__link {
    font-weight: bold;
    font-size: 12px;
    line-height: 26px;
    color: #323232;
    cursor: pointer;
}