@mixin globalCustomScroll {
   &::-webkit-scrollbar {
      width: 15px;
      background-color: rgba($color: #F2F2F2, $alpha: 0.6);
      -webkit-border-radius: 10px;
   }

   &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 10px;
      background-color: #BDBDBD;
   }

   &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
   }

   &::-webkit-scrollbar-corner {
      background-color: #F2F2F2;
   }
}

/*  Mixin for Media Queries
    How to use: 
    @include respondTo (phone/tablet/desktop/) {
      // respondTo code for tablet
       ...
    }    
*/
@mixin respondTo ($breakpoint) {

   @if $breakpoint==small--phone {
      @media only screen and (max-width: 330px) {
         @content;
      }
   }

   @if $breakpoint==semismall--phone {
      @media only screen and (max-width: 365px) {
         @content;
      }
   }


   @if $breakpoint==phone {
      @media only screen and (max-width: 767px) {
         @content;
      }
   }

   @if $breakpoint==tablet {
      @media only screen and (max-width: 991px) {
         @content;
      }
   }

   @if $breakpoint==desktop--small {
      @media only screen and (max-width: 1199px) {
         @content;
      }
   }

   @if $breakpoint==desktop {
      @media only screen and (min-width: 992px) {
         @content;
      }
   }

   @if $breakpoint==large {
      @media only screen and (min-width: 1440px) {
         @content;
      }
   }
}