.detailBooking__banner {
	display: flex;
	flex-direction: column;
	margin-right: 25px;
	width: 100%;

	@media #{$phoneMQ} {
		width: 100%;
	}

	&__image {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #ddd;
		border-radius: 20px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		width: 100%;
		min-height: 229px;
		margin-bottom: 25px;

		@media #{$phoneMQ} {
			width: 100%;
			height: 206px;
			margin: 0;
			border-radius: 0px;
		}

		&__ticket {
			background-repeat: no-repeat;
			background-size: cover;
			display: flex;
			flex-direction: column;

			// width: 315px;
			// height: 138.7px;
			width: 243px;
			height: 107px;
			padding-left: 70px;

			@media #{$phoneMQ} {
				width: 243px;
				height: 107px;
			}

			> table {
				font-size: 8px;
				letter-spacing: 0.01em;
				margin: 12px 0 12px 0;

				td {
					vertical-align: top;
				}
			}

			> tr > td:nth-child(1) {
				color: #000000;
			}

			> tr > td:nth-child(2) {
				color: #828282;
			}

			&__value {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 8rem;
			}

			> span {
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #000000;
			}
		}
	}
}
