.text__banner {
   font-weight: 600;
   font-size: 18px;
   line-height: 17px;
   text-align: center;
   letter-spacing: 0.01em;
   color: #FFFFFF;
}

.text__banner-info {
   font-weight: bold;
   font-size: 36px;
   line-height: 17px;
   text-align: center;
   letter-spacing: 0.01em;
   color: #FFFFFF;
}

.text__status {
   font-weight: 600;
   font-size: 14px;
   line-height: 14px;
   letter-spacing: 0.03em;
}

.text__status--cancel {
   color: #604606;
}

.text__status--active {
   color: #27AE60;
}

.text__status--pending {
   color: #EB5757;
}

.text__bookingID {
   font-size: 14px;
   line-height: 24px;
   letter-spacing: 0.03em;
   color: #828282;
}

.text__mobile-title {
   font-weight: 600;
   font-size: 14px;
   line-height: 21px;
   letter-spacing: 0.03em;
   color: #333333;
}

.text__info {
   font-size: 14px;
   line-height: 17px;
   letter-spacing: 0.01em;
   color: #000000;
}

.text__info-title {
   font-size: 14px;
   line-height: 17px;
   letter-spacing: 0.01em;
   color: #828282;
}

.text__amount {
   font-weight: 600;
   font-size: 14px;
   line-height: 19px;
   letter-spacing: 0.03em;
   color: #FAFAFA;
}

.text__bill-title {
   font-weight: 600;
   font-size: 10px;
   line-height: 10px;
   color: #031615;
}

.text__bill-info {
   font-weight: 600;
   font-size: 14px;
   line-height: 19px;
   letter-spacing: 0.03em;
   color: #17948C;
}

.text__bill-sub {
   font-size: 11px;
   line-height: 24px;
   letter-spacing: 0.03em;
   color: #17948C;
}

.text__time-up {
   font-weight: 600;
   font-size: 18px;
   line-height: 17px;
   letter-spacing: 0.01em;
}

.text__time--pending {
   font-size: 12px;
   line-height: 17px;
   text-align: center;
   letter-spacing: 0.01em;
   color: #031615;
   margin-bottom: 16px;
}

.text__bank {
   font-size: 12px;
   line-height: 17px;
   letter-spacing: 0.01em;
   color: #031615;
}

.text__title-atm {
   font-weight: 500;
   font-size: 14px;
   line-height: 19px;
   letter-spacing: 0.01em;
   color: #000000;
   margin-bottom: 10px;
}

.text__step {
   font-size: 12px;
   line-height: 17px;
   align-items: center;
   letter-spacing: 0.01em;
   color: #000000;
}

.text__payment {
   font-weight: 600;
   font-size: 18px;
   line-height: 24px;
   letter-spacing: 0.03em;
   color: #828282;
}

.membership-mobile__title {
   margin: 32px 0 18px;
}

.membership-mobile__banner {
   background: #4AC7BF;
   padding: 47px 37px 64px;
}

.membership-mobile__banner-title {
   margin-bottom: 25px;
}

.membership-mobile__banner--default {
   background: #17948C;
}

.membership-mobile__info {
   background: #FFFFFF;
   padding: 22px 16px;
   margin-top: -20px;
   border-radius: 20px 20px 0px 0px;
}

.membership-mobile__payment-status {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.payment-status {
   border-radius: 10px;
   padding: 4px 18px;
}

.payment-status--cancel {
   background: #FCEFCF;
}

.payment-status--active {
   background: #D5F6E3;
}

.payment-status--pending {
   background: #FAD1D1;
}

.payment-status__info {
   width: fit-content;
}

.membership-mobile__info {
   >button {
      background: #17948C;
      border-radius: 10px;
      width: 100%;
      margin-top: 22px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: #FFFFFF;
      padding: 12px 0;
   }
}

.membership-mobile__border {
   border: 1px solid #E0E0E0;
   border-radius: 10px;
   padding: 18px;
}

.grid {
   display: grid;
}

.membership-mobile__grid {
   grid-template-columns: 50px 5px auto;
   column-gap: 10px;
   row-gap: 12px;
}

.membership-mobile__benefit-grid {
   grid-template-columns: 42px auto;
   column-gap: 12px;
   align-items: center;

   &:not(:last-child) {
      margin-bottom: 12px;
   }

   p,
   span {
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #495050;
      margin: 0;
   }
}

.membership-mobile__benefit-amount {
   width: 42px;
   height: 42px;
   border-radius: 10px;
   margin-right: 12px;
   background: #1F7899;
   display: flex;
   justify-content: center;
   align-items: center;
}

.membership-mobile__benefit-amount--default {
   background: #66D57B;
}

.membership-mobile__title-info {
   margin-bottom: 14px;
}

.membership-mobile__billed {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.membership-mobile__amount {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: 28px;
}

.membership-mobile__time {
   border-radius: 10px;
   padding: 16px 40px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top: 18px;
}

.membership-mobile__time--pending {
   background: #FAD1D1;

   tr:not(:last-child) {
      td {
         font-weight: 600;
         font-size: 21px;
         line-height: 24px;
         text-align: center;
         letter-spacing: 0.015em;
         color: #EB5757;
      }

      td:nth-child(even) {
         padding: 0 10px;
      }
   }

   tr:last-child {
      td {
         font-weight: 500;
         font-size: 14px;
         line-height: 14px;
         text-align: center;
         letter-spacing: 0.03em;
         color: #EB5757;
      }
   }

   button {
      margin-top: 39px;
      background: rgba(250, 63, 63, 0.15);
      border: 1px solid #EB5757;
      border-radius: 7px;
      padding: 6px 24px;
      font-size: 12px;
      line-height: 124%;
      text-align: center;
      letter-spacing: 0.03em;
      color: #EB5757;
   }
}

.membership-mobile__time--cancel {
   background: #FCEFCF;
   color: #604606;
   margin-bottom: 50px;
}

.membership-mobile__transfer-section {
   background: rgba(29, 186, 175, 0.15);
   border-radius: 10px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 10px 16px;

   span {
      display: block;
   }

   button {
      background: rgba(29, 186, 175, 0.16);
      border: 1px solid #1DBAAF;
      border-radius: 7px;
      font-size: 12px;
      line-height: 124%;
      letter-spacing: 0.03em;
      color: #1DBAAF;
      padding: 6px 11px;
   }
}

.space-between {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.membership-mobile__tutor {
   padding: 5px 16px 30px;

   >button {
      background: #17948C;
      border-radius: 10px;
      width: 100%;
      margin-top: 22px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: #FFFFFF;
      padding: 12px 0;
   }
}

.membership-mobile__tutorial {
   background: #FFFFFF;
   border-radius: 10px;
   padding: 19px 16px;

   >button {
      margin-top: 23px;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.03em;
      color: #17948C;
   }
}

.membership-mobile__tutorial-step {
   grid-template-columns: 9px 8px auto;
}

.membership-mobile__payment-grid {
   row-gap: 10px;
}

.membership-mobile__qrcode {
   background: #FFFFFF;
   margin-top: 18px;
   padding: 24px 47px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   img {
      width: 279px;
      height: 273px;
   }

   >button {
      background: #17948C;
      border-radius: 10px;
      width: 100%;
      margin-top: 22px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: #FFFFFF;
      padding: 12px 0;
   }
}

.membership-mobile__qrcode-title {
   font-weight: 600;
   font-size: 12px;
   line-height: 12px;
   letter-spacing: 0.03em;
   text-align: center;
   color: #333333;
}