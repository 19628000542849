.promo{

    &__header{
        &__title{
            display: flex;
            justify-content: center;
            background: url('../../Assets/background/promo-background.png');
            height: 250px;
            background-size: cover;

            @include respondTo(tablet){
                height: 140px;
            }

            >h2{
                color: #fff;
                font-weight: 600;
                font-size: 48px;

                margin-top: 65px;
                margin-bottom: 50px;

                @include respondTo(tablet){
                    font-size: 24px;
                    margin-top: 42px;
                    margin-bottom: 21px;
                }
            }
        }   
        
        &__description{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 47px 120px;
            background-color: #fff;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
            margin-top: -50px;
            
            @include respondTo(tablet){
                justify-content: space-between;
                padding: 30px 25px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }

            >p{
                color: #0A423F;
                font-size: 15px;
                font-weight: 400;
                text-align: center;
                margin-right: 17%;
                
                @include respondTo(tablet){
                    margin-right: 0;
                    width: 70%;
                    text-align: left;
                    margin-right: 22px;
                    font-size: 12px;
                }
            }

            >aside{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                cursor: pointer;
                
                >svg{
                    @include respondTo(tablet){
                        width: 12px;
                        height: 13px;
                    }
                }

                >span{
                    color: #17948C;
                    font-size: 18px;
                    font-weight: 600;
                    margin-left: 16px;
                    
                    @include respondTo(tablet){
                        margin-left: 7px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &__body{
        background-color: #F5F9F9;
        // padding: 32px 173px;
        padding: 32px 0;
        // height: 768px;

        @include respondTo(tablet){
            height: auto;
            padding: 19px 16px;
        }

        &__tabs{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 34px;

            @include respondTo(tablet){
                justify-content: flex-start;
                margin-bottom: 22px;
                overflow-x: scroll;
                padding-bottom: 10px;
            }

            >button{
                margin: 0 12px;
                padding: 8px 18px;
                border-radius: 20px;
                
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                
                color: #17948C;
                border: 1px solid #17948C;
                
                @include respondTo(tablet){
                    padding: 8px 9px;
                    margin: 0 8px;
                }

                &.active{
                    color: #fff;
                    background-color: #17948C;
                }
            }
        }

        &__emptyState{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 20px 0;

            >p{
                color: #0A423F;
                font-size: 15px;
                font-weight: 600;
                margin-top: 39px;
                text-align: center;
            }
        }

        &__loadingState{
            padding: 0px 10rem;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
            grid-column-gap: 36px;
            column-gap: 36px;
            grid-row-gap: 36px;
            row-gap: 36px;

            @include respondTo(tablet) {
                row-gap: 16px;
                padding: 0px;
            }

            &__card{
                padding: 0 16px;

                @include respondTo(tablet){
                    padding: 0px;
                }
            }
        }

        &__items{
            padding: 0 10rem;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
            grid-column-gap: 36px;
            column-gap: 36px;
            grid-row-gap: 36px;
            row-gap: 36px;

            @include respondTo(tablet) {
                padding: 0px;
                row-gap: 16px;
            }
        

            &__card{
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                background-color: #fff;

                >img{
                    height: 114px;
                    background-color: #ddd;
                    object-fit: cover;
                    width: 100%;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }   

                >div{
                    height: 63px;
                    padding: 16px 0;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    >aside{
                        width: 18px;
                        height: 28px;
                        background-color: #ebefef;
                    }

                    >.clipLeft{
                        margin-left: -4px;
                        border-bottom-right-radius: 26px;
                        border-top-right-radius: 26px;
                        box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.2);
                    }

                    >.clipRight{
                        margin-right: -4px;
                        border-bottom-left-radius: 26px;
                        border-top-left-radius: 26px;
                        box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.2);
                    }

                    >section {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        width: 100%;
                        margin: 0 14px;

                        >div{
                            >h4{
                                font-weight: 600;
                                font-size: 12px;
                                color: #17948C;

                                >span{
                                    color: #666;
                                    font-weight: 400;
                                }
                            }

                            >p{
                                background-color: #FAD1D1;
                                text-align: center;
                                color:#EB5757;
                                padding: 2px 8px;
                                border-radius: 29px;
                                font-size: 9px;
                                width: 75px;
                                margin-top: 5px;
                            }
                        }

                        >button{
                            padding: 5px 20px;
                            border: 1px solid #DBE1E1;
                            border-radius: 10px;
                            text-align: center;
                            font-weight: 600;
                            font-size: 12px;
                            color: #17948C;
                        }
                    }
                }
            }
        }
    }
}