.fitTrainer {
  // padding: 45px 120px;

  @include respondTo(tablet) {
    // padding: 50px 16px 0;
  }
}

.fitTrainer__header {
  padding: 45px 120px 0;
  margin-bottom: 33px;

  @include respondTo(tablet) {
    padding: 25px 16px 0;
    margin-bottom: 12px;
  }
}

.fitTrainer__cards-wrapper {
  padding: 1rem;
  overflow: hidden;

  @include respondTo(tablet) {
    padding: .8rem;
  }
}

.fitTrainer__card {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;

  >img {
    width: 100%;
    height: 173px;
    object-fit: cover;
    object-position: 50% 20%;

    @include respondTo(tablet) {
      height: 103px;
    }
  }
}

.fitTrainer__card__info {
  padding: 16px 18px;

  span:not(:last-of-type) {
    &::after {
      content: ', ';
    }
  }
}

.fitTrainer--title {
  font-weight: bold;
  font-size: 30px;
  line-height: 48px;
  color: #0A423F;
  width: 45%;

  @include respondTo(tablet) {
    font-size: 18px;
    line-height: 113%;
    margin-bottom: 8px;
    width: 100%;
  }
}

.fitTrainer--info {
  font-size: 18px;
  line-height: 32px;
  color: #0A423F;

  @include respondTo(tablet) {
    font-size: 12px;
    line-height: 18px;
  }
}

.fitTrainer--card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #000000;

  @include respondTo(tablet) {
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.fitTrainer--card-loc {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #2A2A2A;
  margin: 8px 0;

  @include respondTo(tablet) {
    font-size: 12px;
    line-height: 18px;
    margin: 4px 0 2px;
  }
}

.fitTrainer--card-sport {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #AFB6B6;

  @include respondTo(tablet) {
    font-size: 10px;
    line-height: 16px;
  }
}

.fitTrainer--card-price {
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #17948C;
  margin-top: 18px;

  @include respondTo(tablet) {
    font-size: 14px;
    line-height: 14px;
    margin-top: 14px;
  }
}

.fitTrainer__card-btn {
  display: flex;
  font-weight: bold;
  font-size: 18px;
  line-height: 113%;
  color: #FFFFFF;
  padding: 13px 18px;
  background: #17948C;
  border-radius: 10px;
  margin-left: auto;
  margin-top: 12px;

  @include respondTo(tablet) {
    display: none;
  }
}