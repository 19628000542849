.doofood {

    &__info {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 27px;

        background-color: #EFD50C;

        @include respondTo(tablet) {
            padding: 18px 16px;
        }

        &__container {
            display: flex;
            justify-content: center;
            flex-direction: column;

            margin-top: -90px;
            max-width: 545px;

            @include respondTo (tablet) {
                flex-direction: row;
                margin-top: unset;
            }
        }

        &__bg {
            position: absolute;
            right: 0;

            @include respondTo (tablet) {
                display: none;
            }
        }

        &__logo {
            width: 127px;
            height: 127px;
            object-fit: cover;
            align-self: center;
            margin-bottom: 4px;

            @include respondTo(tablet) {
                width: 74px;
                height: 74px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            color: #604606;

            @include respondTo(tablet) {
                align-items: flex-start;
                text-align: left;
            }

            >span:nth-child(1) {
                font-weight: bold;
                font-size: 36px;
                line-height: 113%;
                margin-bottom: 13px;

                @include respondTo(tablet) {
                    font-size: 18px;
                }
            }

            >span:nth-child(2) {
                font-size: 15px;
                line-height: 147.5%;
                letter-spacing: 0.02em;

                @include respondTo(tablet) {
                    font-size: 12px;
                }
            }
        }
    }

    &__goal {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 16px;

        position: relative;

        @include respondTo(tablet) {
            padding: 32px 16px;

        }

        &__bg {
            position: absolute;

            @include respondTo(tablet) {
                display: none;
            }

            &:nth-child(1) {
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
                top: 0;
                left: 0;

            }

            &:nth-child(2) {
                bottom: 0;
                right: 0;
            }
        }

        h3 {
            font-weight: bold;
            font-size: 30px;
            line-height: 64px;
            text-align: center;
            color: #323232;


            margin-bottom: 48px;


            @include respondTo(tablet) {
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 12px;
            }
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 30px;
            z-index: 2;

            @include respondTo(tablet) {
                grid-template-columns: repeat(2, 1fr);

            }


            &__item {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 24px;

                background: #FFFFFF;
                box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
                border-radius: 8px;
                max-width: 255px;
                border-bottom: 5px solid transparent;


                &:hover {
                    transition: 0.5s !important;
                    border-bottom: 5px solid #EFD50C;
                }

                >img {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    margin-bottom: 12px;

                    @include respondTo(tablet) {
                        width: 48px;
                        height: 48px;
                    }
                }

                >.lazyImageWrapper {
                    width: 80px;
                    height: 80px;
                    margin-bottom: 12px;

                    @include respondTo(tablet) {
                        width: 48px;
                        height: 48px;
                    }

                    >img {
                        width: 80px;
                        height: 80px;
                        object-fit: cover;
                    }
                }

                span {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    color: #323232;
                    text-align: center;
                    cursor: default;


                    @include respondTo(tablet) {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                    }
                }
            }
        }
    }

    &__batch {

        &__title {
            padding: 45px 100px 0px;

            @include respondTo(tablet) {
                padding: 45px 16px 0px;
            }

            >h3 {
                font-weight: bold;
                font-size: 30px;
                line-height: 64px;
                font-feature-settings: 'liga'off;
                color: #052523;
                margin-bottom: 8px;

                @include respondTo(tablet) {
                    font-size: 18px;
                    line-height: 113%;
                }
            }
        }
    }

    &__membership {
        padding: 63px 127px 167px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include respondTo(tablet) {
            padding: 16px;

            >div:nth-child(2) {
                width: 100%;
                justify-content: center;
            }
        }


        &__info {
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;

            img {
                width: 80px;
                height: 80px;
                margin-bottom: 8px;

                @include respondTo(tablet) {
                    width: 24px;
                    height: 24px;
                }

            }

            h3 {
                font-weight: bold;
                font-size: 30px;
                line-height: 48px;
                font-feature-settings: 'liga'off;
                color: #0A423F;
                margin-bottom: 8px;

                @include respondTo(tablet) {
                    font-size: 18px;
                    line-height: 27px;
                }
            }

            span {
                font-size: 18px;
                line-height: 32px;
                font-feature-settings: 'liga'off;
                color: #0A423F;
                margin-bottom: 21px;

                @include respondTo(tablet) {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            a {
                padding: 13px 18px;
                background: #EFD50C;
                border-radius: 10px;

                font-weight: bold;
                font-size: 18px;
                line-height: 113%;
                color: #604606;
                width: fit-content;
                transform: scale(0.98);


                @include respondTo(tablet) {
                    display: none;
                }

                &:hover {
                    transition: 0.5s;
                    transform: scale(1);

                }
            }

        }


        &__item {
            margin: 0 auto;
            position: relative;
            padding: 14px;
            width: fit-content;
            min-width: 369px;
            min-height: 327px;
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2)), drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
            border-radius: 20px;
            background-image: url('../../Assets/doofood/background-membership.png');

            @include respondTo(tablet) {
                min-width: min-content;
            }

            >div {
                background: #FBFEFE;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
                border-radius: 15px;
                margin-bottom: 18px;
            }

            &__info {
                display: flex;
                flex-direction: column;
                padding: 13px 21px 42px;
                max-width: 369px;

                >span {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.03em;
                    color: #031615;
                    margin-bottom: 17px;
                }

                >div {
                    display: flex;

                    >span:nth-child(1) {
                        height: fit-content;
                        margin-right: 19px;
                        padding: 12px 8px;

                        background: #66D57B;
                        border-radius: 10px;
                        white-space: nowrap;

                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        color: #FAFAFA;
                    }

                    >span:nth-child(2) {
                        font-size: 15px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #495050;
                    }
                }

            }

            &__price {
                padding: 14px 17px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;

                svg {
                    width: 24px;
                    height: 24px;
                    margin-right: 14px;
                }

                >div:first-child {
                    position: absolute;
                    margin-top: -20px;

                    background: #114B5F;
                    border-radius: 5px;

                    font-weight: bold;
                    font-size: 10px;
                    line-height: 10px;
                    color: #FFFFFF;
                    padding: 4px 8px;
                    width: fit-content;
                }

                >div:nth-child(2) {
                    display: flex;
                    width: 100%;
                    align-items: center;

                    >*:nth-child(2) {
                        font-weight: 600;
                        font-size: 10px;
                        line-height: 10px;
                        color: #031615;
                    }

                    >*:nth-child(3) {
                        margin-left: auto;

                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        color: #17948C;
                    }
                }
            }
        }

        &__item__cta {
            display: none;
            color: #FFFFFF;


            @include respondTo(tablet) {
                margin: 32px auto 0;
                width: fit-content;
                text-align: center;

                display: block;
                padding: 13px 18px;
                background: #EDAE0F;
                border-radius: 10px;

                font-weight: bold;
                font-size: 18px;
            }
        }


    }

    &__card {
        padding: 80px 16px;

        @include respondTo(tablet) {
            padding: 18px 16px;
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: column;

            max-width: 1058px;
            min-height: 293px;
            margin: 0 auto;
            padding: 47px 48px;

            background: #EFD50C;
            border-radius: 20px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);

            >span {
                z-index: 2;

                @include respondTo(tablet) {
                    text-align: center;
                }
            }

            >span:nth-of-type(1) {
                font-weight: bold;
                font-size: 30px;
                line-height: 30px;
                font-feature-settings: 'liga'off;
                color: #4F4F4F;

                @include respondTo(tablet) {
                    margin-top: 251px;
                    font-size: 18px;
                    line-height: 30px;
                }
            }

            >span:nth-of-type(2) {
                margin-top: 29px;
                max-width: 533px;
                font-size: 18px;
                line-height: 32px;
                font-feature-settings: 'liga'off;
                color: #5F5504;

                @include respondTo(tablet) {
                    margin-top: 8px;
                    font-size: 12px;
                    line-height: 20px;
                }
            }

            >img {
                border-radius: 20px;
                max-width: 100%;

                position: absolute;
                bottom: 0;
                right: 0;

                @include respondTo(tablet) {
                    bottom: unset;
                    top: 0;
                    border-radius: 0 20px 0 0;
                }
            }
        }
    }
}


//complex secttion
.batch__packages {
    &__carousel {

        .owl-stage-outer {
            z-index: 10;
            max-width: calc(100% - 244px);
            margin: 0 auto;

            @include respondTo(tablet) {
                max-width: unset;
            }
        }

        .owl-nav {
            font-size: 32px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            position: absolute;
            top: 173px;

            @include respondTo(tablet) {
                display: none;
            }

            >* {
                height: 32px;
                width: 32px;
                background-size: 23px;
                background-repeat: no-repeat, repeat;
            }

            .owl-prev {
                background-image: url('../../Assets//icon/arrow-back.svg');
                margin-left: 24px;
            }

            .owl-next {
                background-image: url('../../Assets//icon/arrow-next.svg');
                margin-right: 24px;

            }
        }
    }

    &__item-container {
        display: flex;
        justify-content: center;

        width: 350px;
        padding: 10px 0;
        margin: 0px 33px;

        @include respondTo(tablet) {
            width: 155px;
            margin: 0px 16px;

        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        width: 100%;

        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            .batch__packages__item__desc {
                height: 173px;

                @include respondTo(tablet) {
                    height: 103px;
                }

                >div {
                    padding: 12px;
                    opacity: 1;
                    filter: alpha(opacity=100);
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                }

                img {
                    filter: brightness(0.6);
                }
            }

        }

        &__desc {
            position: relative;

            height: 173px;

            @include respondTo(tablet) {
                height: 103px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px 10px 0 0;
            }

            div {
                position: absolute;
                top: 0;
                left: 0;

                font-size: 14px;
                font-weight: 700;
                color: #fff;
                padding: 1em 0;

                opacity: 0;
                filter: alpha(opacity=0);
                -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
                transition: opacity 0.35s, transform 0.35s;
                -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);

                height: 100%;
                overflow-y: auto;

                @include respondTo(tablet) {
                    height: 100%;
                    font-size: 12px;
                    font-weight: 500;

                }
            }
        }

        &__date {
            z-index: 2;
            display: flex;
            flex-direction: column;
            padding: 28px 8px;
            font-size: 12px;
            background-color: #fff;
            border-radius: 0 0 10px 10px;

            >span {
                max-width: 350px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @include respondTo(tablet) {
                    max-width: 155px;
                }
            }

            >span:nth-child(1) {
                font-weight: 600;
                font-size: 21px;
                line-height: 30px;
                letter-spacing: 0.03em;
                color: #000000;

                @include respondTo(tablet) {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 14px;
                    letter-spacing: 0.03em;
                }
            }

            >span:nth-child(2) {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #798685;

                @include respondTo(tablet) {
                    font-size: 11px;
                    line-height: 14px;
                }
            }

            >span:nth-child(3) {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #AFB6B6;

                @include respondTo(tablet) {
                    font-size: 11px;
                    line-height: 14px;
                }
            }

            >span:nth-child(4) {
                margin-top: 18px;

                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
                letter-spacing: 0.03em;
                color: #BFAA09;

                @include respondTo(tablet) {
                    font-size: 14px;
                    line-height: 17px;
                    margin-top: 8px;
                }
            }
        }
    }
}

.doofood__testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 80px 16px;
    background-color: #FAF2B6;

    @include respondTo(tablet) {
        padding: 18px 16px;
    }

    &__message {
        display: flex;
        align-items: flex-start;

        width: 854px;
        min-height: 232px;

        padding: 34px 32px;
        border-radius: 10px;
        background-color: #fff;
        margin-bottom: 36px;

        @include respondTo(tablet) {
            padding: 34px 16px;
            flex-direction: column;
            justify-content: center;

            width: 100%;
            min-height: 271.01px;
        }

        >span:first-child {
            font-weight: 800;
            font-size: 72px;
            margin-top: -29px;
            margin-right: 20px;
            font-feature-settings: 'liga'off;
            color: #BFAA09;
            font-family: Manrope;

            @include respondTo(tablet) {
                margin-bottom: -51px;
            }

        }

        >div:not(:first-child) {
            display: none;

        }

        &--active {
            display: block !important;
            animation: scale-up-left .4s cubic-bezier(.39, .575, .565, 1.000) both;

            @include respondTo(tablet) {
                font-size: 12px;
                text-align: left;
            }

        }
    }

    &__list {
        display: flex;

        &__item {
            display: flex;

            @include respondTo(tablet) {
                flex-direction: column;
            }

            &:not(:last-child) {
                margin-right: 80px;

                @include respondTo(tablet) {
                    margin-right: 34px;

                }
            }

            >img {
                object-fit: cover;
                border-radius: 50%;
                min-width: 56px;
                min-height: 56px;
                cursor: pointer;
                width: 56px;
                height: 56px;

                @include respondTo(tablet) {
                    min-width: 45px;
                    min-height: 45px;
                    width: 45px;
                    height: 45px;

                }
            }

            >div {
                display: none;
            }
        }

        &__item--active {
            @extend .doofood__testimonial__list__item;

            >div {
                position: relative;
                display: flex;
                flex-direction: column;
                margin: 16px;
                color: #18191F;
                font-feature-settings: 'liga'off;
                width: 100%;



                >div {
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: -53px;
                    right: 24px;
                    border: 15px solid transparent;
                    border-top: none;
                    transform: scaleY(-1);
                    border-bottom-color: #fff;
                    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));

                    @include respondTo(tablet) {
                        margin: 0 auto;
                        top: -97px;
                    }

                }

                >span {
                    animation: scale-up-ver-top .4s cubic-bezier(.39, .575, .565, 1.000) both;

                    @include respondTo(tablet) {
                        display: none;
                    }
                }

                >span:nth-of-type(1) {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 28px;

                    @include respondTo(tablet) {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }

                >span:nth-of-type(2) {
                    font-size: 14px;
                    line-height: 24px;

                    @include respondTo(tablet) {
                        font-size: 12px;
                        line-height: 22px;
                    }
                }
            }
        }
    }

    &__people__mobile {
        >div {
            display: none;

            >span:nth-of-type(1) {
                font-weight: bold;
                font-size: 18px;
                line-height: 28px;

                @include respondTo(tablet) {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            >span:nth-of-type(2) {
                font-size: 14px;
                line-height: 24px;

                @include respondTo(tablet) {
                    font-size: 12px;
                    line-height: 22px;
                }
            }
        }

        &--active {
            flex-direction: column;
            align-items: center;
            animation: scale-up-ver-top .4s cubic-bezier(.39, .575, .565, 1.000) both;

            @include respondTo(tablet) {
                display: flex !important;
            }
        }
    }
}

@keyframes scale-up-ver-top {
    0% {
        transform: scaleY(.4);
        transform-origin: 100% 0
    }

    100% {
        transform: scaleY(1);
        transform-origin: 100% 0
    }
}

@keyframes scale-up-left {
    0% {
        transform: scale(.5);
        transform-origin: 0 50%
    }

    100% {
        transform: scale(1);
        transform-origin: 0 50%
    }
}