.fitMembership {
  display: grid;
  grid-template-columns: auto 400px;
  grid-template-rows: 30rem;
  padding: 45px 127px 0;
  position: relative;

  @include respondTo(tablet) {
    padding: 16px 16px 70px;
    display: flex;
    flex-direction: column;
  }
}

.fitMembership__header {
  width: 100%;

  @include respondTo(tablet) {
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.fitMembership__header-content {
  margin-top: 8px;
}

.fitMembership--title {
  font-weight: bold;
  font-size: 30px;
  line-height: 48px;
  color: #0A423F;

  @include respondTo(tablet) {
    font-size: 18px;
    line-height: 27px;
  }
}

.fitMembership--sub {
  font-size: 18px;
  line-height: 32px;
  color: #0A423F;
  margin: 8px 0;

  @include respondTo(tablet) {
    font-size: 12px;
    line-height: 18px;
  }
}

.fitMembership--info {
  font-weight: bold;
  font-size: 38px;
  line-height: 48px;
  color: #0A423F;

  @include respondTo(tablet) {
    font-size: 18px;
    line-height: 27px;
  }

  span {
    font-size: 24px;

    @include respondTo(tablet) {
      font-size: 12px;
      line-height: 48px;
    }
  }
}

.fitMembership__header-btn {
  font-weight: bold;
  font-size: 18px;
  line-height: 113%;
  color: #FFFFFF;
  margin-top: 32px;

  @include respondTo(tablet) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }

  button {
    padding: 13px 18px;
    background: #17948C;
    border-radius: 10px;
  }
}

.fitMembership__content {
  width: 100%;
}
// .membership__content__list__item > div{
//   background: linear-gradient(180deg, rgba(241, 184, 89, 0.50) 0%, rgba(241, 184, 89, 0.00) 100%) !important;
// }

.fitMembership__card {
  background: url(../../Assets/background/membership-card.png) no-repeat;
  background-size: 100% 100%;
  max-width: 369px;
  overflow: hidden;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2)), drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
  border-radius: 20px;
  padding: 14px;
}

.fitMembership__card-header {
  background: #FBFEFE;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 13px 21px;
}

.fitMembership__card-title {
  display: flex;
  align-items: center;

  img {
    position: relative;

    &:nth-of-type(1) {
      z-index: 5;
    }

    &:nth-of-type(2) {
      transform: translateX(-50%);
      z-index: 1;
    }
  }
}

.fitMembership__card--title {
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #031615;
  margin-right: 5px;
}

.fitMembership__card-benefit {
  margin-top: 14px;

  >div {
    display: grid;
    grid-template-columns: 42px auto;
    grid-template-rows: 42px;
    column-gap: 12px;
    margin-bottom: 12px;

    &:nth-child(1) {
      h3 {
        background: #1F7899;
      }
    }

    &:nth-child(2) {
      h3 {
        background: #66D57B;
      }
    }
  }

  h3 {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #FAFAFA;
  }

  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #495050;
  }
  
  h5 {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #495050;
  }
}

.fitMembership__card-option {
  margin-top: 28px;
}

.fitMembership__option-list {
  background: #FFFFFF;
  padding: 14px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 13px;
}

.fitMembership__label {
  position: absolute;
  left: 50%;
  top: 0;
  background: #114B5F;
  border-radius: 5px;
  padding: 4px 8px;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  color: #FFFFFF;
  transform: translate(-50%, -50%);
}

.fitMembership__option-info {
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    color: #031615;
  }

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #17948C;
    display: block;
  }
}

.fitMembership__option-price {
  display: flex;
  align-items: center;

  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #17948C;
  }

  span {
    font-size: 11px;
    line-height: 24px;
    color: #17948C;
  }
}

.fitMembership__card-astra {
  display: grid;
  grid-template-columns: 60px auto 10px;
  align-items: center;
  column-gap: 9px;
}

.fitMembership__card-astra-img {
  background: #FFFFFF;
  border-radius: 10px;
  display: grid;
  place-items: center;
  min-height: 32px;
}

.fitMembership__card-astra-content {
  h3 {
    font-size: 10px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #F7F8F8;
  }

  h2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #F7F8F8;
  }
}