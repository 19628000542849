.membershipAbout{
    padding: 62px 100px 0 100px;
    z-index: 0;

    @media #{$tabletMQ} {
        padding: 28px;
    }

    &__content {
        z-index: 2;
        display: flex;
        flex-direction: column;

        &__header{
            display: flex;
            align-items: center;

            >a{
                >svg{
                    margin-right: 24px;
                    cursor: pointer;
                }
            }

            >h2{
                margin-top: 10px;
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                line-height: 27px;
                letter-spacing: 0.03em;
                color: #000000;

                @media #{$tabletMQ} {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.03em;
                    color: #031615;
                }
            }
        }

        &__body{
            margin-top: 70px;   
            display: flex;
            
            @media #{$tabletMQ} {
                flex-direction: column;
                margin-top: 57px;   
            }

            >.left-content{
                margin-right: 84px;
                
                @media #{$tabletMQ} {
                    margin-right: 0;
                }

                >section{
                    display: flex;
                    margin-bottom: 39px;

                    >svg{
                        margin-right: 21px;
                    }

                    >div{
                        width: 262px;
                        
                        @media #{$tabletMQ} {
                            width: 100%;
                        }

                        >h4{
                            font-style: normal;
                            font-weight: bold;
                            font-size: 15px;
                            color: #495050;

                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1; /* number of lines to show */
                            -webkit-box-orient: vertical;
                        }

                        >p{ 
                            margin-top: 12px;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 17px;
                            letter-spacing: 0.01em;
                            color: #495050;
                        }
                    }
                }
            }

            >.right-content{
                >section{
                    display: flex;
                    margin-bottom: 39px;

                    >svg{
                        margin-right: 21px;
                    }

                    >div{
                        width: 262px;

                        @media #{$tabletMQ} {
                            width: 100%;
                        }

                        >h4{
                            font-style: normal;
                            font-weight: bold;
                            font-size: 15px;
                            color: #495050;
                            
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1; /* number of lines to show */
                            -webkit-box-orient: vertical;
                        }

                        >p{ 
                            margin-top: 12px;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 17px;
                            letter-spacing: 0.01em;
                            color: #495050;
                        }

                        >div{
                            display: flex;
                            
                            >div{
                                padding: 8px 12px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 22px;
                                cursor: pointer;

                                >span{
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 12px;
                                    line-height: 17px;
                                    letter-spacing: 0.01em;
                                    color: #FFFFFF;
                                    margin-right: 16px;
                                    
                                }
                            }

                            >div:nth-child(1){
                                background: #2F80ED;
                                margin-right: 12px;
                            }

                            >div:nth-child(2){
                                background: #66D57B;
                            }
                        }
                    }
                }
            }
        }
    }

    >svg{
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;

        @media #{$tabletMQ} {
            display: none;
        }
    }
    
}