.detailBooking--mobile {
	display: flex;
	flex-direction: column;
}

.detailBooking--mobile__card {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
	padding: 31px 21px;
	width: 100%;
	border-radius: 20px 20px 0 0;
	margin-top: -31px;
	z-index: 2;

	&__line {
		width: 42px;
		height: 2px;
		background: #bdbdbd;
		border-radius: 2px;
		position: absolute;
		top: 274px;
		left: 168px;
	}

	&__status {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 32px;

		&__canceled,
		&__confirmed,
		&__pending {
			padding: 4px 18px;
			border-radius: 10px;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 14px;
			letter-spacing: 0.03em;
		}

		&__canceled {
			background: #fcefcf;
			color: #604606;
		}

		&__confirmed {
			background: #d5f6e3;
			color: #27ae60;
		}

		&__pending {
			background: #fad1d1;
			color: #eb5757;
		}

		>span {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.03em;
			color: #828282;
		}
	}

	&__location {
		>span {
			font-style: normal;
			font-weight: 600;
			font-size: 21px;
			line-height: 24px;
			letter-spacing: 0.015em;
			color: #000000;
		}

		&__venue {
			display: flex;
			align-items: center;
			margin-top: 12px;
			margin-bottom: 28px;

			>span {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 19px;
				letter-spacing: 0.01em;
				color: #828282;
				margin-left: 6px;
			}
		}

		&__date {
			display: flex;
			border: 1px solid #bdbdbd;
			box-sizing: border-box;
			border-radius: 20px;
			align-items: center;
			margin-bottom: 32px;
			padding: 10px 12px;
			width: fit-content;

			>span {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #031615;
				margin-left: 4px;
			}
		}
	}

	&__link {
		&__title {
			display: flex;
			align-items: center;
			justify-content: space-between;

			>span:nth-child(1) {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
				letter-spacing: 0.03em;
				color: #333333;
			}

			>span:nth-child(2) {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				text-align: right;
				letter-spacing: 0.03em;
				color: #17948c;
			}
		}

		&__access {
			padding: 12px 18px;

			margin-top: 12px;
			background: #f7f8f8;
			border: 1px solid #c9cfce;
			box-sizing: border-box;
			border-radius: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 22px;

			>span {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #17948c;

				height: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				/* number of lines to show */
				-webkit-box-orient: vertical;
			}

			>span:nth-child(1) {
				width: 94%;
			}
		}
	}

	&__amount {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 16px;
		margin-bottom: 32px;

		>span:nth-child(1) {
			font-style: normal;
			font-weight: 500;
			line-height: 17px;
			letter-spacing: 0.01em;
			font-size: 14px;
			color: #031615;
		}

		>span:nth-child(2) {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			letter-spacing: 0.01em;
			color: #17948c;
			font-size: 14px;
		}
	}
}