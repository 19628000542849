%membership-detail__payment__subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #495050;

    @media #{$tabletMQ} {
        font-size: 14px;
        color: #000000;
    }
}

.membership-detail {
    display: flex;
    margin: auto;
    width: 100%;
    padding: 46px 0px;

    @media #{$tabletMQ} {
        flex-direction: column;
        padding: 0;
    }


    &--desktop-left-container {
        width: 65%;

        @media #{$tabletMQ} {
            width: 100%;

            >div:nth-child(2) {
                display: none;
            }
        }



    }

    &--desktop-right-container {
        margin-left: 25px;
        width: 35%;

        @media #{$tabletMQ} {
            width: 100%;
            margin-left: 0px;
            z-index: 2;
        }
    }

    &__banner {
        height: 229px;
        background: linear-gradient(0deg, #126B65, #126B65), #000000;
        border-radius: 20px;

        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @media #{$tabletMQ} {
            border-radius: 0;
        }


        &__title {
            font-weight: bold;
            font-size: 36px;
            line-height: 45px;
            color: #FFFFFF;
            text-align: center;

            @media #{$tabletMQ} {
                font-size: 21px;
                line-height: 30px;
            }
        }

        &__status {
            position: absolute;
            left: 23px;
            bottom: 19px;

            @media #{$tabletMQ} {
                z-index: 3;
                left: 16px;
                bottom: 0px;
            }

            >span {
                padding: 6px 18px;
                border-radius: 10px;

                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
                letter-spacing: 0.03em;
            }
        }
    }

    &__payment {
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 32px 46px;
        margin-top: 24px;

        @media #{$tabletMQ} {
            padding: 27px 16px;

        }

        &__title {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.03em;
            color: #000000;
            margin-bottom: 47px;

            @media #{$tabletMQ} {
                display: none;
            }

        }

        &__content-container {
            width: 100%;

            @media #{$tabletMQ} {
                tr {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 23px;
                }
            }

            >tr>td {
                padding-bottom: 36px;

                @media #{$tabletMQ} {
                    padding-bottom: 10px;

                }
            }

            >tr>td:first-child {
                text-align: left;
            }

            >tr>td:last-child {
                text-align: right;

                @media #{$tabletMQ} {
                    text-align: left;
                }
            }
        }


        &__id {
            >td:first-child {
                @extend %membership-detail__payment__subtitle;
            }

            >td:nth-child(2) {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #828282;

                @media #{$tabletMQ} {
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.01em;
                }
            }
        }

        &__status {
            >td:first-child {
                @extend %membership-detail__payment__subtitle;
            }

            >td:nth-child(2)>span {
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                letter-spacing: 0.03em;

                padding: 8px 12px;
                border-radius: 15px;

                @media #{$tabletMQ} {
                    font-size: 16px;
                    line-height: 24px;
                }

            }

        }

        &__method {
            >td:first-child {
                @extend %membership-detail__payment__subtitle;
            }

            >td:nth-child(2) {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #828282;

                @media #{$tabletMQ} {
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.01em;
                }

            }

        }

        &__amount {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.03em;
            color: #828282;

            >td:first-child {
                @extend %membership-detail__payment__subtitle;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media #{$tabletMQ} {
                    justify-content: flex-start;
                }
            }

            img {
                cursor: pointer;
                width: 24px;
                height: 24px;
                margin-left: 24px;
            }



        }

        &__amount__detail-title {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;

            letter-spacing: 0.03em;
            color: #000000;
        }

        &__amount__detail-price {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.03em;
            color: #828282;

            @media #{$tabletMQ} {
                font-size: 14px;
                line-height: 19px;
            }
        }


    }

    &__info {
        display: flex;
        flex-direction: column;
        padding: 31px 23px;

        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        border-radius: 20px;

        @media #{$tabletMQ} {
            border-radius: 20px 20px 0 0;
            margin-top: -45px;
            padding: 31px 23px;
            padding: 65px 23px;
            box-shadow: unset;
        }

        &__title {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.03em;
            color: #333333;
            margin-bottom: 13px;

        }

        &__user {
            background: #FFFFFF;
            padding: 18px;
            border: 1px solid #E0E0E0;
            box-sizing: border-box;
            border-radius: 10px;
            margin-bottom: 32px;

            td {
                padding-bottom: 12px;
                vertical-align: top;
            }

            tr>td:nth-child(1) {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #000000;
                padding-right: 12px;
                white-space: nowrap;
            }

            tr>td:nth-child(2) {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #828282;
                padding-right: 2px;
            }

            tr>td:nth-child(3) {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #828282;
                word-break: break-all;
            }
        }

        &__membership {
            background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 18px;

            &__subtitle {
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
                letter-spacing: 0.03em;
                color: #031615;
                margin-bottom: 11px;
            }

            &__text {
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #031615;
                margin-bottom: 17px;
            }

            &__benefit {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                padding: 14px 16px;
                background: #D1F1EF;
                border-radius: 10px;
                display: flex;
                justify-content: space-between;

                >span:nth-child(1) {
                    font-size: 12px;
                    line-height: 17px;
                    letter-spacing: 0.01em;
                    color: #031615;
                }

                >span:nth-child(2) {
                    font-size: 12px;
                    line-height: 17px;
                    letter-spacing: 0.01em;
                    color: #1DBAAF;
                }
            }
        }
    }

    &__info__date-and-price {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.03em;
        margin: 29px 0 24px;

        >div:nth-child(1) {
            >span:nth-child(1) {}

            >span:nth-child(2) {
                color: #333333;
            }

            margin-bottom: 10px;
        }

        >div:nth-child(2) {
            >span:nth-child(1) {
                color: #17948C;
            }

            >span:nth-child(2) {
                color: #333333;

            }
        }
    }

    &__info__download {
        >div {
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #000000;
            margin-bottom: 16px;
        }

        >img {
            width: 86.98px;
            height: 27.18px;
            object-fit: cover;

            &:not(:first-child) {
                margin-right: 13px;
            }
        }
    }

    &__info__payment--mobile-container {
        display: none;
        margin-top: 32px;


        @media #{$tabletMQ} {
            display: block;
        }

        >div:first-child {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.03em;
            color: #333333;
        }
    }

}


@media (min-width: 992px) {
    .membership-detail {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .membership-detail {
        max-width: 1140px;
    }
}