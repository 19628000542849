%tnc-navigation {
    padding: 30px 20px;
    font-size: 12px;
    width: calc(100% - 4px);
}


.faq-header {
    min-height: 500px;
    background-image: url('../../Assets/faq/banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 120px;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 41px;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        margin-bottom: 32px;
    }

    &__search {
        background-color: #fff;
        border-radius: 5px;
        padding-right: 20px;
        display: flex;
        align-items: center;

        input {
            width: 100%;
            padding: 16px 20px;
        }

        >svg {
            cursor: pointer;
        }

    }
}

.faq-main {
    display: flex;
    padding: 100px 0;

    @include respondTo(tablet) {
        flex-direction: column;
        padding: 100px 5%;
    }
}

.faq-main__nav {
    display: flex;
    flex-direction: column;
    min-width: 175px;
    max-width: 175px;
    margin-right: 50px;

    @include respondTo(tablet) {
        width: 100%;
        min-width: unset;
        max-width: unset;
    }

    >div {
        text-align: center;
    }

    >div:nth-child(1) {
        font-size: 12px;
        border-radius: 5px 5px 0 0;
        background: #e1e1e1;
        padding: 30px 20px;
        padding: 8px 0;
        border-bottom: 1px solid #fff;
        width: 100%;
    }

    &--idle {
        @extend %tnc-navigation;
        cursor: pointer;
        color: #666;
        background: #f5f5f5;

    }

    &--active {
        @extend %tnc-navigation;
        cursor: pointer;
        color: #666;
        font-weight: 500;
        border-right: 4px solid #1f7999;
        border-image-source: linear-gradient(0deg, #67d77d, #1f7999);
        border-image-slice: 4;
        background-color: #fff;
    }

    &--seperator {
        @extend %tnc-navigation;
        color: #222;
        background-color: #e1e1e1
    }
}

.faq-main__content {
    color: #555;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    text-align: justify;
    width: 100%;

    h2 {
        margin-bottom: 21px;
    }

    >ol {
        padding-left: 12px;

        li {
            margin-bottom: 6px;
        }
    }

    p {
        padding-bottom: 6px;
    }

    b {
        color: #222;
    }
}