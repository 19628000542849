.meta--primary {
    color: #33333360;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.03em;
}

.meta--success {
    color: #17948C;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.03em;
}

.meta--danger {
    color: #EB5757;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.03em;
}