.venuePackage {
   padding-top: 36px;

   @media #{$tabletMQ} {
      padding: 24px 12px 0;
   }
}

.venuePackage__card {
   background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   padding: 18px 24px;
   margin-bottom: 24px;
   position: relative;

   @media #{$tabletMQ} {
      margin-bottom: 14px;
   }
}

.venuePackage__title {
   font-weight: 600;
   font-size: 18px;
   line-height: 19px;
   letter-spacing: 0.03em;
   color: #000000;
   margin-bottom: 14px;

   @media #{$tabletMQ} {
      font-size: 14px;
      margin-bottom: 3px;
   }
}

.venuePackage__session {
   font-size: 14px;
   line-height: 21px;
   letter-spacing: 0.01em;
   color: #4F4F4F;
   margin-bottom: 24px;

   @media #{$tabletMQ} {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 23px;
   }
}

.venuePackage__disc {
   font-weight: 500;
   font-size: 14px;
   line-height: 14px;
   letter-spacing: 0.03em;
   text-decoration-line: line-through;
   color: #BDBDBD;
   margin-bottom: 5px;

   @media #{$tabletMQ} {
      margin-bottom: 3px;
   }
}

.venuePackage__price {
   font-weight: 600;
   font-size: 18px;
   line-height: 14px;
   letter-spacing: 0.03em;
   color: #17948C;
   margin-bottom: 10px;

   @media #{$tabletMQ} {
      font-size: 14px;
      margin-bottom: 1px;
   }
}

.venuePackage__info {
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 24px;
   letter-spacing: 0.03em;
   color: #EB5757;
}

.venuePackage__btn {
   position: absolute;
   right: 20px;
   bottom: 18px;
   padding: 5px 21px;
   background: #17948C;
   border-radius: 10px;
   font-weight: 600;
   font-size: 18px;
   line-height: 24px;
   text-align: center;
   letter-spacing: 0.03em;
   color: #FFFFFF;
}

.venue-package {
   background: #FFFFFF;
   margin-top: -5.5rem;
}

.venue-package__header {
   background: #FFFFFF;
   position: relative;
   padding-bottom: 60px;
   padding: 19px 113px;
   border-radius: 47px 47px 0 0;
   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);

   @media #{$tabletMQ} {
      padding: 22px 16px;
      border-radius: 20px 20px 0 0;
   }
}

.venue-package__title {
   font-weight: 600;
   font-size: 36px;
   line-height: 54px;
   letter-spacing: 0.015em;
   margin-bottom: 3px;
   width: 60%;

   @media #{$tabletMQ} {
      font-size: 21px;
      line-height: 24px;
      margin-bottom: 12px;
      width: 90%;
   }
}

.venue-package__location {
   display: flex;
   align-items: center;

   span {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: #828282;
      margin-left: 4px;

      @media #{$tabletMQ} {
         width: 50%;
         white-space: nowrap;
         text-overflow: ellipsis;
         overflow: hidden;
      }
   }
}

.venue-package__share {
   position: absolute;
   top: 34px;
   right: 192px;
   display: flex;
   align-items: center;
   cursor: pointer;

   @media #{$tabletMQ} {
      right: 16px;
   }

   span {
      display: block;
      margin-left: 12px;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #17948C;

      @media #{$tabletMQ} {
         display: none;
      }
   }
}

.venue-package__main {
   width: 100%;
   display: flex;

   @media #{$tabletMQ} {
      flex-direction: column;
   }
}

.venue-package__main-info {
   width: 50%;
   padding: 50px 115px;

   @media #{$tabletMQ} {
      width: 100%;
      padding: 27px 16px;
      border-bottom: 1px solid #C4C4C4;
   }
}

.venue-package__main-title {
   font-weight: bold;
   font-size: 14px;
   line-height: 19px;
   letter-spacing: 0.01em;
   color: #031615;
   margin-bottom: 4px;

   &.hide {
      @media #{$tabletMQ} {
         display: none;
      }
   }
}

.venue-package__main-desc {
   font-weight: 500;
   font-size: 14px;
   line-height: 15px;
   letter-spacing: 0.01em;
   color: #4F4F4F;
   margin-bottom: 25px;

   @media #{$tabletMQ} {
      &.mobile {
         margin-bottom: 0;
      }
   }
}

.venue-package__main-price {
   font-weight: 600;
   font-size: 14px;
   line-height: 14px;
   letter-spacing: 0.03em;
   color: #17948C;
   margin-bottom: 32px;
}

.venue-package__main-disc {
   font-weight: 600;
   font-size: 12px;
   line-height: 24px;
   letter-spacing: 0.03em;
   text-decoration-line: line-through;
   color: #828282;
}

.venue-package__main-tutor {
   display: flex;
   justify-content: space-between;
   margin-top: 17px;

   @media #{$tabletMQ} {
      display: none;
   }
}

.venue-package__main-tutor__bubble {
   width: 138px;
}

.bubble__title {
   border: 1px solid #BDBDBD;
   border-radius: 19px;
   padding: 8px 31px;
   text-align: center;
   font-weight: bold;
   font-size: 12px;
   line-height: 19px;
   letter-spacing: 0.01em;
   margin-bottom: 16px;
}

.bubble__desc {
   font-weight: 500;
   font-size: 12px;
   line-height: 15px;
   text-align: center;
   letter-spacing: 0.01em;
   color: #828282;
}

.venue-package__main-content {
   background: #E2ECEC;
   width: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;

   @media #{$tabletMQ} {
      width: 100%;
   }
}

.venue-package__main-card {
   background: #FFFFFF;
   border-radius: 10px;
   padding: 20px 31px;

   @media #{$tabletMQ} {
      border-radius: 0;
      width: 100%;
      padding: 27px 16px;
   }
}

.main-card__item-card {
   background: rgba(29, 186, 175, 0.15);
   border-radius: 10px;
}

.item-card__content {
   padding: 16px;
   display: flex;
   align-items: center;
   justify-content: space-between;

   &:not(:last-child) {
      border-bottom: 1px solid #D1F1EF;
   }
}

.item-card__title {
   display: block;
   font-size: 14px;
   line-height: 24px;
   letter-spacing: 0.03em;
}

.item-card__desc {
   font-size: 10px;
   line-height: 16px;
   letter-spacing: 0.015em;
   color: rgba($color: #000000, $alpha: 0.5);
   position: relative;
   width: 250px;
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}


.item-card__session {
   display: block;
   font-weight: 500;
   font-size: 14px;
   line-height: 19px;
   letter-spacing: 0.01em;
   margin-left: 125px;
}

.main-card__title {
   font-weight: 500;
   font-size: 14px;
   line-height: 19px;
   letter-spacing: 0.01em;
   color: #031615;
   margin-bottom: 18px;
}

.main-card__btn {
   width: 100%;
   filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
   background: #17948C;
   border-radius: 10px;
   font-weight: 600;
   font-size: 18px;
   line-height: 24px;
   text-align: center;
   letter-spacing: 0.03em;
   color: #FFFFFF;
   padding: 16px 70px;
   margin-top: 26px;

   &.disabled {
      background: grey;
   }
}

.venuePackage__loader {
   width: 100%;
}

.venuePackage__skeleton {
   width: 343px;
   height: 88px;

   &.button {
      height: 56px;
   }
}