.subscribe {
  background: #052523;
  display: grid;
  grid-template-columns: 30% auto;
  column-gap: 20px;
  padding: 51px 135px 72px;

  @include respondTo(tablet) {
    display: flex;
    flex-direction: column;
    padding: 18px 16px 32px;
  }
}

.subscribe__info {
  h2 {
    margin-bottom: 23px;

    @include respondTo(tablet) {
      margin-bottom: 8px;
    }
  }
}

.subscribe__form {
  background: #FFFFFF;
  border-radius: 25px;
  padding: 27px 44px;

  @include respondTo(tablet) {
    padding: 11px 12px;
    border-radius: 15px;
    margin-top: 8px;
  }
}

.subscribe__input {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    &:nth-of-type(2) {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #BDBDBD;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 13px 26px;
      font-size: 15px;
      line-height: 123.5%;

      @include respondTo(tablet) {
        font-size: 12px;
        padding: 9px 12px 10px;
      }
    }

    &:nth-of-type(3) {
      background: #17948C;
      border-radius: 9px;
      font-weight: bold;
      font-size: 18px;
      line-height: 113%;
      color: #FFFFFF;
      padding: 13px 62px;
      margin-left: 47px;

      @include respondTo(tablet) {
        padding: 13px 19px;
        margin-left: 14px;
        font-size: 12px;
      }
    }
  }
}

.text__subscribe-label {
  font-weight: 500;
  font-size: 15px;
  line-height: 123.5%;
  color: #031615;
  display: block;
  margin-bottom: 16px;

  @include respondTo(tablet) {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.text__subscribe-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 113%;
  color: #FBFEFE;

  @include respondTo(tablet) {
    font-size: 18px;
  }
}

.text__subscribe-info {
  font-size: 15px;
  line-height: 147.5%;
  letter-spacing: 0.02em;
  color: #FAFAFA;

  @include respondTo(tablet) {
    font-size: 12px;
  }
}