.calendar {
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	width: 343px;
	// height: 100%;
	background: #ffffff;
	padding: 24px;

	&__header {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 25px;

		&__title {
			font-family: Poppins;
			font-style: normal;
			font-weight: 600;
			font-size: 21px;
			line-height: 24px;
			letter-spacing: 0.015em;
			color: #555555;
		}

		&__img {
			width: 24px;
			height: 24px;
			background: #f7f8f8;
			border-radius: 10.3699px;
			align-items: center;
			display: flex;
			justify-content: center;
			cursor: pointer;
		}
	}

	&__weeks {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-left: 5px;
		padding-right: 5px;
		margin-bottom: 26px;

		&__time {
			font-family: Poppins;
			font-weight: 600;
			font-size: 14px;
			line-height: 14px;
			text-align: center;
			letter-spacing: 0.03em;
			color: #555555;
		}
	}

	&__availableTime {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 25px;

		&__title {
			font-family: Poppins;
			font-weight: 500;
			font-size: 17px;
			line-height: 14px;
			text-align: center;
			letter-spacing: 0.03em;
			color: #555555;
		}
	}

	&__days {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 22px;

		&__currentViewDate {
			width: 31.17px;
			height: 31.13px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&__body {
			width: 100 / 7%;
			justify-content: center;
			align-items: center;
		}

		&__date {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			text-align: center;
			letter-spacing: 0.01em;
			color: #555555;
			cursor: pointer;
		}

		&__span {
			width: 100%;
			height: 0px;
			left: calc(50% - 342px / 2 - 0.5px);
			top: 329px;
			border: 1px solid #c4c4c4;
			margin-top: 22px;
			margin-bottom: 12px;
		}
	}

	&__sectionTitle {
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 17px;
		letter-spacing: 0.01em;
		color: #000000;
		margin-bottom: 18px;
	}

	&__time {
		@include globalCustomScroll();
		display: flex;
		// justify-content: space-between;
		padding: 10px 0;
		margin-bottom: 19px;
		width: 100%;
		overflow-x: auto;


		&__capsule {
			background: #ffffff;
			border: 1px solid #17948c;
			box-sizing: border-box;
			border-radius: 22px;
			align-items: center;
			display: flex;
			white-space: nowrap;
			padding: 10px 12px;
			cursor: pointer;

			&:not(:last-child) {
				margin-right: 14px;
			}

			&:hover {
				background-color: #17948c;

				* {
					color: #ffff;
				}
			}

			&__title {
				font-family: Poppins;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.03em;
				color: #17948c;
				margin-right: 5px;
			}

			&__duration {
				font-family: Poppins;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.03em;
				color: #828282;
			}
		}

		&__capsule--active {
			@extend .calendar__time__capsule;
			background-color: #17948c;

			* {
				color: #ffff;
			}
		}



		&__title {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.03em;
			color: #17948c;
		}

		&__titleBody {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.03em;
			color: #ffffff;
		}
	}
}