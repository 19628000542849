.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}


//display

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.d-grid {
  display: grid
}