.carouselBanner {
   width: 100%;
   z-index: 0;

   img {
      width: 100%;
      height: 534px;
      object-fit: cover;

      @media #{$phoneMQ} {
         height: 228px;
      }
   }

   svg {
      @media #{$tabletMQ} {
         display: none !important;
      }
   }
}

.slick-dots>li {
   width: 12px;
   height: 12px;
   background: #EEEEEE;
   border-radius: 100%;
}

.slick-dots {
   position: absolute;
   bottom: 6.5rem;

   @media #{$tabletMQ} {
      bottom: 1.5rem;
   }

   button {
      opacity: 0;
      padding: 14px !important;
   }
}

.venue-package__slider {
   .slick-dots {
      bottom: 6.5rem !important;

      @media #{$tabletMQ} {
         left: 50%;
         transform: translateX(-18%);
      }
   }
}

.slick-dots .slick-active {
   width: 12px;
   height: 12px;
   background: #333333;
   border-radius: 100%;
}

.slick-initialized {
   overflow: hidden;
}

.slick-prev,
.slick-next {
   z-index: 1;
   width: 57px;
   height: 57px;
}

.slick-prev {
   left: 2rem;
}

.slick-next {
   right: 2rem;
}

.fitMembership {
   .slick-slider {
      padding-bottom: 45px !important;

      >svg {
         width: 32px;
         height: 32px;
      }
   }

   .slick-dots {
      justify-content: center;
      bottom: 0.15rem !important;

      @include respondTo(tablet) {
         bottom: 1rem !important;
      }
   }

   .slick-prev {
      left: 4rem;
      transform: translateY(12rem);

      @include respondTo(tablet) {
         display: none !important;
      }
   }

   .slick-next {
      right: 4rem;
      transform: translateY(12rem);

      @include respondTo(tablet) {
         display: none !important;
      }
   }
}

.fitTrainer {
   .slick-initialized {
      padding: 0 8rem 20px;

      @include respondTo(tablet) {
         padding: 0 0 20px;
      }
   }

   .slick-dots {
      display: none !important;
   }

   .slick-next {
      right: 24px;
      top: 50%;
      transform: translateY(-100%);

      @include respondTo(tablet) {
         display: none !important;
      }
   }

   .slick-prev {
      left: 24px;
      top: 50%;
      transform: translateY(-100%);

      @include respondTo(tablet) {
         display: none !important;
      }
   }
}

.whats-new {

   @include respondTo(tablet) {
      .slick-dots {
         justify-content: center;
         transform: translateX(-1rem);
      }

      .slick-initialized {
         max-width: 100vw !important;
         padding: 0 1rem;
         height: 20rem;
      }

      .slick-prev,
      .slick-next {
         display: none !important;
      }
   }

   @include respondTo(desktop) {
      .slick-dots {
         transform: rotate(90deg) translate(-30%, -12rem) !important;
      }

      .slick-list {
         height: 490px !important;
      }

      .slick-slider {
         padding-bottom: 0 !important;
      }

   }
}

.detail-course {
   .slick-dots {
      bottom: 6.5rem !important;

      @include respondTo(tablet) {
         bottom: 3rem !important;
         width: 100%;
         left: 50%;
         transform: translateX(-5%);
      }
   }
}

.slick-slide {
   padding: 0;
}