.observer {
  &_animation {
    animation-name: animation_observer;
    animation-duration: 1s;
  }

  @keyframes animation_observer {
    0% { opacity: 0; transform: translateY(10%); }
    100% { opacity: 1; transform: translateY(0); }
  }
}