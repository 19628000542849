.popup-download {
  background-color: white;
  padding: 2rem 2rem;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  @include respondTo(tablet){
    width: 90%;
    padding: 1.75rem;
  }
  &__close {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    > svg {
      width: 1.75rem;
      height: 1.75rem;
      > path {
        fill: #a5a5a5;
      }
    }
  }
  .text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #114B5F;
    margin-bottom: 1rem;
    @include respondTo(tablet){
      font-size: 1.25rem;
    }
  }
  > .button-wrapper > a {
    display: inline-block;
    &:nth-child(1) {
      margin-right: 1rem;
    }
  }
}