%own-package-status__payment__subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #495050;

    @media #{$tabletMQ} {
        font-size: 14px;
        color: #000000;
    }
}

.own-package-status {
    display: flex;
    margin: auto;
    width: 100%;
    padding: 46px 0px;

    @media #{$tabletMQ} {
        flex-direction: column;
        padding: 0;
    }


    &--desktop-left-container {
        width: 65%;

        @media #{$tabletMQ} {
            width: 100%;

            >div:nth-child(2) {
                display: none;
            }
        }



    }

    &--desktop-right-container {
        margin-left: 25px;
        width: 35%;

        @media #{$tabletMQ} {
            width: 100%;
            margin-left: 0px;
            z-index: 2;
        }
    }

    &__banner {
        height: 229px;
        border-radius: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media #{$tabletMQ} {
            border-radius: 0;
        }

        &__title {
            font-weight: bold;
            font-size: 36px;
            line-height: 45px;
            color: #FFFFFF;
            text-align: center;
            z-index: 2;


            @media #{$tabletMQ} {
                font-size: 21px;
                line-height: 30px;
            }
        }

        &__detail {
            margin-top: 24px;
            z-index: 2;


            @media #{$tabletMQ} {}

            >span {
                background: #FFFFFF;
                border: 1px solid #828282;
                box-sizing: border-box;
                border-radius: 20px;
                padding: 8px 12px;

                font-size: 12px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.01em;
                color: #17948C;

                &:not(:first-child) {
                    margin-left: 11px;
                }
            }
        }
    }

    &__payment {
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 32px 46px;
        margin-top: 24px;

        @media #{$tabletMQ} {
            padding: 27px 16px;

        }

        &__title {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.03em;
            color: #000000;
            margin-bottom: 47px;

            @media #{$tabletMQ} {
                display: none;
            }

        }

        &__content-container {
            width: 100%;

            @media #{$tabletMQ} {
                tr {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 23px;
                }
            }

            >tr>td {
                padding-bottom: 36px;

                @media #{$tabletMQ} {
                    padding-bottom: 10px;

                }
            }

            >tr>td:first-child {
                text-align: left;
            }

            >tr>td:last-child {
                text-align: right;

                @media #{$tabletMQ} {
                    text-align: left;
                }
            }
        }


        &__id {
            >td:first-child {
                @extend %own-package-status__payment__subtitle;
            }

            >td:nth-child(2) {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #828282;

                @media #{$tabletMQ} {
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.01em;
                }
            }
        }

        &__status {
            >td:first-child {
                @extend %own-package-status__payment__subtitle;
            }

            >td:nth-child(2)>span {
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                letter-spacing: 0.03em;

                padding: 8px 12px;
                border-radius: 15px;

                @media #{$tabletMQ} {
                    font-size: 16px;
                    line-height: 24px;
                }

            }

        }

        &__method {
            >td:first-child {
                @extend %own-package-status__payment__subtitle;
            }

            >td:nth-child(2) {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #828282;

                @media #{$tabletMQ} {
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.01em;
                }

            }

        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding: 31px 23px;

        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        border-radius: 20px;

        @media #{$tabletMQ} {
            border-radius: 20px 20px 0 0;
            margin-top: -45px;
            padding: 31px 23px;
            padding: 65px 23px;
            box-shadow: unset;
        }

        &__title {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.03em;
            color: #333333;
            margin-bottom: 13px;
            display: flex;
            align-items: center;
            margin-right: 8px;

            >span {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.01em;
                color: #828282;
                margin-left: auto;
            }

        }

        &__user {
            background: #FFFFFF;
            padding: 18px;
            border: 1px solid #E0E0E0;
            box-sizing: border-box;
            border-radius: 10px;
            margin-bottom: 32px;

            td {
                padding-bottom: 12px;
                vertical-align: top;
            }

            tr>td:nth-child(1) {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #000000;
                padding-right: 12px;
                white-space: nowrap;
            }

            tr>td:nth-child(2) {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #828282;
                padding-right: 2px;
            }

            tr>td:nth-child(3) {
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #828282;
                word-break: break-all;
            }
        }

        &__package {
            background: #FFFFFF;
            border: 1px solid #E0E0E0;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 32px;

            >div:nth-child(1) {
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #052523;
                margin-bottom: 3px;
            }

            >div:nth-child(2) {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #828282;
                margin-bottom: 7px;
                cursor: pointer;
            }

            &__capsules {
                display: flex;
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #828282;

                >span {
                    border: 1px solid #828282;
                    box-sizing: border-box;
                    border-radius: 20px;
                    padding: 8px 12px;
                }

                >span:not(:first-child) {
                    margin-left: 12px;
                }
            }
        }

        &__quota {
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            padding: 16px 18px;
            background: rgba(29, 186, 175, 0.15);
            margin-top: 22px;
            min-height: 125px;


            >div:nth-child(1) {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #000000;
            }

            >div:nth-child(2) {
                font-size: 10px;
                line-height: 16px;
                display: flex;
                align-items: center;
                letter-spacing: 0.015em;
                color: #000000;
                opacity: 0.5;
                margin-bottom: 12px;
            }

            >button {
                background: #17948C;
                border-radius: 10px;
                margin-top: auto;

                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #FFFFFF;
                padding: 5px 16px;
                margin-left: auto;
            }
        }
    }

    &__info__payment--mobile-container {
        display: none;
        margin-top: 32px;


        @media #{$tabletMQ} {
            display: block;
        }

        >div:first-child {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.03em;
            color: #333333;
        }
    }

}


@media (min-width: 992px) {
    .own-package-status {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .own-package-status {
        max-width: 1140px;
    }
}