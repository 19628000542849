.program {
	position: relative;
	margin-top: -1rem;
	overflow: hidden !important;
	height: auto !important;

	a:hover{
		text-decoration: none;
	}
	
	a{
		text-decoration: none;
	}

	@media #{$tabletMQ} {
		margin-top: -0.5rem;
	}

	&__card {
		position: relative;
		width: 100%;
		z-index: 5;
		margin-top: -6rem;

		@media #{$tabletMQ} {
			margin-top: -1.5rem;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			position: relative;

			padding: 32px 113px;
			background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
			border-radius: 50px 50px 0 0;

			@media #{$tabletMQ} {
				padding: 22px 16px 0;
				border-radius: 20px 20px 0 0;
			}

			&__info {
				.programInfo {
					display: flex;
					align-items: center;
					margin-bottom: 18px;

					@media #{$tabletMQ} {
						flex-direction: column;
						align-items: flex-start;
						order: 2;
					}
				}

				.programName {
					font-style: normal;
					font-weight: 600;
					font-size: 36px;
					line-height: 54px;
					letter-spacing: 0.015em;
					color: #000000;

					@media #{$tabletMQ} {
						font-size: 21px;
						line-height: 24px;
						margin-bottom: 13px;
					}
				}

				.programRating {
					display: flex;
					align-items: center;

					> div {
						min-width: 10rem;

						@media #{$tabletMQ} {
							min-width: auto;
						}
					}

					> span {
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 21px;
						color: #17948c;
						margin-left: 14px;
						margin-right: 32px;
						min-width: 7rem;

						@media #{$tabletMQ} {
							margin-right: 0;
							font-size: 14px;
							line-height: 16px;
							min-width: auto;
						}
					}

					> img {
						@media #{$tabletMQ} {
							width: 24px;
							height: 24px;
						}
					}
				}

				.programMap {
					display: flex;
					align-items: center;

					span {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.01em;
						color: #828282;
						margin-left: 5px;
					}

					a {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 35rem;

						@media #{$tabletMQ} {
							max-width: 15rem;
						}
					}

					@media #{$tabletMQ} {
						margin-top: 16px;
					}
				}
			}

			&__action {
				display: flex;
				align-items: center;
				height: fit-content;

				.programShare,
				.programFavorite {
					display: flex;
					align-items: center;

					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 27px;
					text-align: center;
					letter-spacing: 0.03em;
					color: #17948c;

					@media #{$tabletMQ} {
						svg {
							width: 24px;
							height: 24px;
						}

						span {
							display: none;
						}
					}
				}

				.programShare {
					span {
						margin: 0 24px 0 12px;
					}

					@media #{$tabletMQ} {
						margin-right: 15px;
					}
				}

				.programFavorite {
					span {
						margin-left: 12px;
					}
				}
			}
		}

		&__body {
			display: flex;
			padding-bottom: 10rem;
			background: #ffffff;

			@media #{$tabletMQ} {
				flex-direction: column;
				padding-bottom: 0;
			}

			&__main {
				padding: 48px 65px 48px 113px;
				width: 50%;

				@media #{$tabletMQ} {
					padding: 23px 16px;
					width: 100%;
				}

				&.desktop {
					@media #{$tabletMQ} {
						display: none;
					}
				}

				.main {
					height: auto !important;

					.title {
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 19px;
						letter-spacing: 0.01em;
						color: #031615;
						margin-bottom: 16px;

						@media #{$tabletMQ} {
							font-size: 14px;
							margin-bottom: 8px;
						}
					}

					.desc {
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 17px;
						letter-spacing: 0.01em;
						color: #828282;

						@media #{$tabletMQ} {
							font-size: 12px;
						}
					}

					&.info {
						background: #bdf5f1;
						border-radius: 10px;
						padding: 27px 16px;
						display: flex;
						align-items: center;
						margin-bottom: 36px;

						.wrapper {
							display: flex;
							flex-direction: column;
							margin-left: 15px;

							> .title {
								font-style: normal;
								font-weight: bold;
								font-size: 12px;
								line-height: 17px;
								letter-spacing: 0.01em;
								color: #17948c;
								margin-bottom: 0;
							}

							> .desc {
								font-style: normal;
								font-weight: normal;
								font-size: 12px;
								line-height: 17px;
								letter-spacing: 0.01em;
								color: #17948c;
							}
						}
					}

					&.programinfo {
						margin-bottom: 38px;

						> div {
							display: flex;
							align-items: center;
							margin-bottom: 18px;

							> svg {
								margin-right: 12px;
							}

							> div {
								font-style: normal;
								font-weight: 500;
								font-size: 18px;
								line-height: 19px;
								display: flex;
								align-items: center;
								letter-spacing: 0.01em;
								color: #031615;

								@media #{$tabletMQ} {
									font-size: 14px;
								}
							}
						}

						> span {
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 21px;
							letter-spacing: 0.01em;
							color: #828282;
						}

						> ul {
							> li {
								font-style: normal;
								font-weight: normal;
								font-size: 14px;
								line-height: 21px;
								letter-spacing: 0.01em;
								color: #828282;
							}
						}
					}

					&.about,
					&.facility {
						margin-bottom: 32px;
					}

					&.facility {
						.desc {
							display: flex;

							.facility__card {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								margin-right: 20px;

								.image {
									border: 1px solid #e0e0e0;
									box-sizing: border-box;
									border-radius: 100%;
									padding: 13px;
									height: 70px;
									width: 70px;

									display: flex;
									align-items: center;
									justify-content: center;
								}

								.name {
									margin-top: 8px;
								}
							}
						}
					}

					&.review {
						margin-bottom: 26px;

						.reviewCont {
							display: flex;
							align-items: center;
							margin-bottom: 22px;

							img {
								border: 1px solid #ffffff;
								box-sizing: border-box;
								width: 32px;
								height: 32px;
								object-fit: cover;
								border-radius: 100%;
							}

							.reviewer {
								margin-left: 18px;
								margin-right: auto;

								&__name {
									font-style: normal;
									font-weight: normal;
									font-size: 14px;
									line-height: 17px;
									letter-spacing: 0.01em;
									color: #031615;

									@media #{$tabletMQ} {
										font-size: 12px;
									}
								}

								&__date {
									font-style: normal;
									font-weight: normal;
									font-size: 12px;
									line-height: 17px;
									letter-spacing: 0.01em;
									color: #828282;
								}
							}
						}

						.link {
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							line-height: 17px;
							letter-spacing: 0.01em;
							margin-top: 16px;
							color: #17948c;
						}
					}

					&.location {
						margin-bottom: 28px;

						.title {
							margin-bottom: 5px;
						}

						.desc {
							font-size: 12px;
						}
					}

					&.social {
						.social__wrapper {
							display: flex;
							align-items: center;
						}

						.social-media {
							display: flex;
							align-items: center;

							background: #ffffff;
							border: 1px solid #17948c;
							box-sizing: border-box;
							border-radius: 22px;
							cursor: pointer;

							padding: 8.5px 8px;
							margin-right: 14px;

							span {
								font-style: normal;
								font-weight: normal;
								font-size: 12px;
								line-height: 17px;
								letter-spacing: 0.01em;
								color: #17948c;
								margin-left: 4px;
							}
						}
					}
				}
			}

			&__second {
				background: #ffffff;
				box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
				border-radius: 20px;
				width: 50%;
				margin: 48px 130px 48px 113px;
				padding: 32px 36px;
				max-width: 34rem;

				@media #{$tabletMQ} {
					width: 100%;
					padding: 0;
					margin: 0;
					box-shadow: none;
					border-radius: 0;
					max-width: auto;
				}

				.tabs {
					display: flex;
					justify-content: center;
					position: relative;
					
					@media #{$tabletMQ} {
						padding: 0;
						margin: 0 16px;
						border-bottom: 1px solid rgba($color: #828282, $alpha: 0.2);

						> div {
							&:nth-child(2) {
								margin: 0 15px;
							}
						}
					}

					&__title {
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 14px;
						text-align: center;
						letter-spacing: 0.03em;
						color: #828282;
						padding: 13px 18px;
						cursor: pointer;

						display: flex;
						align-items: center;

						span {
							margin-left: 1px;
						}

						&.active {
							color: #17948c;

							@media #{$tabletMQ} {
								border-bottom: 3px solid #17948c;
							}
						}

						@media #{$tabletMQ} {
							padding: 0;
							padding-bottom: 12px;
							font-size: 14px;
						}
					}

					svg {
						width: 18px;
						height: 18px;
						margin-left: 5px;

						path {
							fill: #828282;
						}
					}
				}
			}
		}
	}
}

.programClass {
	&__filter {
		@include globalCustomScroll();
		padding-bottom: 12px;
		border-bottom: 1px solid #c4c4c4;
		display: flex;
		align-items: center;
		overflow-x: auto;
		justify-content: space-between;

		margin-top: 24px;

		> div {
			margin-right: 15px;
			white-space: nowrap;
		}

		@media #{$tabletMQ} {
			padding: 0 21px 10px;
		}

		.title {
			background: #ffffff;
			border: 1px solid #17948c;
			box-sizing: border-box;
			border-radius: 22px;
			padding: 8px 18px;
			cursor: pointer;

			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.01em;
			color: #17948c;

			&.active {
				background: #17948c;
				color: #ffffff;
			}
		}
	}

	&__dates {
		@include globalCustomScroll();
		display: flex;
		align-items: center;
		padding: 24px 0 10px;
		margin-bottom: 28px;
		overflow-x: auto;
		max-width: 30rem;

		@media #{$tabletMQ} {
			padding: 24px 16px 10px;
		}

		.title {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			letter-spacing: 0.0693333px;
			color: #000000;
			padding: 14px 8px 0;
			margin-right: 13px;
			cursor: pointer;

			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 3rem;
			}

			&.active {
				background: #e9fcfa;
				border: 1px solid #17948c;
				box-sizing: border-box;
				border-radius: 21px;
			}

			&.selected {
				background: #17948c;
				border-radius: 21px;
				color: #ffffff;
				// padding: 16px 18px 0;
			}

			div {
				&:nth-child(1) {
					margin-bottom: 20px;
				}

				&:nth-child(2) {
					margin-bottom: 17px;
				}
			}
		}
	}

	&__session {
		margin-top: 30px;

		@media #{$tabletMQ} {
			padding: 0 16px 10px;
			margin-top: 10px;
		}

		&__card {
			background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
			border-radius: 10px;
			padding: 18px 24px;
			margin-bottom: 24px;
			position: relative;
			display: flex;
			flex-direction: column;

			@media #{$tabletMQ} {
				margin-top: 29px;
			}

			&__tile {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.name {
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 19px;
					letter-spacing: 0.03em;
					color: #000000;
					margin-bottom: 18px;

					@media #{$tabletMQ} {
						font-size: 14px;
					}

					&.doolive {
						svg {
							position: absolute;
							top: 1rem;
							right: 2rem;
						}

						@media #{$tabletMQ} {
							> div {
								max-width: 15rem;
							}
						}
					}
				}

				.price {
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 14px;
					letter-spacing: 0.03em;
					color: #17948c;
					margin-bottom: 18px;

					@media #{$tabletMQ} {
						font-size: 14px;
					}
				}
			}

			&__detail {
				border-top: 1px solid #bdbdbd;
				padding-top: 18px;
				margin-top: 14px;
				display: flex;
				flex-direction: column;

				@media #{$tabletMQ} {
					border-top: none;
				}

				&__duration {
					display: flex;
					align-items: center;
					flex-direction: row;

					> div:nth-child(1) {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
						margin-right: 8px;
					}

					> div:nth-child(2) {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #798685;
					}
				}

				&__description {
					margin-top: 14px;
					> div:nth-child(1) {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
						margin-right: 8px;
					}

					> div:nth-child(2) {
						margin-top: 8px;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 21px;
						letter-spacing: 0.01em;
						color: #798685;
					}
				}

				&__included {
					margin-top: 14px;
					> div {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
						margin-right: 8px;
					}

					> span,
					ul {
						margin-top: 8px;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 21px;
						letter-spacing: 0.01em;
						color: #798685;
					}
				}

				&__button {
					margin-top: 77px;
					margin-bottom: 6px;
					display: flex;
					align-content: center;
					align-items: center;

					@media #{$tabletMQ} {
						align-self: center;
					}

					.button__primary {
						display: flex;
					}
				}
			}

			> svg {
				cursor: pointer;
			}

			button {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-end;
				height: fit-content;
				width: fit-content;
				padding: 5px 21px;
				margin-left: auto;
				margin-top: auto;

				@media #{$tabletMQ} {
					display: none;
				}
			}

			.time {
				display: flex;
				align-items: center;
				margin-bottom: 18px;

				span {
					&:nth-child(2) {
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 14px;
						letter-spacing: 0.03em;
						color: #2a2a2a;
						margin: 0 6px 0 13px;

						@media #{$tabletMQ} {
							margin: 0 6px 0 7px;
							font-size: 14px;
						}
					}

					&:nth-child(3) {
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 21px;
						letter-spacing: 0.01em;
						color: rgba($color: #2a2a2a, $alpha: 0.4);

						@media #{$tabletMQ} {
							font-size: 12px;
							line-height: 17px;
						}
					}
				}

				@media #{$tabletMQ} {
					> svg {
						margin-left: -6px;
					}
				}
			}

			.slot {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.03em;
				color: #eb5757;
			}
		}
	}
}

.noSession {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	@media #{$tabletMQ} {
		padding-bottom: 5rem;

		svg {
			width: 200px;
		}
	}

	.title {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 17px;
		text-align: center;
		letter-spacing: 0.03em;
		color: #052523;
		margin-bottom: 16px;
		margin-top: 59px;

		@media #{$tabletMQ} {
			margin-top: 0;
		}
	}

	.text {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		letter-spacing: 0.01em;
		color: #052523;
	}
}

.programPartner {
	box-sizing: border-box;
	border-radius: 20px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.03em;
	padding: 12px;
	margin-left: 23px;

	@media #{$tabletMQ} {
		margin-left: 0;
	}

	&.official {
		border: 1px solid #fb7315;
		color: #fff;
		background: #fb7315;
	}

	&.unofficial {
		border: 1px solid #eb5757;
		color: #eb5757;
		background: #fad1d1;
	}
}

.detail-program__session--loader {
	> span > span {
		border-radius: 10px;
		margin-bottom: 24px;
	}
}

.programProcess {
	margin-top: 38px;
	margin-bottom: 12px;

	.programProcess-body {
		padding: 21px 25px;
		width: 465px;
		background: #ffffff;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		margin-bottom: 12px;

		@media #{$tabletMQ} {
			width: 100%;
		}

		&__tile {
			display: flex;
			justify-content: space-between;
			align-items: center;

			> div {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 19px;
				display: flex;
				align-items: center;
				letter-spacing: 0.03em;
				color: #000000;

				@media #{$tabletMQ} {
					font-size: 12px;
				}
			}

			> svg {
				cursor: pointer;
			}
		}

		> p {
			margin-top: 19px;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 0.03em;
			color: #828282;

			@media #{$tabletMQ} {
				font-size: 12px;
			}
		}

		.content {
			margin-top: 19px;

			> span {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 19px;
				letter-spacing: 0.03em;
				color: #828282;
				@media #{$tabletMQ} {
					font-size: 12px;
				}
			}

			> ul {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 19px;
				letter-spacing: 0.03em;
				color: #828282;
				@media #{$tabletMQ} {
					font-size: 12px;
				}
			}
		}
	}
}
