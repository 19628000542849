.maps {
  position: relative;
  scroll-margin-top: 15rem;

  @include respondTo(tablet) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.maps__img {
  width: 100%;
  height: 735px;
  background: lightcyan;
}

.maps__container {
  position: absolute;
  background: #D0E9E8;
  padding: 43px 32px 43px 120px;
  max-width: 531px;
  height: 100%;
  top: 0;
  left: 0;

  @include respondTo(tablet) {
    padding: 33px 16px 4rem;
    position: relative;
  }
}

.maps--title {
  font-size: 30px;
  line-height: 48px;
  font-weight: bold;
  color: #0A423F;

  @include respondTo(tablet) {
    font-size: 18px;
    line-height: 27px;
  }
}

.maps__search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 11px 14px;
  margin: 14px 0 12px;

  >input {
    width: 100%;
    outline: none;
    box-shadow: none;
    font-size: 15px;
    line-height: 123.5%;

    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }
}


.maps__filter {
  display: flex;
  align-items: center;
}

.maps__filter-capsules {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 34px;
  padding: 4px 10px;
  font-size: 14px;
  line-height: 147.5%;
  letter-spacing: 0.02em;
  color: #000000;
  cursor: pointer;

  @include respondTo(tablet) {
    font-size: 13px;
  }

  &:not(:last-of-type) {
    margin-right: 8px;
  }
}

.maps__filter-capsules--active {
  background-color: #17948C;
  color: #FFFFFF;
}

.maps__list {
  margin-top: 12px;
  max-height: 30rem;
  padding: 12px 11px 0 0;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y proximity;

  @include respondTo(tablet) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0;
    padding: 1rem .5rem;
    max-height: none;
  }

  &::-webkit-scrollbar {
    width: 10px;
    /* width of the entire scrollbar */

    @include respondTo(tablet) {
      height: 10px;
    }
  }

  &::-webkit-scrollbar-track {
    background: #A2D4D1;
    /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #73BEBA;
    /* color of the scroll thumb */
    border-radius: 5px;
    /* roundness of the scroll thumb */
  }

  >button {
    font-size: 14px;
    line-height: 147.5%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    background: #17948C;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    padding: 10px 0;

    @include respondTo(tablet) {
      position: absolute;
      bottom: 10px;
      transform: translateX(-24px);
    }
  }
}

.maps-card {
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 12px 14px;
  margin-bottom: 12px;
  cursor: pointer;

  @include respondTo(tablet) {
    width: 20rem;
    margin-bottom: 0;
    margin-right: 12px;
  }

  img {
    width: 104px;
    height: 104px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.maps-card__content {
  margin-left: 18px;

  span:not(:last-of-type) {
    &::after {
      content: ', ';
    }
  }
}

.maps-card--title {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #333333;
}

.maps-card--grey {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: rgba($color: #333333, $alpha: .5);
}

.maps-card--rating {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #17948C;
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 15px;
    margin-right: 4px;
  }
}

.maps__loader {
  width: 100%;
  margin-bottom: 12px;

  span {
    height: 125px;
  }
}