.offer__header {
  display: grid;
  place-items: center;
  background: rgba($color: #17948C, $alpha: 0.6);
  padding: 66px 0 90px;
  position: relative;

  svg {
    position: absolute;
    left: 5rem;
    bottom: 0;

    @include respondTo(tablet) {
      left: 1rem;
      bottom: -2rem;
      z-index: 1;
      width: 35%;
    }
  }
}

.offer__content {
  position: relative;
  height: 100%;
  z-index: 1;
  margin-top: -3rem;
  padding: 59px 15rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 50px 50px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include respondTo(tablet) {
    padding: 22px 22px;
    border-radius: 20px 20px 0 0;
    box-shadow: none;
    z-index: 3;
  }
}

.offer__share {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.03em;
  color: #17948C;
  position: absolute;
  right: 10%;
  cursor: pointer;

  @include respondTo(tablet) {
    font-size: 12px;
    line-height: 18px;

    svg {
      width: 16px;
      margin-right: 5px;
    }
  }
}

.offer__cards {
  background: #F5F9F9;
  padding: 51px 10rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 36px;
  row-gap: 36px;

  @include respondTo(tablet) {
    padding: 24px 16px;
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
  }

  @include respondTo(phone) {
    grid-template-columns: 100%;
  }
}

.offer__card {
  width: 20rem;
  border-radius: 10px;
  overflow: hidden;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  cursor: pointer;

  @include respondTo(tablet) {
    width: 100%;
  }
}

.offer__card__header {
  position: relative;

  &::after {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 62.28%, rgba(0, 0, 0, 0.5) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  img {
    position: relative;
    width: 343px;
    height: 114px;
    object-fit: cover;
  }

  span {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    position: absolute;
    z-index: 20;
    bottom: 12px;
    left: 16px;
  }
}

.offer__card__content {
  background: #FFFFFF;
  padding: 10px 15px;

  >div {
    display: flex;
    align-items: center;
    margin-top: 1px;
  }

  svg {
    margin-right: 10px;
  }
}

.offer__empty {
  display: grid;
  place-items: center;
  padding: 8rem;

  @include respondTo(tablet) {
    padding: 0;
  }

  img {
    margin-bottom: 24px;
  }

  span {
    margin: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 147.5%;
    letter-spacing: 0.02em;
    color: #0A423F;
  }
}

.offer__text--card {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #323232;
  margin: 0;
}

.offer__text--date {
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #17948C;
  margin: 0;
}

.offer__text--header {
  font-weight: normal;
  font-size: 15px;
  line-height: 147.5%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #0A423F;
  margin: 0;

  @include respondTo(tablet) {
    font-size: 12px;
    text-align: start;
    padding-right: 7rem;
  }
}

.offer__text--title {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  color: #FFFFFF;

  @include respondTo(tablet) {
    font-size: 24px;
    line-height: 36px;
    z-index: 3;
  }
}

.offer-detail__content {
  display: flex;

  @include respondTo(tablet) {
    justify-content: space-between;
  }
}

.offer-detail__header {
  @include respondTo(tablet) {
    display: block;
    position: relative;
  }
}

.offer-detail__share {
  @include respondTo(tablet) {
    position: absolute;
    top: 1.8rem;
    right: 1rem;

    svg {
      width: 18px;
      height: 18px;
    }

    span {
      display: none;
    }
  }
}

.offer-detail__location {
  @include respondTo(tablet) {
    width: 100%;
    margin-left: 0 !important;
  }
}