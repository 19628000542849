@mixin detail-course__text--primary($color: null) {
	font-size: 14px;
	letter-spacing: 0.01em;
	font-weight: 500;
	line-height: 19px;

	@if $color {
		color: $color;
	}

	@else {
		color: #031615;
	}
}

@mixin detail-course__text--secondary {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #262825;
}

.detail-course {
	display: flex;
	flex-direction: column;
}

.detail-course__main {
	display: flex;
	flex-direction: column;
	z-index: 10;
	margin-top: -6rem;

	@include respondTo(tablet) {
		margin-top: -2rem;
	}

	&__header {
		display: flex;
		justify-content: space-between;

		padding: 51px 48px;
		background: #ffffff;
		box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
		border-radius: 50px 50px 0 0;

		@media #{$tabletMQ} {
			padding: 22px 16px;
			border-radius: 20px 20px 0 0;
			flex-direction: column;
			border-bottom: 1px solid #c4c4c4;
		}

		&__price {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media #{$desktopMQ} {
				display: none;
			}

			>div {
				>span:nth-child(1) {
					font-weight: 600;
					font-size: 14px;
					line-height: 14px;
					letter-spacing: 0.03em;
					color: #17948c;
				}

				>span:nth-child(2) {
					font-weight: 600;
					font-size: 14px;
					line-height: 14px;
					letter-spacing: 0.03em;
					color: #17948c;
				}
			}
		}

		&__info {
			.session-name {
				display: flex;
				align-items: center;
				font-weight: 600;
				font-size: 36px;
				line-height: 54px;
				letter-spacing: 0.015em;
				color: #000000;
				margin-bottom: 24px;

				@media #{$phoneMQ} {
					margin-bottom: 12px;
					flex-direction: column;
					align-items: flex-start;

					font-size: 21px;
					line-height: 24px;
				}

				>.doolive-sign {
					display: flex;
					align-items: center;
					margin-left: 21px;

					width: fit-content;
					height: fit-content;
					padding: 2px 31px 2px 11px;
					font-size: 13px;
					line-height: 24px;
					letter-spacing: 0.015em;
					color: #ffffff;
					background: #000000;
					border-radius: 37px;
					position: relative;

					@media #{$phoneMQ} {
						margin-left: unset;
						margin-top: 21px;
					}

					&:after {
						content: ' ';
						right: 11px;
						width: 10px;
						height: 10px;
						background: #eb5757;
						border: 2px solid #fad1d1;
						border-radius: 50%;
						position: absolute;
					}
				}
			}

			.session-info {
				display: flex;
				align-items: center;
				margin-bottom: 18px;
				cursor: pointer;

				@media #{$phoneMQ} {
					margin-top: 16px;
				}

				>.streaming {
					display: flex;
					align-items: center;

					>img {
						width: 19px;
						height: 13.28px;
					}

					h3 {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.01em;
						color: #828282;
						margin-left: 10px;
					}
				}

				>.venueMap {
					display: flex;
					align-items: center;
					align-self: center;
					cursor: pointer;

					span {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.01em;
						color: #828282;
					}
				}

				>.official-class {
					white-space: nowrap;
					padding: 12px;
					margin-left: 38px;
					background: #bdf5f1;
					border: 1px solid #17948c;
					box-sizing: border-box;
					border-radius: 20px;
					align-self: center;

					font-weight: 600;
					font-size: 12px;
					line-height: 12px;
					letter-spacing: 0.03em;
					color: #17948c;
				}

				>.unofficial-class {
					white-space: nowrap;
					padding: 12px;
					margin-left: 38px;
					background: #fad1d1;
					border: 1px solid #eb5757;
					box-sizing: border-box;
					border-radius: 20px;
					align-self: center;

					font-weight: 600;
					font-size: 12px;
					line-height: 12px;
					letter-spacing: 0.03em;
					color: #eb5757;
				}
			}
		}

		&__action {
			display: flex;
			align-items: center;
			height: fit-content;

			@media #{$tabletMQ} {
				display: none;
			}

			.venueShare,
			.venueFavorite {
				display: flex;
				align-items: center;

				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
				text-align: center;
				letter-spacing: 0.03em;
				color: #17948c;

				@media #{$tabletMQ} {
					svg {
						width: 24px;
						height: 24px;
					}

					span {
						display: none;
					}
				}
			}

			.venueShare {
				cursor: pointer;

				span {
					margin: 0 24px 0 12px;
				}

				@media #{$tabletMQ} {
					margin-right: 15px;
				}
			}

			.venueFavorite {
				span {
					margin-left: 12px;
				}
			}
		}

		&__filter {
			display: flex;
			justify-content: space-between;

			@media #{$desktopMQ} {
				display: none;
			}

			>div {
				width: 48.5%;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					width: 24px;
					height: 24px;
					margin-right: 4px;
				}
			}

			&__calendar {
				padding: 10px 12px;
				border: 1px solid #17948c;
				box-sizing: border-box;
				border-radius: 20px;
				background-color: white;

				svg {
					min-width: 24px;
					min-height: 24px;
				}

				>span {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			&__time {
				padding: 10px 12px;
				border: 1px solid #17948c;
				border-radius: 20px;
				background-color: white;

				svg {
					min-width: 24px;
					min-height: 24px;
				}

				>div {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
}

.detail-course__main__content {
	display: flex;

	@include respondTo(tablet) {
		flex-direction: column;
	}

	&__info {
		display: flex;
		flex-direction: column;
		padding: 48px;
		width: 50%;

		@media #{$tabletMQ} {
			width: 100%;
			padding: 35px 16px;
		}

		>div:not(:last-child) {
			margin-bottom: 25px;
		}

		>.alert {
			padding: 32px 16px;
			background: #bdf5f130;
			border-radius: 10px;
			display: flex;
			align-items: center;

			>img {
				width: 19px;
				height: 13.28px;
				margin-right: 15px;
			}

			svg {
				width: 24px;
				height: 24px;
				margin-right: 15px;
			}

			div {
				display: flex;
				flex-direction: column;

				>span:nth-child(1) {
					font-weight: bold;
					font-size: 12px;
					line-height: 17px;
					color: #17948c;
				}

				>span:nth-child(2) {
					font-size: 12px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #17948c;
				}
			}
		}

		>.price {
			@media #{$phoneMQ} {
				display: none;
			}

			>span:nth-child(1) {
				@include detail-course__text--primary();
			}

			>div {
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				letter-spacing: 0.03em;
				color: #052523;
				margin-top: 5px;
			}
		}

		>.about {
			text-align: justify;
		}

		>.about,
		>.note {
			>span:nth-child(1) {
				@include detail-course__text--primary;
				display: block;
				margin-bottom: 10px;
			}

			>span:nth-child(2) {
				@include detail-course__text--secondary;
			}
		}

		>.facilities {
			>span:nth-child(1) {
				@include detail-course__text--primary;
				display: block;
				margin-bottom: 10px;
			}

			.facilities-list {
				display: flex;
				overflow-x: auto;

				>div {
					display: flex;
					flex-direction: column;
					align-items: center;

					&:not(:last-child) {
						margin-right: 18px;
					}

					>div {
						border: 1px solid #e0e0e0;
						border-radius: 50%;
						padding: 18px;
						height: 70px;
						width: 70px;
						display: flex;
						justify-content: center;
						align-items: center;

						>img {
							width: 39px;
							height: 39px;
							object-fit: cover;
						}
					}

					>span {
						@include detail-course__text--secondary;
						margin-top: 8px;
					}
				}
			}
		}

		>.how-book {
			>span:nth-child(1) {
				@include detail-course__text--primary;
				display: block;
				margin-bottom: 10px;
			}

			>.how-book__steps {
				display: flex;
				flex-direction: column;
			}

			.how-book__steps__name {
				display: flex;
				flex-direction: column;
				justify-content: center;
				position: relative;
				width: 100%;

				>hr {
					width: 100%;
					align-self: center;
					position: absolute;
				}
			}

			.how-book__steps__name__card {
				display: flex;
				justify-content: space-between;

				>span {
					@include detail-course__text--secondary;
					z-index: 1;
					background-color: white;
					width: 30%;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					flex-wrap: wrap;

					border: 1px solid #bdbdbd;
					box-sizing: border-box;
					border-radius: 19px;
					padding: 8px 21px;
					// height: 62px;
				}
			}

			.how-book__steps__desc {
				display: flex;
				justify-content: space-between;

				>span {
					@include detail-course__text--secondary;
					width: 30%;
					margin-top: 10px;
					text-align: center;
					font-weight: 500;
					font-size: 10px;
					line-height: 15px;
					letter-spacing: 0.01em;
					color: #828282;
				}
			}
		}
	}

	&__button--mobile-download {
		display: none;

		@media only screen and (max-width: 768px) {
			display: block;
		}
	}

	&__filter {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #e2ecec;
		width: 50%;
		padding: 7rem 0;

		// padding: 35px 16px;

		@media #{$tabletMQ} {
			&:nth-child(2) {
				display: none;
			}
		}
	}

	&__event {
		display: flex;
		width: 50%;
		justify-content: center;
		background: #E2ECEC;
		padding-top: 87px;

		@include respondTo(tablet) {
			order: -1;
			padding: 87px 0;
			width: 100%;
		}

		&__card {
			width: clamp(300px, 100%, 343px);
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
			background: #FFFFFF;
			border-radius: 10px;
			height: fit-content;

			display: flex;
			flex-direction: column;
			padding: 20px 29px;

			&__content {
				display: flex;
				justify-content: space-between;

				>div:nth-child(1) {
					display: flex;
					flex-direction: column;
					margin-right: 21px;

					>span {
						font-size: 18px;
						line-height: 25px;
						letter-spacing: 0.01em;
						color: #000000;
					}
				}

				>div:nth-child(2) {
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					img {
						width: 32px;
						height: 32px;
						// margin-right: ;
					}

					>div {
						display: flex;
						align-items: center;
						margin-top: 16px;

						>span {
							font-weight: 600;
							font-size: 18px;
							line-height: 14px;
							letter-spacing: 0.03em;
						}
					}

					>span {
						font-size: 14px;
						line-height: 21px;
						letter-spacing: 0.01em;
						color: #2A2A2A;
						opacity: 0.4;
					}
				}

			}

			>button {
				border-radius: 15px;
				margin-top: 33px;

				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.03em;
			}
		}
	}

	&__filter--mobile {}
}

.detail-course--error-message {
	color: #eb5757;
	font-size: 10px;
	margin-top: 5px;
}