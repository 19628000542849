.own-package-payment {

    &__banner {
        height: 229px;
        border-radius: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 25px;

        @media #{$tabletMQ} {
            border-radius: 0;
            margin-bottom: 0;
        }

        &__title {
            font-weight: bold;
            font-size: 36px;
            line-height: 45px;
            color: #FFFFFF;
            text-align: center;
            z-index: 2;


            @media #{$tabletMQ} {
                font-size: 21px;
                line-height: 30px;
            }
        }

        &__detail {
            margin-top: 24px;
            z-index: 2;


            @media #{$tabletMQ} {}

            >span {
                background: #FFFFFF;
                border: 1px solid #828282;
                box-sizing: border-box;
                border-radius: 20px;
                padding: 8px 12px;

                font-size: 12px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.01em;
                color: #17948C;

                &:not(:first-child) {
                    margin-left: 11px;
                }
            }
        }
    }

    &__info__quota {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        padding: 16px 18px;
        background: rgba(29, 186, 175, 0.15);
        margin-top: 22px;
        height: fit-content;


        >div:nth-child(1) {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #000000;
        }

        >div:nth-child(2) {
            font-size: 10px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: 0.015em;
            color: #000000;
            opacity: 0.5;
            margin-bottom: 12px;
        }

        >button {
            background: #17948C;
            border-radius: 10px;
            margin-top: auto;

            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.03em;
            color: #FFFFFF;
            padding: 5px 16px;
            margin-left: auto;
        }
    }

    &__info__check-status {
        background-color: #17948C;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
        border-radius: 10px;
        padding: 11px;
        text-align: center;
        width: 343px;
        margin-top: 32px;
        display: block;
        margin-left: auto;

        @media #{$phoneMQ} {
            width: 100%;
        }

    }


}