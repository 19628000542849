.stories {
  display: grid;
  justify-content: center;
  padding: 32px 0 10rem;
  position: relative;
  overflow: hidden;

  @include respondTo(tablet) {
    padding: 0;
    padding-right: 18px;
  }

  &::after {
    content: url(../../Assets/background/stories.png);
    position: absolute;
    bottom: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-65%, 80%);


    @include respondTo(tablet) {
      display: none;
    }
  }
}

.stories__header {

  @include respondTo(tablet) {
    padding: 32px 16px 0;
  }
}

.stories__cards {
  margin-top: 34px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;

  @include respondTo(tablet) {
    overflow-x: auto;
    padding: 2rem 1rem;
    justify-content: flex-start;
    margin-top: 0;
  }
}

.stories__card {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 16px;
  max-width: 316px;
  position: relative;
  display: flex;
  flex-direction: column;

  @include respondTo(tablet) {
    padding: 14px;
  }

  &:nth-child(1) {
    &::after {
      content: url(../../Assets/icon/quote-large.png);
      position: absolute;
      bottom: 15rem;
      left: -5.5rem;
      z-index: 1;

      @include respondTo(tablet) {
        display: none;
      }
    }
  }

  &:nth-child(2) {
    margin: 0 18px;
  }

  > .image > img {
    width: 281.28px;
    height: 140px;
    object-fit: none;
    object-position: 50% 20%;
    border-radius: 8px;
    margin-bottom: 19px;

    @include respondTo(tablet) {
      width: 270px;
    }
  }
}

.stories__card-user {
  display: block;
  align-items: center;
  justify-content: flex-start;
  margin-top: auto;
}

.stories--title {
  font-weight: bold;
  font-size: 30px;
  line-height: 50px;
  color: #19291A;

  @include respondTo(tablet) {
    font-size: 18px;
  }
}

.stories--info {
  font-size: 13px;
  line-height: 20px;
  color: #031615;

  @include respondTo(tablet) {
    font-size: 12px;
  }
}

.stories--card-title {
  font-weight: bold;
  font-size: 15px;
  line-height: 28px;
  color: #18191F;
}

.stories--card-info {
  font-size: 13px;
  line-height: 28px;
  color: #18191F;
}

.stories--user-title {
  font-weight: bold;
  font-size: 15px;
  line-height: 28px;
  color: #18191F;
}

.stories--user-job {
  font-size: 13px;
  line-height: 20px;
  color: #828282;
}