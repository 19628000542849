.merchant-banner {
    background-image: url('../../Assets/merchant/banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;

    height: 800px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respondTo(tablet) {
        background-image: url('../../Assets/merchant/banner-mobile.png');
        background-position: top right;
    }

    &__desc {
        max-width: 1140px;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        padding: 0 15px;

        >span {
            max-width: 587px;

            @include respondTo(tablet) {
                max-width: 49vw;
                text-align: left;
            }

            @include respondTo (small--phone) {
                max-width: 46vw;
            }   
    
            @include respondTo (semismall--phone) {
                max-width: 42vw;
            }   
        }

        >span:nth-child(1) {
            font-weight: 600;
            font-size: 51px;
            line-height: 59px;
            letter-spacing: 0.272px;
            color: #0A0A0A;
            margin-bottom: 15px;

            @include respondTo(tablet) {
                font-size: 24px;
                line-height: 29px;
                margin-bottom: 15px;
            }

        }

        >span:nth-child(2) {
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 23px;
            letter-spacing: 0.39px;
            color: #222222;
            margin-bottom: 24px;

            @include respondTo(tablet) {
                font-size: 16px;
                line-height: 21px;
            }
        }
    }

    &__btn-join {
        cursor: pointer;
        background-image: linear-gradient(270deg, #1F7899 7.91%, #66D67B 92.09%);
        border: none;
        width: fit-content;
        padding: 22px 30px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.08px;
        color: #FFFFFF;

        @include respondTo(tablet) {
            padding: 18px 20px;
            width: 170px;
        }

        @include respondTo (small--phone) {
            padding: 16px 18px;
            width: 158px;
        }   

        @include respondTo (semismall--phone) {
            padding: 16px 18px;
            width: 150px;
        }   
    }
}


.merchnat-benefit {
    width: 60%;
    padding: 100px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;

    @include respondTo(tablet) {
        width: 100%;
    }

    &__item {
        width: 49%;
        padding: 40px 30px;
        text-align: center;

        @include respondTo(tablet) {
            width: 100%;
            border-left: none;
            border-bottom: 1px solid #e1e1e1;

        }

        >p {
            font-size: 15px;
        }

        h3 {
            font-size: 28px;
            text-align: center;
            margin: 15px 0;
        }

        &:nth-child(1) {
            border-bottom: 1px solid #e1e1e1;
        }

        &:nth-child(2) {
            border-bottom: 1px solid #e1e1e1;
        }

        &:nth-child(2n) {
            border-left: 1px solid #e1e1e1;
        }
    }

    &__item__icon {
        height: 110px;

        img {
            max-height: 100%;
        }
    }
}

.merchant-statistic {
    display: flex;
    justify-content: space-between;
    max-width: 1140px;
    padding-right: 55px;
    padding-left: 55px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 99px;
    margin-bottom: 160px;

    @include respondTo(tablet) {
        padding: 0px 35px;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        margin-bottom: 199px;
    }

    >img {
        width: 318px;
        height: auto;
        align-self: flex-start;

        @include respondTo(tablet) {
            width: 250px;
            align-self: center;
        }
    }

    &__wraper {
        display: flex;
        flex-direction: column;

        @include respondTo(tablet) {
            align-items: center;
        }

        >span {
            max-width: 472px;
            font-size: 24px;
            line-height: 31px;
            letter-spacing: 0.614118px;
            color: #040404;
            margin-bottom: 40px;

            @include respondTo(tablet) {
                text-align: center;
                font-size: 18px;
                line-height: 23px;
                margin: 15px 0;
            }
        }

        >button {
            margin-top: 40px;
            cursor: pointer;
            background-image: linear-gradient(270deg, #1F7899 7.91%, #66D67B 92.09%);
            border: none;
            width: fit-content;
            padding: 22px 30px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.08px;
            color: #FFFFFF;
        }
    }

    &__number {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
        grid-column-gap: 20px;
        grid-row-gap: 40px;

        @include respondTo(tablet) {
            grid-gap: 10px;
        }

        >div {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
                font-weight: 600;
                font-size: 53px;
                line-height: 45px;
                letter-spacing: 0.904118px;
                margin-bottom: 29px;

                @include respondTo(tablet) {
                    font-size: 25px;
                    line-height: 30px;
                }
            }

            >span:nth-child(2) {
                font-size: 25px;
                line-height: 30px;
                letter-spacing: 0.42647px;
                color: rgba(0, 0, 0, 0.7);

                @include respondTo(tablet) {
                    font-size: 21px;
                    line-height: 26px;
                    margin-top: 8px;
                }
            }
        }

        >div:nth-child(1)>span:nth-child(1) {
            color: #62D17D;

        }

        >div:nth-child(2)>span:nth-child(1) {
            color: #2C7463;
        }

        >div:nth-child(3)>span:nth-child(1) {
            color: #1F7898;
        }

    }
}

.merchant-howto {
    position: relative;

    &__bgc-hexa {
        position: absolute;
        height: 245px;
        right: 72px;
        top: -150px;
        z-index: -1;

        @include respondTo(tablet) {
            width: 100px;
            height: auto;
            top: -125px;
        }
    }

    &__bgc-hexa--half {
        position: absolute;
        height: 470px;
        right: 0;
        top: -250px;
        z-index: -1;

        @include respondTo(tablet) {
            width: 100px;
            height: auto;
            top: -220px;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-content: center;
        margin-bottom: 100px;
    }

    &__title {
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 50px;

        @include respondTo(tablet) {
            text-align: center;
        }
    }

    &__contents-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respondTo(phone) {
            flex-direction: column;
            justify-content: center;
        }

        >div:nth-child(2n) {
            margin: 0 20px;

            @include respondTo(phone) {
                display: none;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        max-width: 192px;

        @include respondTo(phone) {
            margin-bottom: 21px;
            max-width: unset;
        }

        >span {
            text-align: center;
            font-size: 12px;
            color: #222;
        }

        >img {
            align-self: center;
            height: 38px;
            margin-bottom: 12px;

            @include respondTo(phone) {
                height: 51px;
            }
        }
    }

    &__desc {
        text-align: center;
        margin-top: 30px;
        font-size: 15px;
        color: #222;
    }
}

.merchant-testi {
    margin-bottom: 100px;


    &__title {
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 21px;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respondTo(phone) {
            flex-direction: column;
        }

        &__desc {
            display: flex;
            flex-direction: column;
            color: #222;
            width: 25%;

            @include respondTo(tablet) {
                width: 100%;
            }

            >span:nth-child(1) {
                font-size: 12px;
                line-height: 21px;
            }

            >span:nth-child(2) {
                font-weight: 700;
                font-size: 14px;
                line-height: 23px;
                margin-top: 18px;
            }

            >span:nth-child(3) {
                cursor: pointer;
                font-size: 12px;
                line-height: 21px;
                color: #67d77d;
                font-weight: 600;

                &:hover {
                    color: #555;
                    text-decoration: underline;
                }
            }

        }

        &__img {
            position: relative;
            width: 65%;
            margin-left: 10%;
            min-height: 490px;

            @include respondTo(tablet) {
                min-height: 301px;
            }

            @include respondTo(phone) {
                display: none;
            }

            >img {
                position: absolute;
            }

            >img:nth-child(1) {
                width: 600px;
                left: 0;

                @include respondTo(tablet) {
                    width: 300px;
                }
            }

            >img:nth-child(2) {
                width: 200px;
                top: 0;
                left: 212px;

                @include respondTo(tablet) {
                    width: 100px;
                    left: 87px;
                }
            }

            >img:nth-child(3) {
                width: 400px;
                top: 180px;
                left: 0;
                cursor: pointer;

                @include respondTo(tablet) {
                    width: 200px;
                    top: 100px;
                }
            }
        }
    }


}


.merchant-faq {
    margin-bottom: 50px;

    &__title {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
        margin-bottom: 50px;
    }
}

.merchant-footer {
    background: url('../../Assets/merchant/footer.png') 0% 0% / cover;
    background-position: left bottom;
    padding: 110px 0px 90px;
    min-height: 550px;

    @include respondTo(tablet) {
        background-position: right bottom;
    }


    &__container {
        margin-left: 34%;
        display: flex;
        flex-direction: column;

        @include respondTo(tablet) {
            margin-left: unset;
        }
    }


    &__desc {
        font-size: 41px;
        line-height: 50px;
        color: #FFFFFF;
        margin-bottom: 40px;

        >span:nth-of-type(1) {
            font-weight: 800;
        }

        >span:nth-of-type(2) {
            font-weight: 600;
        }
    }

    &__cta {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        font-weight: 600;
        color: #FFFFFF;

        span {
            margin-bottom: 8px;
        }

        div {
            display: flex;

            @include respondTo(phone) {
                flex-direction: column;
                align-items: flex-start;

            }

            img:not(:last-child) {
                margin-right: 8px;

                @include respondTo(phone) {
                    margin-left: unset;
                    margin-bottom: 8px;

                }
            }
        }
    }
}