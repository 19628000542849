.pay__content {
   span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #000000;

      &:first-of-type {
         margin-right: 0.3rem;
      }
   }
}