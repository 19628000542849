@mixin label-status__text {
    white-space: nowrap;
}

.start--unknown {
    @include label-status__text;
    background: #0E5854;
    color: #ffffff;
}

.status--reject {
    @include label-status__text;
    background-color: #A30015;
    color: #ffffff;
}

.status--cancel {
    @include label-status__text;
    background-color: #8F2D56;
    color: #ffffff;
}

.status--pending {
    @include label-status__text;
    background-color: #E0CA3C;
    color: #ffffff;
}

.status--confirmed {
    @include label-status__text;
    background-color: #335C67;
    color: #ffffff;
}

.status--active {
    @include label-status__text;
    //alter for : finished || check-in
    background-color: #D5F6E3;
    color: #27AE60;
    ;
}

.status--expired {
    @include label-status__text;
    background: #495050;
    color: #ffffff;
}