.productSlider {
  .slick-slider {
    overflow: visible ;
    .slick-list {
      padding: 3px;
      .slick-track {
        display: flex;
        align-items: stretch;
        .slick-slide {
          align-items: stretch;
          display: flex;
          height: unset;
        }
      }
    }
    .slick-dots {
      display: none !important;
    }
    .slick-prev {
      display: none !important;
    }
    .slick-next {
      position: absolute;
      top: 50%;
      left: calc(100% + 20px);
    }
  }
}

.executiveSlider {
  position: relative;
  z-index: 1;
  .slick-slider {
    overflow: visible ;
    .slick-list {
      padding: 8px;
      @include respondTo(tablet) {
        padding: 0;
      }
      .slick-track {
        display: flex;
        align-items: stretch;
        .slick-slide {
          align-items: stretch;
          display: flex;
          height: unset;
        }
      }
    }
    .slick-dots {
      bottom: -2rem;
      li {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        transition: .3s ease;
      }
      .slick-active {
        width: 16px;
        border-radius: 100px;
      }
    }
    .slick-prev, .slick-next {
      display: none !important;
    }
  }
}

.filterTeamSlider {
  display: flex !important;
  align-items: center;
  justify-content: center;
  .slick-slide {
    margin-right: 0.5rem;
  }
  .slick-dots {
    display: none !important;
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    background-color: white;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: static;
    transform: translate(0, 0);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    &::before {
      display: none !important;
    }
  }
  .slick-list {
    overflow: hidden;
    flex: 1
  }
}