.canceledBooking {
	@media #{$phoneMQ} {
		display: none;
	}
}

.detailBookingCard {
	&__guest {
		margin-bottom: 32px;
		@media #{$phoneMQ} {
			margin-bottom: 16px;
		}
	}
	&__guest,
	&__class {
		> .title {
			display: block;
			margin-bottom: 12px;
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			letter-spacing: 0.03em;
			color: #333333;
		}

		> .card {
			background: #ffffff;
			border: 1px solid #e0e0e0;
			border-radius: 10px;
			padding: 18px;
		}
	}

	&__guest > .card {
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.01em;

		> table {
			width: 100%;

			td {
				vertical-align: top;
			}
		}

		tr:not(:last-child) > td {
			padding-bottom: 12px;
		}

		tr > td:nth-child(1),
		tr > td:nth-child(2) {
			color: #000000;
		}

		tr > td:nth-child(3) {
			color: #828282;
			padding-left: 20px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 10rem;
		}
	}

	&__class__info {
		display: flex;
		align-items: center;

		&__icon {
			width: 62px;
			height: 62px;
			background: #e9fcfa;
			border-radius: 50%;
			display: flex;
			margin-right: 31px;

			> img {
				width: 47px;
				height: 47px;
				margin: auto;
			}
		}

		&__desc {
			> div:not(:last-child) {
				margin-bottom: 12px;
			}

			> div:nth-child(1) {
				font-weight: 600;
				font-size: 14px;
				line-height: 19px;
				letter-spacing: 0.03em;
				color: #000000;
			}

			> div:nth-child(2) {
				font-weight: 500;
				font-size: 14px;
				line-height: 19px;
				letter-spacing: 0.03em;
				color: #828282;
			}

			> div:nth-child(3) {
				display: flex;
				align-items: center;

				svg {
					margin-right: 12px;
				}

				> span:nth-of-type(1) {
					font-weight: 600;
					font-size: 14px;
					line-height: 14px;
					letter-spacing: 0.03em;
					color: #2a2a2a;
				}

				> span:nth-of-type(2) {
					font-size: 12px;
					line-height: 18px;
					letter-spacing: 0.01em;
					color: #2a2a2a;
					opacity: 0.4;
					margin-left: 6px;
				}
			}

			> div:nth-child(4) {
				display: flex;
				align-items: center;

				svg {
					margin-right: 12px;
				}

				font-weight: 600;
				font-size: 14px;
				line-height: 14px;
				letter-spacing: 0.03em;
				color: #2a2a2a;
			}
		}
	}
}
