.paymentInfo--success {
	background: #ffffff;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	padding: 32px 48px;

	&__label {
		width: fit-content;
		background: #d5f6e3;
		border-radius: 15px;
		padding: 8px 16px;

		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.03em;
		color: #27ae60;
	}

	@media #{$phoneMQ} {
		box-shadow: unset;
		background: #ffffff;
		padding: unset;
		margin-top: 16px;
	}

	>div:nth-child(1) {
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		display: flex;
		align-items: center;
		letter-spacing: 0.03em;
		color: #000000;
		margin-bottom: 47px;

		@media #{$phoneMQ} {
			display: block;
			margin-bottom: 12px;
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			letter-spacing: 0.03em;
			color: #333333;
		}
	}

	>hr {
		border: 1px solid #afb6b6;
	}

	&__card {
		@media #{$phoneMQ} {
			border: 1px solid #e0e0e0;
			border-radius: 10px;
		}

		&__detail-price-toggle {
			display: flex;
			align-items: center;

			svg {
				margin-left: 18px;
			}
		}

		&__detail {
			padding: 18px;

			>div:not(:last-child) {
				margin-bottom: 20px;
			}

			>div:nth-child(2),
			>div:nth-child(3) {
				display: flex;
				justify-content: space-between;
			}

			.primary-text {
				font-weight: 600;
				font-size: 14px;
				line-height: 19px;
				letter-spacing: 0.03em;
				color: #000000;
			}

			.secondary-text {
				font-size: 18px;
				line-height: 24px;
				letter-spacing: 0.03em;
				color: #828282;
			}

			.meta-text {
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.03em;
				color: #828282;
			}
		}
	}

	table {
		width: 100%;
		padding: 18px;

		>tr {
			@media #{$phoneMQ} {
				display: flex;
				flex-direction: column;
				padding: 18px;
			}

			&:not(:last-child)>td {
				padding-bottom: 45px;

				@media #{$phoneMQ} {
					padding-bottom: unset;
				}
			}
		}

		tr>td {
			vertical-align: top;

			&:nth-child(1) {
				font-weight: 500;
				font-size: 18px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #495050;
				white-space: nowrap;
				padding-right: 18px;

				@media #{$phoneMQ} {
					margin-bottom: 10px;
					font-size: 14px;
				}
			}

			&:nth-child(2) {
				font-size: 18px;
				line-height: 24px;
				letter-spacing: 0.03em;
				color: #828282;
				text-align: right;

				@media #{$phoneMQ} {
					text-align: left;
					margin-bottom: 23px;
					font-size: 14px;
				}
			}
		}
	}
}

.paymentInfo--pending {
	display: flex;
	flex-direction: column;

	&__countdown {
		display: flex;
		flex-direction: column;
		margin-top: 32px;

		@media #{$phoneMQ} {
			background: #fad1d1;
			border-radius: 10px;
			align-items: center;
			padding: 16px;
			margin-top: 24px;
		}

		>p {
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 17px;
			text-align: center;
			letter-spacing: 0.01em;
			color: #031615;

			@media #{$phoneMQ} {
				font-size: 12px;
			}
		}

		&__time {
			@media #{$phoneMQ} {
				margin-top: 16px;
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			>table {
				font-style: normal;
				font-weight: 600;
				line-height: 24px;
				text-align: center;
				letter-spacing: 0.015em;
				color: #eb5757;
				font-size: 24px;

				@media #{$phoneMQ} {
					font-size: 21px;
					margin-bottom: 39px;
				}

				>tr:nth-child(2) {
					display: none;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 14px;
					text-align: center;
					letter-spacing: 0.03em;

					color: #eb5757;

					@media #{$phoneMQ} {
						display: contents;
					}
				}
			}

			>button {
				background: rgba(250, 63, 63, 0.15);
				border: 1px solid #eb5757;
				box-sizing: border-box;
				border-radius: 7px;
				padding: 6px 24px;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 124%;
				text-align: center;
				letter-spacing: 0.03em;
				color: #eb5757;
			}
		}
	}

	&__amount {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 32px;

		@media #{$phoneMQ} {
			order: -1;
		}

		>span:nth-child(1) {
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 17px;
			letter-spacing: 0.01em;
			color: #495050;

			@media #{$phoneMQ} {
				font-size: 14px;
				color: #031615;
			}
		}

		>span:nth-child(2) {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 17px;
			letter-spacing: 0.01em;
			color: #17948c;

			@media #{$phoneMQ} {
				font-size: 14px;
			}
		}
	}

	&__transferTo {
		margin-top: 32px;

		>span {
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 19px;
			letter-spacing: 0.01em;
			color: #031615;

			@media #{$phoneMQ} {
				font-size: 14px;
			}
		}

		&__bank {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: rgba(29, 186, 175, 0.15);
			border-radius: 10px;
			padding: 10px 16px;
			margin-top: 12px;

			>div {
				display: flex;
				flex-direction: column;

				>span {
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #031615;

					@media #{$phoneMQ} {
						font-weight: normal;
					}
				}
			}

			>button {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 124%;
				letter-spacing: 0.03em;
				color: #1dbaaf;
				background: rgba(29, 186, 175, 0.16);
				border: 1px solid #1dbaaf;
				box-sizing: border-box;
				border-radius: 7px;
				padding: 6px 11px;
			}
		}
	}

	&__howToPay {
		padding: 40px 48px;
		background: #f7f8f8;
		margin-top: 32px;
		border-radius: 10px;

		@media #{$phoneMQ} {
			padding: 34px 16px;
			margin-top: 16px;
		}

		&__title {
			display: flex;
			justify-content: space-between;
			align-items: center;

			>span {
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 14px;
				letter-spacing: 0.03em;
				color: #333333;

				@media #{$phoneMQ} {
					font-weight: 600;
					font-size: 14px;
				}
			}
		}

		&__step {
			background-color: #fff;
			padding: 19px 44px;
			border-radius: 10px;
			margin-top: 27px;

			@media #{$phoneMQ} {
				padding: 19px 16px;
			}

			&__one {
				margin-bottom: 29px;

				>span {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 19px;
					display: flex;
					align-items: center;
					letter-spacing: 0.01em;
					color: #000000;
				}

				>table {
					margin-top: 10px;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					align-items: center;
					letter-spacing: 0.01em;
					color: #000000;
				}
			}

			&__two {
				margin-bottom: 23px;

				>span {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 19px;
					display: flex;
					align-items: center;
					letter-spacing: 0.01em;
					color: #000000;
				}

				>table {
					margin-top: 10px;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					align-items: center;
					letter-spacing: 0.01em;
					color: #000000;
				}
			}

			&__button {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				display: flex;
				align-items: center;
				letter-spacing: 0.03em;
				color: #17948c;
				cursor: pointer;
			}
		}
	}

	&__qrCode {
		display: flex;
		flex-direction: column;
		margin-top: 24px;
		align-items: center;

		>span {
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 12px;
			text-align: center;
			letter-spacing: 0.03em;
			color: #333333;
			padding-left: 40px;
			padding-right: 40px;

			@media #{$phoneMQ} {
				padding-left: 64px;
				padding-right: 64px;
			}
		}

		>img {
			margin-bottom: 38px;
			width: 279px;
		}
	}

	&__info {
		text-align: center;
		margin-top: 20px;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 0.03em;
		color: #333333;
		padding: 0 32px;

		@media #{$phoneMQ} {
			display: none;
		}
	}
}