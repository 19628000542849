.programSuccess {
	display: flex;
	justify-content: space-between;
	padding: 46px 112px;
	max-width: 100%;
	margin-bottom: 60px;

	@media #{$tabletMQ} {
		padding: 0;
		margin-bottom: 0;
		flex-direction: column;
		justify-content: flex-start;
	}

	&__left {
		width: 60%;

		@media #{$tabletMQ} {
			width: 100%;
		}

		&__banner {
			background-color: #fb7315;
			border-radius: 20px;
			height: 229px;
			margin-bottom: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			@media #{$tabletMQ} {
				border-radius: 0;
				height: 229px;
				margin-bottom: 0;
				position: absolute;
				left: 0;
				right: 0;
				top: 65px;
				z-index: -2;
				align-items: flex-start;
				padding-top: 29px;
			}

			> img {
				position: absolute;
				top: 200px;
				left: 345px;

				@media #{$tabletMQ} {
					top: 38px;
					left: 82px;
				}
			}

			> h2 {
				position: absolute;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #000000;
				top: 260px;
				left: 400px;

				@media #{$tabletMQ} {
					top: 105px;
					left: 135px;
				}
			}

			> table {
				position: absolute;
				top: 200px;
				left: 400px;

				@media #{$tabletMQ} {
					top: 38px;
					left: 135px;
				}

				> tr {
					> td:nth-child(1) {
						font-style: normal;
						font-weight: normal;
						font-size: 8px;
						letter-spacing: 0.01em;
						color: #000000;
					}
					> td:nth-child(2) {
						font-style: normal;
						font-weight: normal;
						font-size: 8px;
						letter-spacing: 0.01em;
						color: #000000;
					}

					> td:nth-child(3) {
						font-style: normal;
						font-weight: normal;
						font-size: 8px;
						letter-spacing: 0.01em;
						color: #828282;

						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 9rem;
					}
				}
			}
		}

		&__paymentInfo {
			background: #ffffff;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
			border-radius: 20px;
			padding: 32px 48px;

			&__title {
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 36px;
				letter-spacing: 0.03em;
				color: #000000;
			}

			&__booking {
				display: flex;
				margin-bottom: 45px;
				justify-content: space-between;
				align-items: center;
				margin-top: 47px;

				> span:nth-child(1) {
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #495050;
				}

				> span:nth-child(2) {
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 24px;
					letter-spacing: 0.03em;
					color: #828282;
				}
			}

			&__method {
				display: flex;
				margin-bottom: 45px;
				justify-content: space-between;
				align-items: center;

				> span:nth-child(1) {
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #495050;
				}

				> span:nth-child(2) {
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 24px;
					letter-spacing: 0.03em;
					color: #828282;
				}
			}

			&__status {
				display: flex;
				margin-bottom: 45px;
				justify-content: space-between;
				align-items: center;

				> span {
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #495050;
				}

				> div {
					padding: 8px 12px;
					background: #d5f6e3;
					border-radius: 15px;

					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 27px;
					text-align: center;
					letter-spacing: 0.03em;
					color: #27ae60;
				}
			}

			&__amount {
				display: flex;
				justify-content: space-between;
				align-items: center;

				> span {
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #495050;
				}

				> div {
					display: flex;
					align-items: center;

					> span {
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 24px;
						text-align: right;
						letter-spacing: 0.03em;
						color: #828282;
						margin-right: 18px;
					}

					> svg {
						cursor: pointer;
					}
				}
			}

			&__detail {
				border-top: 1px solid #afb6b6;
				padding-top: 22px;
				margin-top: 20px;

				> span {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 19px;
					letter-spacing: 0.03em;
					color: #000000;
					mix-blend-mode: normal;
				}

				&__guest {
					margin-top: 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					> span:nth-child(1) {
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
					}

					> span:nth-child(2) {
						font-style: normal;
						font-weight: normal;
						font-size: 18px;
						line-height: 24px;
						text-align: right;
						letter-spacing: 0.03em;
						color: #828282;
					}
				}

				&__voucher {
					margin-top: 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					> div {
						> span:nth-child(1) {
							font-weight: 600;
							font-size: 14px;
							line-height: 19px;
							letter-spacing: 0.03em;
							color: #000000;
							margin-right: 10px;
						}

						> span:nth-child(2) {
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 24px;
							text-align: right;
							letter-spacing: 0.03em;
							color: #828282;
						}
					}

					> span {
						font-style: normal;
						font-weight: normal;
						font-size: 18px;
						line-height: 24px;
						text-align: right;
						letter-spacing: 0.03em;
						color: #828282;
					}
				}
			}
		}
	}

	&__right {
		width: 38%;

		@media #{$tabletMQ} {
			background-color: #fff;
			border-radius: 20px 20px 0px 0px;
			width: 100%;
			margin-top: 175px;
		}

		&__programInfo {
			background: #ffffff;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
			border-radius: 20px;
			margin-bottom: 24px;
			padding: 31px 23px;
			display: flex;
			flex-direction: column;

			@media #{$tabletMQ} {
				box-shadow: none;
				width: 100%;
				margin-bottom: 0;
				padding-bottom: 0;
				padding-top: 10px;
			}

			// MOBILE
			&__line {
				width: 42px;
				height: 2px;
				left: 167px;
				top: 261px;
				background: #bdbdbd;
				border-radius: 2px;
				align-self: center;
				margin-bottom: 10px;
			}
			&__status {
				background: #d5f6e3;
				border-radius: 10px;
				padding: 6px 18px;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 14px;
				letter-spacing: 0.03em;
				color: #27ae60;
				margin-bottom: 25px;
				width: 55%;
			}
			&__title {
				font-style: normal;
				font-weight: 600;
				font-size: 21px;
				line-height: 24px;
				letter-spacing: 0.015em;
				color: #000000;
				margin-bottom: 17px;
			}
			&__user {
				display: flex;
				align-items: center;
				margin-bottom: 18px;

				> div {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #17948c;
					margin-left: 8px;
				}

				> span {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 19px;
					letter-spacing: 0.01em;
					color: #828282;
					margin-left: 15px;
				}
			}

			&__duration {
				display: flex;
				align-items: center;
				margin-bottom: 16px;

				> span {
					border: 1px solid #828282;
					box-sizing: border-box;
					border-radius: 20px;
					padding: 8px 12px;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					text-align: center;
					letter-spacing: 0.01em;
					color: #828282;
					margin-top: 14px;
					width: 40%;
				}

				> span:nth-child(2) {
					margin-left: 12px;
				}
			}

			> span {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
				letter-spacing: 0.03em;
				color: #333333;
				margin-bottom: 12px;
			}

			&__card1 {
				border: 1px solid #bdbdbd;
				box-sizing: border-box;
				border-radius: 10px;
				margin-bottom: 33px;
				padding: 18px;
				padding-bottom: 6px;

				> table {
					> tr {
						> td:nth-child(1) {
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 17px;
							letter-spacing: 0.01em;
							color: #000000;
							padding-bottom: 12px;
						}
						> td:nth-child(2) {
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 17px;
							letter-spacing: 0.01em;
							color: #000000;
							padding-bottom: 12px;
						}

						> td:nth-child(3) {
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 17px;
							letter-spacing: 0.01em;
							color: #828282;
							padding-bottom: 12px;

							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							max-width: 11rem;
						}
					}
				}
			}

			&__card2 {
				border: 1px solid #bdbdbd;
				box-sizing: border-box;
				border-radius: 10px;
				padding: 18px;
				display: flex;
				align-items: center;
				margin-bottom: 18px;

				> div {
					margin-left: 24px;
					display: flex;
					flex-direction: column;

					> span:nth-child(1) {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
					}

					> span:nth-child(2) {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #828282;
						margin-top: 12px;
					}

					> span:nth-child(3) {
						border: 1px solid #828282;
						box-sizing: border-box;
						border-radius: 20px;
						padding: 8px 12px;
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 17px;
						text-align: center;
						letter-spacing: 0.01em;
						color: #828282;
						margin-top: 14px;
						width: 70%;
					}

					> div {
						display: flex;
						align-items: center;

						> span {
							border: 1px solid #828282;
							box-sizing: border-box;
							border-radius: 20px;
							padding: 8px 12px;
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							line-height: 17px;
							text-align: center;
							letter-spacing: 0.01em;
							color: #828282;
							margin-top: 14px;
							width: 60%;
						}

						> span:nth-child(2) {
							margin-left: 12px;
						}
					}
				}
			}

			&__card3 {
				border: 1px solid #bdbdbd;
				box-sizing: border-box;
				border-radius: 10px;
				padding: 18px;
				margin-bottom: 28px;

				&__tile {
					display: flex;
					justify-content: space-between;
					align-items: center;
					> span {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
					}
				}

				&__detail {
					border-top: 1px solid #bdbdbd;
					padding-top: 18px;
					margin-top: 14px;
					display: flex;
					flex-direction: column;

					@media #{$tabletMQ} {
						border-top: none;
					}

					&__duration {
						display: flex;
						align-items: center;
						flex-direction: row;

						> div:nth-child(1) {
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 19px;
							letter-spacing: 0.03em;
							color: #000000;
							margin-right: 8px;
						}

						> div:nth-child(2) {
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 19px;
							letter-spacing: 0.03em;
							color: #798685;
						}
					}

					&__description {
						margin-top: 14px;
						> div:nth-child(1) {
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 19px;
							letter-spacing: 0.03em;
							color: #000000;
							margin-right: 8px;
						}

						> div:nth-child(2) {
							margin-top: 8px;
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 21px;
							letter-spacing: 0.01em;
							color: #798685;
						}
					}

					&__included {
						margin-top: 14px;
						> div {
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 19px;
							letter-spacing: 0.03em;
							color: #000000;
							margin-right: 8px;
						}

						> span,
						ul {
							margin-top: 8px;
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 21px;
							letter-spacing: 0.01em;
							color: #798685;
						}
					}
				}
			}

			// FOR MOBILE
			&__titlePaymentInfo {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
				letter-spacing: 0.03em;
				color: #333333;
			}
			&__paymentInfo {
				border: 1px solid #bdbdbd;
				box-sizing: border-box;
				border-radius: 10px;
				padding: 27px 20px;
				margin-bottom: 28px;

				&__span {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #000000;
					margin-bottom: 10px;
				}
				&__span2 {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #828282;
					margin-bottom: 23px;
				}

				&__title {
					padding: 8px 12px;
					background: #d5f6e3;
					border-radius: 15px;

					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					text-align: center;
					letter-spacing: 0.03em;
					color: #27ae60;
					margin-bottom: 19px;
					width: 70%;
				}

				&__price {
					display: flex;
					align-items: center;
					margin-bottom: 10px;

					> span {
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 24px;
						text-align: right;
						letter-spacing: 0.03em;
						color: #828282;
						margin-right: 18px;
					}

					> svg {
						cursor: pointer;
					}
				}

				&__detailPrice {
					border-top: 1px solid #afb6b6;
					padding-top: 22px;
					margin-top: 20px;

					> span {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
						mix-blend-mode: normal;
					}

					&__guest {
						margin-top: 20px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						> span:nth-child(1) {
							font-weight: 600;
							font-size: 14px;
							line-height: 19px;
							letter-spacing: 0.03em;
							color: #000000;
						}

						> span:nth-child(2) {
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 24px;
							text-align: right;
							letter-spacing: 0.03em;
							color: #828282;
						}
					}

					&__voucher {
						margin-top: 20px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						> div {
							> span:nth-child(1) {
								font-weight: 600;
								font-size: 14px;
								line-height: 19px;
								letter-spacing: 0.03em;
								color: #000000;
								margin-right: 10px;
							}

							> span:nth-child(2) {
								font-style: normal;
								font-weight: normal;
								font-size: 14px;
								line-height: 24px;
								text-align: right;
								letter-spacing: 0.03em;
								color: #828282;
							}
						}

						> span {
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 24px;
							text-align: right;
							letter-spacing: 0.03em;
							color: #828282;
						}
					}
				}
			}

			&__info {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				display: flex;
				align-items: center;
				letter-spacing: 0.01em;
				color: #17948c;
				margin-bottom: 32px;

				> div {
					margin-left: 11px;
				}
			}
		}

		&__trainerContact {
			background: #ffffff;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
			border-radius: 20px;
			padding: 27px 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;

			@media #{$tabletMQ} {
				box-shadow: none;
				margin-bottom: 40px;
				border: 1px solid #e0e0e0;
				box-sizing: border-box;
				border-radius: 10px;
			}

			@media #{$phoneMQ} {
				box-shadow: none;
				border: 1px solid #e0e0e0;
				box-sizing: border-box;
				border-radius: 10px;
				margin-top: 20px;
			}

			> div {
				display: flex;
				align-items: center;

				> span {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 19px;
					letter-spacing: 0.03em;
					color: #17948c;
					margin-left: 15px;
				}
			}
		}
	}
}
