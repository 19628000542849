@media #{$tabletMQ} {
	.paymentContainer {
		background-color: #ffffff;
		border-radius: 20px 20px 0px 0px;
		position: relative;
		width: 100%;
		animation: animationFrames linear 0.3s;
		animation-iteration-count: 1;
		transform-origin: 50% 50%;

		@keyframes animationFrames {
			0% {
				transform: translate(0, 100%);
			}

			100% {
				transform: translate(0, 0%);
			}
		}

		.full-height {
			position: absolute;
			left: 0;
			width: 20%;
			top: 0;
			height: 100%;
		}
	}
}

.payment {
	height: 100%;

	display: flex;
	flex-direction: column;
	overflow-y: auto;

	@media #{$desktopMQ} {
		background-color: #ffffff;
		border-radius: 20px 20px 0px 0px;
		position: relative;
		width: 454px;
		height: 100vh;
		animation: animationFrames linear 0.2s;
		animation-iteration-count: 1;
		transform-origin: 50% 50%;

		@keyframes animationFrames {
			0% {
				transform: translate(100%, 0);
			}

			100% {
				transform: translate(0%, 0);
			}
		}
	}

	@media #{$tabletMQ} {
		// position: absolute;
		// top: 10rem;
		// right: 0;
		height: 100vh;
		width: 100vw;
		overflow-y: auto;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba($color: #bdbdbd, $alpha: 0.3);
		padding-bottom: 18px;
		padding: 15px 37px;

		svg {
			cursor: pointer;
		}

		span {
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 0.03em;
			color: #000000;
		}

		&.loginState {
			border: none;
		}
	}

	&__breadcrumbs {
		display: flex;
		align-items: center;

		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.03em;
		color: #828282;
		padding: 22px 37px;

		span {
			margin: 0 8px;
			font-size: 18px;
		}

		.active {
			font-weight: 600;
			font-size: 12px;
			color: #000000;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 80%;
		padding: 22px 37px;

		&__title {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 14px;
			letter-spacing: 0.03em;
			color: #333333;
			margin-bottom: 18px;
		}

		input {
			width: 100%;
			background: #f2f3f3;
			border-radius: 10px;
			padding: 14px 18px;

			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			letter-spacing: 0.01em;

			&::placeholder {
				color: #17948c;
			}
		}

		&__phone {
			display: flex;
			align-items: center;
			margin: 16px 0;

			.default {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #17948c;
				padding: 14px 18px;
				background: #f2f3f3;
				border-radius: 10px;
				margin-right: 12px;
			}
		}

		&__info {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			align-items: center;
			letter-spacing: 0.01em;
			color: #17948c;
			margin-top: 11px;
			padding-bottom: 22px;
			display: flex;

			> div {
				margin-left: 12px;
			}
		}

		&__footer {
			&__button {
				display: flex;
				justify-content: flex-end;
				margin-top: 47px;
				padding: 0 2rem;

				@media #{$tabletMQ} {
					margin-bottom: 3rem;
				}

				button {
					width: fit-content;
					padding: 12px 60px;
				}

				&.noPadding {
					padding: 0;
				}
			}

			&__signup {
				background: #d1f1ef;
				border-radius: 10px;
				padding: 16px 18px;
				position: relative;

				@media #{$tabletMQ} {
					margin-top: 2rem;
				}

				.header,
				.text {
					width: 75%;
				}

				.header {
					font-style: normal;
					font-weight: 600;
					font-size: 12px;
					line-height: 17px;
					letter-spacing: 0.03em;
					color: #052523;
					margin-bottom: 6px;
				}

				.text {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #052523;
				}

				&__button {
					display: flex;
					justify-content: flex-end;

					@media #{$tabletMQ} {
						margin-top: 1rem;
					}
				}

				svg {
					position: absolute;
					top: 0.5rem;
					right: 0.5rem;
					width: 24px;
					height: 24px;
					cursor: pointer;

					path {
						fill: #17948c;
					}
				}
			}
		}
	}

	&__info {
		display: flex;
		align-items: center;
		margin-top: 8px;
		margin-bottom: 16px;
		padding: 0 37px;

		> div {
			margin-left: 12px;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			letter-spacing: 0.01em;
			color: #17948c;
		}
	}

	&__session {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid rgba($color: #bdbdbd, $alpha: 0.3);
		padding-bottom: 15px;
		margin-top: 15px;
		padding: 22px 37px;

		svg {
			display: flex;
			margin-left: auto;
		}

		&__name {
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 14px;
			letter-spacing: 0.03em;
			color: #000000;
			margin-bottom: 8px;
		}

		&__venue {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 14px;
			letter-spacing: 0.01em;
			color: #2a2a2a;
		}

		&__trainer {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 14px;
			display: flex;
			align-items: center;
			letter-spacing: 0.01em;
			color: #828282;
			mix-blend-mode: normal;
			margin-top: 11px;
		}

		&__capsule {
			border: 1px solid #828282;
			box-sizing: border-box;
			border-radius: 20px;
			padding: 8px 12px;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			text-align: center;
			letter-spacing: 0.01em;
			color: #828282;
			margin-top: 14px;
		}

		&__date {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 14px;
			text-align: right;
			letter-spacing: 0.01em;
			color: #2a2a2a;
			margin-top: 6px;
			margin-bottom: 3px;
		}

		&__duration {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			text-align: right;
			letter-spacing: 0.01em;
			color: rgba($color: #2a2a2a, $alpha: 0.4);
		}
	}

	&__membership {
		background-image: url("../../Assets/background/bg-membership.png");
		padding: 8px 22px;
		height: auto; 
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		@media #{$tabletMQ} {
			background-size: initial;
			padding: 14px 19px;
		}

		&__topBody {
			width: 410px;
			background: #FFFFFF;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
			border-radius: 15px;
			margin-bottom: 18px;
			padding: 13px 21px;

			@media #{$tabletMQ} {
				width: 339px;
			 }

			.title {
				font-family: Poppins;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 19px;
				letter-spacing: 0.03em;
				color: #031615;
			}

			&__row { 
				display: flex;
				margin-top: 14px;
			}

			&__description { 
				font-family: Poppins;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #495050;
				align-self: center;
			}

			&__boxClass {
				width: 42px;
				height: 42px;
				border-radius: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 12px;


				.title {
					font-family: Poppins;
					font-weight: 600;
					font-size: 14px;
					letter-spacing: 0.03em;
					line-height: 19px;
					color: #FAFAFA;
				}
			}
		 }

		 &__bottomBody {
			width: 410px;
			height: 55px;
			background: #FFFFFF;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
			border-radius: 15px;
			padding: 12px 12px;

			@media #{$tabletMQ} {
				width: 339px;
			 }

			&__row { 
				display: flex;
				justify-content: space-between;
			}

			.title {
				font-family: Poppins;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 10px;
				color: #031615;
			}

			.discount {
				font-family: Poppins;
				font-weight: 600;
				font-size: 12px;
				line-height: 14px;
				letter-spacing: 0.01em;
				color: #17948C;
				margin-top: 7px;
			}

			.price {
				font-family: Poppins;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 10px;
				color: #17948C;
				align-self: center;

				>span {
					font-family: Poppins;
					font-size: 11px;
					font-weight: normal;
					line-height: 10px;
					color: #17948C;
				}
			}
		 }
	}

	&__membership-more { 
		display: flex;
		margin-top: 23px;
		padding: 0px 37px;
		margin-bottom: 22px;

		>p{
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			text-align: center;
			letter-spacing: 0.01em;
			color: #031615;

			>a{
				 cursor: pointer;
				 >span{
					  font-style: normal;
					  font-weight: 600;
					  font-size: 12px;
					  line-height: 17px;
					  letter-spacing: 0.01em;
					  color: #17948C;
					  cursor: pointer;
				 }
			}
	  }
	}

	&__merchantPackage { 
		border-bottom: 1px solid rgba(189, 189, 189, 0.3);
		padding-bottom: 15px;
		margin-top: 15px;
		padding: 22px 37px;

		&__name {
			font-family: Poppins;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 14px;
			letter-spacing: 0.03em;
			color: #000000;
			margin-bottom: 8px;
		}

		&__location {
			font-family: Poppins;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 14px;
			letter-spacing: 0.03em;
			color: #828282;
			margin-bottom: 8px;
		}

		&__capsuleBody {
			display: flex;

			&__capsule {
				width: 94px;
				height: 32px;
				border: 1px solid #828282;
				border-radius: 20px;
				justify-content: center;
				align-items: center;
				display: flex;
				margin-right: 12px;

				>span {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
	
					text-align: center;
					letter-spacing: 0.01em;
	
				}
			}
		 }
	}

	&__guest {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 14px;
		letter-spacing: 0.03em;
		color: #333333;
		padding-top: 22px;
		padding: 22px 37px;

		.name {
			font-weight: 500;
			color: rgba($color: #333333, $alpha: 0.6);
			margin-top: 8px;
			margin-bottom: 20px;
		}
	}

	&__method {
		padding: 22px 37px;

		.title {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 14px;
			letter-spacing: 0.03em;
			color: #333333;
			margin-bottom: 23px;
		}

		&__card {
			display: flex;
			align-items: center;
			border-bottom: 1px solid rgba($color: #bdbdbd, $alpha: 0.3);
			padding-bottom: 32px;

			@media #{$tabletMQ} {
				justify-content: space-around;
			}

			.card {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-right: 16px;

				.info {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					text-align: center;
					letter-spacing: 0.01em;
					color: #333333;
					margin-top: 5px;

					@media #{$tabletMQ} {
						white-space: nowrap;
					}
				}

				.iconWrapper {
					width: 74px;
					height: 74px;

					box-sizing: border-box;
					border-radius: 10px;
					border: 1px solid #17948c;

					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					position: relative;
					cursor: pointer;

					&.dashed {
						border: 1px dashed #17948c;
					}

					&.active {
						background: #d1f1ef;
					}

					div {
						margin-top: 2px;
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 17px;
						text-align: center;
						letter-spacing: 0.01em;
						color: #828282;
					}

					.absolute__dl {
						position: absolute;
						bottom: 0.2rem;
						left: 0.2rem;
					}

					.absolute__tr {
						position: absolute;
						top: -0.8rem;
						right: -0.8rem;
					}

					>img{
						@include respondTo (phone) {
							image-rendering: -webkit-optimize-contrast;
						}  
					}
				}
			}
		}
	}

	&__voucher {
		padding: 0 37px 12px;

		&__wrapper {
			padding: 5px 7px;
			border-radius: 10px;
			display: flex;
			background: #f2f3f3;
		}

		input {
			width: 100%;
		}

		button {
			padding: 5px 26px;
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 14px 22px;
		margin-top: auto;
		width: 100%;
		background: #f7f8f8;

		svg {
			cursor: pointer;
		}

		.text {
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 14px;
			letter-spacing: 0.03em;
			color: #333333;
		}

		.price {
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.03em;
			color: #17948c;
		}

		&__button {
			button {
				padding: 12px 63px;
			}
		}
	}

	&__detail {
		padding: 0px 37px;

		&__titleProgram {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
				letter-spacing: 0.03em;
				color: #333333;
				margin-bottom: 12px;
			}

			.button {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 17px;
				text-align: right;
				letter-spacing: 0.01em;
				color: #17948c;
				cursor: pointer;
			}
		}

		.title {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			letter-spacing: 0.03em;
			color: #333333;
			margin-bottom: 12px;
		}

		.name,
		.email,
		.phone {
			margin-top: 12px;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.01em;
			color: #000000;
		}

		.phone {
			margin-bottom: 32px;
		}

		&__card {
			background: #ffffff;
			border: 1px solid #e0e0e0;
			box-sizing: border-box;
			border-radius: 10px;
			padding: 18px;
			margin-bottom: 20px;

			&.guestInfo {
				.guestInfo__wrapper {
					display: flex;
					align-items: center;

					div {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;

						&:nth-child(1) {
							min-width: 5rem;

							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 17px;
							letter-spacing: 0.01em;
							color: #000000;
						}

						&:nth-child(3) {
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 17px;
							letter-spacing: 0.01em;
							color: #828282;
							margin-left: 20px;
						}
					}
				}
			}
			&.sessionInfo2 {
				.wrap {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.programTitle {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
					}

					> svg {
						cursor: pointer;
					}
				}

				.detail {
					.price {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 24px;
						letter-spacing: 0.03em;
						color: #17948c;
					}
				}
			}

			&.sessionInfo {
				display: flex;
				align-items: center;

				&.modalSuccess {
					min-width: 25rem;

					@media #{$tabletMQ} {
						min-width: 18rem;
					}
				}

				.sessionInfo {
					&__capsule {
						border: 1px solid #828282;
						box-sizing: border-box;
						border-radius: 20px;
						padding: 8px 12px;
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 17px;
						text-align: center;
						letter-spacing: 0.01em;
						color: #828282;
						margin-top: 14px;
						width: 70%;
					}

					&__wrapper {
						margin-left: 24px;
					}

					&__name {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
						margin-bottom: 12px;
					}

					&__venue {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #828282;
						margin-bottom: 15px;
					}

					&__time {
						display: flex;
						align-items: center;
						margin-bottom: 8px;

						svg {
							width: 24px;
							height: 24px;
						}

						span {
							&:nth-child(2) {
								font-style: normal;
								font-weight: 600;
								font-size: 14px;
								line-height: 14px;
								letter-spacing: 0.03em;
								color: #2a2a2a;
								margin: 0 12px;
							}

							&:nth-child(3) {
								font-style: normal;
								font-weight: normal;
								font-size: 12px;
								line-height: 18px;
								text-align: right;
								letter-spacing: 0.01em;
								color: rgba($color: #2a2a2a, $alpha: 0.4);
							}
						}
					}

					&__date {
						display: flex;
						align-items: center;

						span {
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 14px;
							letter-spacing: 0.03em;
							color: #2a2a2a;
							margin-left: 12px;
						}
					}
				}
			}

			&.paymentInfo {
				display: flex;
				justify-content: space-between;

				svg {
					&:nth-child(3) {
						width: 21px;
						height: 21px;
						margin-top: 1.5rem;
						display: flex;
						align-items: center;
						cursor: pointer;

						@media #{$tabletMQ} {
							margin-top: 2.5rem;
						}

						path {
							fill: #17948c;
						}
					}
				}

				.paymentInfo__wrapper {
					margin: 0 11px 0 14px;
					max-width: 15rem;
					width: 546px;

					@media #{$tabletMQ} {
						max-width: 12rem;
					}

					.title {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
					}

					.desc {
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 17px;
						letter-spacing: 0.01em;
						color: #17948c;
					}
				}
			}
		}
	}
}

.successReservation {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border-radius: 20px;
	padding: 75px 77px;

	@media #{$tabletMQ} {
		padding: 26px 22px;
		margin: 0 8px;
	}

	.title {
		font-style: normal;
		font-weight: 600;
		font-size: 21px;
		line-height: 31px;
		text-align: center;
		letter-spacing: 0.03em;
		color: #000000;
		margin-bottom: 38px;

		@media #{$tabletMQ} {
			font-size: 18px;
			line-height: 27px;
			margin-bottom: 31px;
		}
	}

	.desc {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 27px;
		text-align: center;
		letter-spacing: 0.03em;
		color: #000000;
		width: 25rem;
		margin-bottom: 39px;

		@media #{$tabletMQ} {
			font-size: 14px;
			line-height: 21px;
			width: auto;
			margin-bottom: 21px;
		}

		span {
			&:nth-child(2) {
				font-weight: bold;
			}
		}
	}
}

.selectPayment {
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: #fff;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #bdbdbd;
		padding-bottom: 18px;
		padding: 15px 37px;

		.title {
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 0.03em;
			color: #000000;
		}
	}

	&__content {
		padding: 0 37px;
	}

	&__wrapper {
		.title {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 14px;
			letter-spacing: 0.03em;
			color: #333333;
			margin-top: 32px;
		}

		.desc {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			letter-spacing: 0.01em;
			color: rgba($color: #333333, $alpha: 0.4);
			margin-top: 23px;
			width: 320px;

			@media #{$tabletMQ} {
				width: 100%;
			}

			&__wrapper {
				display: flex;
				margin-bottom: 16px;

				div {
					&:nth-child(1) {
						width: 1.2rem;
					}

					&:nth-child(2) {
						width: 100%;
					}
				}
			}
		}
	}

	&__form {
		margin-top: 32px;
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 15px 37px;

		div:nth-child(3) {
			margin-bottom: 36px;
		}

		&__wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #f2f2f2;
			border-radius: 10px;
			margin-bottom: 22px;
			padding: 10px 17px;

			label {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #17948c;
			}

			input {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #17948c;

				&::placeholder {
					color: #bdbdbd;
				}
			}
		}

		.creditCardWrapper {
			padding-top: 40px;
			border-top: 1px solid #bdbdbd;
		}
	}

	&__footer {
		display: flex;
		justify-content: flex-end;
		margin-top: auto;
		padding: 15px 37px;

		&.creditCard__footer {
			padding: 0;
		}

		button {
			margin-top: auto;
			width: fit-content;
			padding: 12px 45px;
			margin-bottom: 30px;
		}
	}
}

.paymentConfirmation {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 21.5rem;

	background: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
	padding: 44px 16px 16px;

	> .title {
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.03em;
		color: #000000;
		margin-bottom: 36px;
	}

	> .info {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		letter-spacing: 0.03em;
		color: #798685;
		margin-bottom: 50px;
		width: 16rem;

		span {
			&:nth-child(2) {
				color: #17948c;
				cursor: pointer;
			}
		}
	}

	&__info {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		margin-bottom: 20px;
		padding: 0 8px;

		svg {
			&:nth-child(1) {
				path {
					fill: #eb5757;
				}
			}
		}

		&__text {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.03em;
			color: #000000;
		}

		.item {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.03em;
			color: #000000;
			margin-right: auto;
			margin-left: 19px;
			width: 12rem;

			> div {
				margin-bottom: 13px;

				span {
					font-weight: 600;
				}

				&:nth-child(1) {
					font-weight: 600;
				}
			}
		}

		a {
			display: flex;
			align-items: center;
		}

		.info {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 124%;
			letter-spacing: 0.03em;
			color: #1dbaaf;
			padding: 6px 10px;
			height: fit-content;

			background: rgba(29, 186, 175, 0.16);
			border: 1px solid #1dbaaf;
			box-sizing: border-box;
			border-radius: 7px;
		}
	}

	&__info__doolive {
		display: flex;
		justify-content: space-between;
		border: 1px solid #FCEFCF;
		background-color: #FCEFCF;
		border-radius: 12px;
		padding: 15px;
		width: 100%;
		align-items: center;
		margin-bottom: 10px;
		// margin-bottom: 36px;
		// padding: 0 8px;

		svg {
			&:nth-child(1) {
				path {
					fill: #EDAE0F;
				}
			}
		}
		
		>span {
			text-align: justify;
		}

		&__text {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.03em;
			color: #000000;
		}

		.item {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.03em;
			color: #000000;
			margin-right: auto;
			margin-left: 19px;
			width: 12rem;

			> div {
				margin-bottom: 13px;

				span {
					font-weight: 600;
				}

				&:nth-child(1) {
					font-weight: 600;
				}
			}
		}

		.items {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.03em;
			color: #000000;
			margin-right: auto;
			margin-left: 19px;
			width: 12rem;

			> div {
				margin-bottom: 13px;

				span {
					font-weight: 600;
				}

				&:nth-child(1) {
					font-weight: 600;
				}
			}
		}

		&__info {
			display: flex;
			justify-content: space-between;
			width: 100%;
			align-items: center;
			margin-bottom: 36px;
			padding: 0 8px;
	
			svg {
				&:nth-child(1) {
					path {
						fill: #eb5757;
					}
				}
			}
	
			&__text {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.03em;
				color: #000000;
			}
	
			.item {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.03em;
				color: #000000;
				margin-right: auto;
				margin-left: 19px;
				width: 12rem;
	
				> div {
					margin-bottom: 13px;
	
					span {
						font-weight: 600;
					}
	
					&:nth-child(1) {
						font-weight: 600;
					}
				}
			}
	
			a {
				display: flex;
				align-items: center;
			}
	
			.info {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 124%;
				letter-spacing: 0.03em;
				color: #1dbaaf;
				padding: 6px 10px;
				height: fit-content;
	
				background: rgba(29, 186, 175, 0.16);
				border: 1px solid #1dbaaf;
				box-sizing: border-box;
				border-radius: 7px;
			}
		}

		.items {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.03em;
			color: #000000;
		}

		a {
			display: flex;
			align-items: center;
		}

		.info {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 124%;
			letter-spacing: 0.03em;
			color: #1dbaaf;
			padding: 6px 10px;
			height: fit-content;

			background: rgba(29, 186, 175, 0.16);
			border: 1px solid #1dbaaf;
			box-sizing: border-box;
			border-radius: 7px;
		}
	}

	&__attendance {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: rgba(29, 186, 175, 0.15);
		border-radius: 10px;
		margin-top: 36px;
		padding: 13px 16px;
		width: 100%;

		svg {
			cursor: pointer;

			&:nth-child(3) {
				width: 17px;
				height: 17px;

				path {
					fill: #17948c;
				}
			}
		}

		.wrapper {
			margin-right: auto;
			margin-left: 16px;
			width: 11rem;

			.title {
				font-style: normal;
				font-weight: 600;
				font-size: 12px;
				line-height: 12px;
				letter-spacing: 0.03em;
				color: #000000;
				margin-bottom: 4px;
			}

			.desc {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.03em;
				color: #000000;
			}
		}
	}

	&__attendance__doolive {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #1dbaaf;
		background: white;
		border-radius: 10px;
		margin-top: 36px;
		padding: 13px 16px;
		width: 100%;

		svg {
			cursor: pointer;

			&:nth-child(3) {
				width: 17px;
				height: 17px;

				path {
					fill: #17948c;
				}
			}
		}

		.wrapper {
			margin-right: auto;
			margin-left: 16px;
			width: 11rem;

			.title {
				font-style: normal;
				font-weight: 600;
				font-size: 12px;
				line-height: 12px;
				letter-spacing: 0.03em;
				color: #000000;
				margin-bottom: 4px;
			}

			.desc {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.03em;
				color: #000000;
			}
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 24px;

		button {
			width: fit-content;
			height: fit-content;
			padding: 12px 56px;

			&:nth-child(1) {
				border: none;
				margin-right: 12px;
			}
		}
	}

	&__footer__process {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 24px;

		button {
			width: fit-content;
			height: fit-content;
			padding: 12px 43px;

			&:nth-child(1) {
				border: none;
				margin-right: 12px;
			}
		}
	}
}

.attendanceInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 34px 51px 23px;
	background: #ffffff;
	border-radius: 10px;
	width: 21.5rem;

	.title {
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.03em;
		color: #000000;
		margin-bottom: 17px;
	}

	.desc {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 17px;
		text-align: center;
		letter-spacing: 0.01em;
		color: #495050;
		margin-bottom: 11px;
	}

	img {
		width: 241px;
		height: 214px;
		object-fit: contain;
	}

	button {
		width: fit-content;
		height: fit-content;
		padding: 12px 30px;
		margin-top: 25px;
	}
}

.partnerModal {
	display: flex;
	justify-content: center;
	flex-direction: column;

	background: #ffffff;
	border-radius: 20px;
	position: relative;
	padding: 27px 25px;
	width: 546px;
	height: 444px;

	@media #{$tabletMQ} {
		width: 345px;
	}

	> div {
		&:last-child {
			margin-top: 37px;
		}
	}

	svg {
		position: absolute;
		right: 1rem;
		top: 1rem;
		cursor: pointer;
	}

	&__title {
		font-style: normal;
		font-weight: 600;
		font-size: 21px;
		line-height: 31px;
		text-align: center;
		letter-spacing: 0.03em;
		color: #000000;
		margin-bottom: 37px;
	}

	&__cards {
		background: #ffffff;
		border: 1px solid #e0e0e0;
		box-sizing: border-box;
		border-radius: 10px;
		padding: 19px 23px;

		&__text {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0.03em;
			color: #000000;
			margin-top: 16px;
		}
	}

	&__capsule {
		margin-left: 0 !important;
		width: fit-content;
	}
}

.flexEnd {
	justify-content: flex-end !important;
}

.capsule__paymentMethod {
	display: flex;
	align-items: center;
	margin-top: 18px;

	> div {
		background: #ffffff;
		border: 1px solid #17948c;
		box-sizing: border-box;
		border-radius: 22px;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 17px;
		letter-spacing: 0.01em;
		color: #17948c;
		margin-right: 12px;
		padding: 8px 12px;
		cursor: pointer;

		@media #{$tabletMQ} {
			padding: 8px 10px;
		}

		@include respondTo (small--phone) {
			padding: 6px 8px;
			font-size: 10px;
		}   

		@include respondTo (semismall--phone) {
			padding: 6px 8px;
			font-size: 10px;
		}   
	}

	.selected {
		background-color: #17948c;
		color: #ffffff;
	}
}
