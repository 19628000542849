.detailMembershipDesktop{
    display: flex;
	justify-content: space-between;
    padding: 46px 112px;
    width: 100%;
    align-items: flex-start;
    
    &__left{
        width: 65%;

    
        &__banner{
            background: #4AC7BF;
            border-radius: 20px;
            width: 100%;
            height: 229px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            >p{
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 17px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.01em;

                color: #FFFFFF;

            }

            >h2{
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                line-height: 17px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.01em;
                color: #FFFFFF;
            }
        }

        &__paymentInfo{
            margin-top: 32px;
            width: 100%;
            padding-top: 32px;

            background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
            border-radius: 20px;

            >section:nth-child(1){
                margin: 0 48px;
                margin-bottom: 32px;

                >h2{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 36px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.03em;
                    color: #000000;
                    margin-bottom: 47px;
                }

                >.-bookingID{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    >span:nth-child(1){
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #495050;
                    }

                    >span:nth-child(2){
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 24px;
                        letter-spacing: 0.03em;
                        color: #828282;
                    }
                }

                >.-statusPayment{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 28px;

                    >span{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #495050;
                    }
                    
                    >div{
                        border-radius: 15px;
                        padding: 8px 18px;

                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 27px;
                        text-align: right;
                        letter-spacing: 0.03em;
                    }

                }

                >.-time{
                    margin-top: 28px;

                    >span{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #495050;
                    }   

                    >section{
                        margin-top: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        background: #FAD1D1;
                        border-radius: 15px;
                        padding: 16px;

                        >p {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-right: 100px;
                            // margin: auto;
    
                            >div {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 24px;
                                line-height: 17px;
                                letter-spacing: 0.01em;
                                color: #eb5757;
                                margin: 0 4px;
                                margin-top: 10px;
                            }
                        }

                        >button{
                            background: #FAD1D1;

                            border: 1px solid #EB5757;
                            box-sizing: border-box;
                            border-radius: 7px;
                            padding: 6px 16px;

                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 124%;
                            text-align: center;
                            letter-spacing: 0.03em;
                            color: #EB5757;
                        }
                    }
                }

                >.-amount{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 28px;

                    >span:nth-child(1){
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #495050;
                    }

                    >span:nth-child(2){
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #17948C;
                    }
                }

                >.-transfer{
                    margin-top: 32px;

                    >span{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 19px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.01em;
                        color: #495050;
                    }

                    >section{
                        margin-top: 12px;

                        background: rgba(29, 186, 175, 0.15);
                        border-radius: 10px;
                        padding: 13px 16px;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        >div:nth-child(1){
                            display: flex;
                            flex-direction: column;

                            >span{
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 17px;
                                letter-spacing: 0.01em;
                                color: #031615;
                            }
                        }

                        >div:nth-child(2){
                            background: rgba(29, 186, 175, 0.16);
                            border: 1px solid #1DBAAF;
                            box-sizing: border-box;
                            border-radius: 7px;

                            padding: 6px 11px;

                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 124%;
                            letter-spacing: 0.03em;
                            color: #1DBAAF;
                            cursor: pointer;
                        }

                    }
                }
            }

            >section:nth-child(2){
                background: #F2F3F3;
                border-radius: 0px 0px 20px 20px;
                padding: 40px 48px;

                >div{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 27px;

                    >span{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 14px;
                        letter-spacing: 0.03em;
                        color: #333333;
                    }

                    >svg{
                        cursor: pointer;
                    }
                }

                >section{
                    background: #FFFFFF;
                    border-radius: 10px;
                    padding: 19px 44px;

                    >span{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 19px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.01em;
                        color: #000000;
                        margin-bottom: 10px;
                    }

                    >table{
                        margin-bottom: 23px;
                        width: 100%;
                        
                        >tr{
                            >td{
                                font-style: normal;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 17px;
                                letter-spacing: 0.01em;
                                color: #000000;
                            }
                        }
                    }

                    >button{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 14px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.03em;
                        color: #17948C;
                    }
                }

                >aside{
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    margin-top: 32px;

                    >button{
                        padding: 12px 61px;
                        background: #17948C;
                        border-radius: 10px;


                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.03em;
                        color: #FFFFFF;
                    }

                }
            }

        }
    }   

    &__right{
        width: 33%;
        
        >section{
            padding: 31px 23px;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
            border-radius: 20px;

            >.-accountDetail{
                >h2{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    letter-spacing: 0.03em;
                    color: #333333;
                }

                >section{
                    background: #FFFFFF;
                    border: 1px solid #E0E0E0;
                    box-sizing: border-box;
                    border-radius: 10px;
                    margin-top: 12px;
                    padding: 18px;

                    >table{
                        width: 100%;
                            
                        >tr{
                            >td{
                                padding: 10px 0;
                            }

                            >td:nth-child(1), >td:nth-child(2){
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 17px;
                                // display: flex;
                                align-items: center;
                                letter-spacing: 0.01em;

                                color: #000000;
                            }

                            >td:nth-child(3){
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 17px;
                                display: flex;
                                align-items: center;
                                letter-spacing: 0.01em;
                                color: #828282;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }

            >.-membership{
                >h2{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    letter-spacing: 0.03em;
                    color: #333333;
                }

                >section{
                    background: #FFFFFF;
                    border: 1px solid #E0E0E0;
                    box-sizing: border-box;
                    border-radius: 15px;
                    margin-top: 14px;

                    padding: 13px 21px;

                    >h2{
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 19px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.03em;
                        color: #031615;
                    }

                    >div{
                        margin-top: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        >section{
                            padding: 8px;
                            border-radius: 10px;
                            margin-right: 12px;

                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 19px;
                            /* or 136% */

                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            letter-spacing: 0.03em;

                            color: #FAFAFA;

                            width: 42px;
                            height: 42px;
                        }

                        >span{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 17px;
                            letter-spacing: 0.01em;
                            color: #495050;
                        }
                        
                    }
                }
            }

            >.-billed{
                background: #FFFFFF;
                border: 1px solid #C9CFCE;
                border-radius: 10px;
                padding: 12px 22px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 18px;

                >label{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 10px;
                    color: #031615;
                }

                >p{
                    margin-top: 4px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 19px;
                    text-align: center;
                    letter-spacing: 0.03em;
                    color: #17948C;

                    >span{
                        font-style: normal;
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 24px;
                        letter-spacing: 0.03em;
                        color: #17948C;
                    }
                }
            }
        }

        >div{
            margin-top: 28px;

            >section{
                padding: 40px 55px;

                background: #FFFFFF;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
                border-radius: 20px;

                >p{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;
                    letter-spacing: 0.03em;
                    color: #333333;
                }

                >img{
                    margin-top: 16px;

                    width: 260px;   
                    height: 253px;
                }
            }

           >a{
            >button{
                width: 100%;
                margin-top: 23px;

                background: #17948C;
                border-radius: 15px;

                padding: 12px;

                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                /* identical to box height, or 133% */

                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                letter-spacing: 0.03em;

                /* DOOGETHER V3/ WHITE */

                color: #FFFFFF;
            }
           }


            >p{
                padding: 0 20px;
                margin-top: 20px;

                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                letter-spacing: 0.03em;
                color: #333333;
            }

        }

    }
}