.register {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 99px 443px;
	z-index: 1;

	@media #{$phoneMQ} {
		padding: 0;
	}

	&__card {
		background: #ffffff;
		box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
		border-radius: 20px;
		padding: 30px 106px;
		width: 35rem;
		position: relative;
		z-index: 1;
		height: 691px;

		@media #{$phoneMQ} {
			padding: 46px 0;
			width: 100%;
			box-shadow: none;
			border-radius: 0;
		}

		&__background {
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: -1;

			@media #{$phoneMQ} {
				width: 40%;
			}
		}

		&__step {
			@media #{$phoneMQ} {
				padding: 0 16px;
			}

			&__step4 {
				&__phone {
					font-family: Poppins;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #333333;
					margin-bottom: 5px;
				}

				&__description {
					font-family: Poppins;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #495050;
					margin-bottom: 7px;
				}

				&__change_phone {
					font-family: Poppins;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #17948c;
					margin-bottom: 47px;
				}

				&__code {
					display: flex;
					margin-bottom: 36px;
					width: 100%;
					justify-content: space-between;

					&__form {
						width: 78px;
						height: 44px;
						background: #f2f3f3;
						border-radius: 10px;
						padding: 8px 10px 10px 8px;

						>input {
							width: 100%;
							text-align: center;
						}
					}
				}

				&__resend_code {
					font-family: Poppins;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #17948c;
					margin-bottom: 47px;
					text-align: center;
				}
			}

			&__with {
				display: flex;
				align-items: center;

				border: 1px solid #17948c;
				box-sizing: border-box;
				border-radius: 10px;
				padding: 12px 19px;
				margin-bottom: 24px;
				cursor: pointer;

				&__img {
					width: 25px;
					height: 25px;
					margin-right: 15px;
					display: flex;
					justify-content: center;

					img {
						object-fit: contain;
					}
				}

				&__title {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 12px;
					letter-spacing: 0.01em;
					color: #333333;
				}
			}

			&__form {
				&__title {
					font-family: Poppins;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: 0.03em;
					margin-bottom: 16px;

					color: #000000;
				}

				&__number {
					display: flex;
					justify-content: space-between;

					&__square {
						width: 55px;
						height: 44px;
						background: #f2f3f3;
						border-radius: 10px;
						color: #17948c;
						display: flex;
						align-items: center;
						letter-spacing: 0.01em;
						font-family: Poppins;
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						padding-left: 15px;
						margin-right: 12px;
					}

					&__error {
						font-family: Poppins;
						font-style: normal;
						font-weight: normal;
						font-size: 10px;
						line-height: 124%;
						letter-spacing: 0.03em;
						color: #eb5757;
						margin-bottom: 10px;
						margin-left: 75px;
					}

					&__phone {
						background: #f2f3f3;
						border-radius: 10px;
						height: 44px;
						width: 276px;

						@media #{$phoneMQ} {
							width: 310px;
						}
					}
				}

				input {
					width: 100%;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					display: flex;
					align-items: center;
					letter-spacing: 0.01em;
					padding: 14px 18px;

					&::placeholder {
						color: #17948c;
					}
				}

				&__email {
					background: #f2f3f3;
					border-radius: 10px;

					&__error {
						font-family: Poppins;
						font-style: normal;
						font-weight: normal;
						font-size: 10px;
						line-height: 124%;
						letter-spacing: 0.03em;
						color: #eb5757;
						margin-bottom: 10px;
						margin-left: 5px;
					}
				}

				&__password {
					background: #f2f3f3;
					border-radius: 10px;
					display: flex;
					align-items: center;

					svg {
						cursor: pointer;
						margin-right: 9px;
					}

					&__error {
						font-family: Poppins;
						font-style: normal;
						font-weight: normal;
						font-size: 10px;
						line-height: 124%;
						letter-spacing: 0.03em;
						color: #eb5757;
						margin-bottom: 10px;
						margin-left: 5px;
					}
				}

				&__confirm {
					background: #f2f3f3;
					border-radius: 10px;
					display: flex;
					align-items: center;

					svg {
						cursor: pointer;
						margin-right: 9px;
					}
				}

				&__phone {
					background: #f2f3f3;
					border-radius: 10px;
					height: 44px;
					width: 276px;
				}

				&__description {
					font-family: Poppins;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 20px;
					letter-spacing: 0.01em;
					margin-bottom: 28px;

					color: #495050;
				}
			}

			&__info {
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				text-align: center;
				letter-spacing: 0.01em;
				color: rgba($color: #333333, $alpha: 0.5);
				margin-bottom: 28px;
			}

			&__sectionTitle {
				font-family: Poppins;
				font-style: normal;
				font-weight: 600;
				font-size: 21px;
				line-height: 31px;
				display: flex;
				align-items: center;
				letter-spacing: 0.03em;
				margin-bottom: 24px;

				color: #114b5f;
			}
		}

		&__nextButton {
			@media #{$phoneMQ} {
				padding: 0 16px;
			}
		}

		&__slider {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 24px;

			@media #{$phoneMQ} {
				padding: 0 16px;
			}

			&__unselected {
				background-color: #d1f1ef;
				width: 8px;
				height: 8px;
				margin: 0 8px;
				border-radius: 8px;
				cursor: pointer;
			}

			&__selected {
				background-color: #17948c;
				width: 15px;
				height: 8px;
				margin: 0 8px;
				border-radius: 4px;
				cursor: pointer;
			}
		}

		&__signup {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 18px;

			span {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				letter-spacing: 0.01em;
				color: #031615;
			}

			button {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				letter-spacing: 0.01em;
				color: #17948c;
			}
		}

		&__title {
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 0.03em;
			text-align: center;
			color: #000000;
			margin-bottom: 60px;
		}
	}

	&__forget {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		display: flex;
		letter-spacing: 0.03em;
		color: #17948c;
		cursor: pointer;
		margin-bottom: 32px;
		z-index: 1;
	}

	&__footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 32px;

		@media #{$phoneMQ} {
			padding-bottom: 49px;
		}

		&__title {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 0.03em;
			color: #031615;
			margin-bottom: 20px;
		}

		&__cta {
			display: flex;
			justify-content: center;

			img {
				cursor: pointer;

				@media #{$phoneMQ} {
					width: 40%;
				}

				&:nth-child(1) {
					margin-right: 24px;
				}
			}
		}
	}

	&__modal {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		background: #ffffff;
		border-radius: 20px;
		padding: 37px 49px;
		width: 343px;
		height: 322px;

		@media #{$phoneMQ} {
			width: 20rem;
			padding: 30px 20px;
		}

		&__profpict {
			margin-bottom: 18px;

			img {
				width: 100px;
				height: 100px;
				border: 1px solid #17948c;
				border-radius: 100%;
				object-fit: cover;
			}
		}

		&__greating {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			display: flex;
			align-items: center;
			letter-spacing: 0.01em;
			margin-top: 21;

			color: #828282;
		}

		&__title {
			font-family: Poppins;
			font-style: normal;
			font-weight: 600;
			font-size: 21px;
			line-height: 31px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.03em;

			color: #17948c;
		}

		&__signup_suuces {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 20px;
			width: 245px;
			height: 152.95px;

			@media #{$phoneMQ} {
				margin-bottom: 16px;
			}

			div {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				text-align: center;
				letter-spacing: 0.01em;
				color: #0a423f;
				margin-left: 12px;
			}
		}

		&__info {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			display: flex;
			align-items: center;
			letter-spacing: 0.01em;

			color: #031615;

			@media #{$phoneMQ} {
				font-size: 13px;
				line-height: 23px;
				margin-bottom: 24px;
			}
		}

		>span {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			text-align: center;
			letter-spacing: 0.03em;
			color: #4f4f4f;
			margin: 8px 0 -4px;

			@media #{$phoneMQ} {
				font-size: 11px;
				line-height: 15px;
				margin: 8px 0 0;
			}
		}

		>button {
			width: fit-content;
			padding: 12px 71px;

			@media #{$phoneMQ} {
				font-size: 12px;
				line-height: 20px;
			}

			padding: 8px 20px;
		}
	}
}

.oauth {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 99px 433px;

	@media #{$tabletMQ} {
		padding: 0;
	}

	&__card {
		background: #FFFFFF;
		box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
		border-radius: 20px;
		padding: 20px 106px 87px;
		width: 555px;
		height: 691px;
		position: relative;
		z-index: 1;

		display: flex;
		flex-direction: column;
		justify-content: center;

		@media #{$tabletMQ} {
			padding: 46px 16px;
			width: 100%;
			box-shadow: none;
			border-radius: 0;
		}

		.title {
			font-style: normal;
			font-weight: 600;
			font-size: 21px;
			line-height: 31px;
			text-align: start;
			letter-spacing: 0.03em;
			color: #114B5F;
			width: 15rem;
			margin-bottom: 50px;
		}
	}

	&__form {
		&__phone {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 12px;

			.default {
				background: #F2F3F3;
				border-radius: 10px;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #17948C;
				padding: 14px 18px;
				margin-right: 12px;
			}

			input {
				background: #F2F3F3;
				border-radius: 10px;
				padding: 14px 18px;
				width: 100%;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #17948C;
			}
		}

		&__username {
			input {
				background: #F2F3F3;
				border-radius: 10px;
				padding: 14px 18px;
				width: 100%;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #17948C;
				margin-bottom: 16px;
			}
		}
	}

	&__desc {
		display: flex;
		align-items: center;
		margin-bottom: 32px;

		svg {
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}

		.text {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			letter-spacing: 0.01em;
			color: #495050;
		}
	}
}