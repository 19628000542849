.rating {
	@media #{$tabletMQ} {
		background-color: #fff;
	}

	&__button {
		margin-bottom: 48px;
		margin-top: 32px;
		display: flex;
		align-items: center;
		justify-content: center;

		> button {
			width: 343px;
			height: 48px;
			background: #17948c;
			border-radius: 15px;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;
			text-align: center;
			letter-spacing: 0.03em;
			color: #ffffff;
		}
	}

	&__modal {
		width: 343px;
		height: 322px;
		background: #ffffff;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		> p {
			font-style: normal;
			font-weight: 600;
			font-size: 21px;
			line-height: 31px;
			letter-spacing: 0.03em;
			color: #1dbaaf;
			margin-top: 40px;
			margin-bottom: 2px;
		}

		> div {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 17px;
			letter-spacing: 0.01em;
			color: #031615;
		}
	}

	&__card {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 48px 113px 0px 113px;

		@media #{$tabletMQ} {
			flex-direction: column-reverse;
			padding: 0;
		}

		&__information {
			@media #{$tabletMQ} {
				width: 100%;
				box-shadow: none;
				border-radius: 0;
				padding: 32px 32px 0 32px;
			}

			width: 65%;
			background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
			border-radius: 20px;
			padding: 32px 48px;

			&__title {
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 36px;
				display: flex;
				align-items: center;
				letter-spacing: 0.03em;
				color: #000000;
				margin-bottom: 47px;

				@media #{$tabletMQ} {
					font-size: 14px;
					margin-bottom: 26px;
				}
			}

			&__rateTitle {
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #495050;
				margin-bottom: 31px;

				@media #{$tabletMQ} {
					display: none;
				}
			}

			&__giveReviewTitle {
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #495050;
				margin-top: 36px;
				margin-bottom: 37px;

				@media #{$tabletMQ} {
					font-weight: 600;
					line-height: 36px;
					letter-spacing: 0.03em;
					color: #000000;
					font-size: 14px;
					margin-top: 27px;
					margin-bottom: 32px;
				}
			}

			&__reviewDesc {
				padding: 14px 18px;
				width: 100%;
				height: 114px;
				background: #f2f3f3;
				border-radius: 10px;
				border: none;
			}

			&__rateIcon {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				@media #{$tabletMQ} {
					justify-content: center;
				}

				> div {
					cursor: pointer;
				}
			}
		}

		&__bookingdetail {
			@media #{$tabletMQ} {
				width: 90%;
				align-self: center;
				margin-top: 31px;
				// box-shadow: none;
				// border-radius: 0;
			}

			width: 33%;
			background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
			border-radius: 20px;
			padding: 31px 23px;
			height: 454px;

			&__guestTitle {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
				letter-spacing: 0.03em;
				color: #333333;
			}

			&__guestContent {
				margin-top: 12px;
				background: #ffffff;
				border: 1px solid #e0e0e0;
				box-sizing: border-box;
				border-radius: 10px;
				padding: 2px 18px 18px 18px;
				margin-bottom: 32px;

				&__item {
					display: flex;
					align-items: center;
					margin-top: 12px;

					> p {
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 17px;
						display: flex;
						align-items: center;
						letter-spacing: 0.01em;
						color: #000000;
					}

					> div {
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 17px;
						display: flex;
						align-items: center;
						letter-spacing: 0.01em;
						color: #828282;
					}
				}
			}

			&__classTitle {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
				letter-spacing: 0.03em;
				color: #333333;
			}

			&__classContent {
				margin-top: 12px;
				background: #ffffff;
				border: 1px solid #e0e0e0;
				box-sizing: border-box;
				border-radius: 10px;
				padding: 18px;
				display: flex;
				align-items: center;

				&__icon {
					width: 62px;
					height: 62px;
					background: #e9fcfa;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 24px;
					border-radius: 62px;
				}

				&__info {
					> p {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #000000;
						mix-blend-mode: normal;
					}

					&__venue {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 19px;
						letter-spacing: 0.03em;
						color: #828282;
						mix-blend-mode: normal;
						margin-top: 12px;
						margin-bottom: 15px;
					}

					&__time {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-bottom: 15px;
						margin-left: -4px;

						> img {
							margin-left: -4px;
							margin-right: 9px;
						}

						> p {
							margin-right: 7px;
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 14px;
							letter-spacing: 0.03em;
							color: #2a2a2a;
						}

						> div {
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							line-height: 18px;
							letter-spacing: 0.01em;
							color: #2a2a2a;
							mix-blend-mode: normal;
							opacity: 0.4;
						}
					}

					&__date {
						display: flex;
						align-items: center;
						justify-content: flex-start;

						> img {
							margin-right: 12px;
						}

						> p {
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 14px;
							letter-spacing: 0.03em;
							color: #2a2a2a;
						}
					}
				}
			}
		}
	}
}
