@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

.astra {
   width: 100vw;
   font-family: 'Rubik', sans-serif;

   &__header {
      padding-top: 49px;

      >svg{
         margin: 0 16px;
      }

      &__title {
         padding: 5px 16px 22px 16px;
         font-style: normal;
         font-weight: bold;
         font-size: 27px;
         line-height: 32px;
         color: #000;
      }

      &__img {
         object-fit: contain;
         width: 100%;
      }
   }

   &__protection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 31px 0;

      >div {
         font-style: normal;
         font-weight: 500;
         font-size: 24px;
         line-height: 28px;
         letter-spacing: 0.02em;
         text-align: center;
         color: #004FB6;
      }
   }

   &__benefit {
     >section{
        border-top: 1px solid #D8D8D8;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >div{
         color: #666666;
			font-family: Poppins;
         font-size: 12px;
         font-style: normal;
         line-height: 18px;
         letter-spacing: 2%;
        }

        >span{
         color: #000;
         font-size: 14px;
         font-weight: 400;
         line-height: 17px;
         letter-spacing: 2%;
        }
     }
   }

   &__description {
      padding: 21px 27px;
      background-color: #F1F8FF;

      >h4{
         color: #004FB6;
         font-size: 14px;
         line-height: 17px;
         text-align: left;
         letter-spacing: 2%;

         margin-bottom: 13px;
      }

      >p{
         font-size: 12px;
         line-height: 17px;
         text-align: left;
         letter-spacing: 2%;
         color: #000;

         margin-bottom: 19px;
      }

      >div{
         display: flex;
         justify-content: center;
         align-items: center;

         >a {
            display: flex;
            padding: 10px 20px;
            background: linear-gradient(0deg, #004fb6, #004fb6), #ffffff;
            border-radius: 30px;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            color: #fff;
            font-size: 12px;
         }
      }
   }

   &__summaryInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 20px;

      >a {
         display: flex;
         padding: 10px 20px;
         align-items: center;
         justify-content: center;
         margin-top: 18px;
         margin-bottom: 27px;

         cursor: pointer;
         background: #ffffff;
         border: 2px solid #004fb6;
         border-radius: 30px;
         text-decoration: none;

         color: #004fb6;
         font-size: 12px;
      }
   }

   &__footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #f1f8ff;
      padding: 24px 53px;

      >p {
         margin-bottom: 10px;
         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 17px;
         text-align: center;
         letter-spacing: 0.02em;
         color: #000;
      }

      >div {
         margin-top: 10px;
         text-align: center;
         font-size: 12px;
         letter-spacing: 0.02em;
         color: #0b59a5;

         >a{
            text-decoration: underline !important;
         }
      }
   }

   // TERMS AND CONDITIONAL
   &__banner {
      margin-top: 6px;
      height: 61px;
      background: url('../../Assets/astra/banner.png');
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      >section{
         padding-right: 27px;
         background-color: #fce012;
         height: 100%;
      }
   }

   &__termsBody {
      padding: 32px 16px 0px 16px;
      background-color: #f1f8ff;

      &__firstTitle,
      &__lastTitle {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 17px;
         letter-spacing: 0.02em;
         color: #004fb6;
      }

      &__firstDescription {
         margin-bottom: 20px;
         padding-left: 16px;

         >li {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            text-align: justify;
            letter-spacing: 0.02em;
            color: #333;
         }
      }

      &__lastDescription {
         >ol{
            padding-left: 16px;

            >li {
               font-style: normal;
               font-weight: normal;
               font-size: 12px;
               line-height: 17px;
               text-align: justify;
               letter-spacing: 0.02em;
               color: #333;
            }


            >div {
               // padding-left: 10px;
               margin-bottom: 17px;
               margin-top: 22px;
   
               >p {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 17px;
                  text-align: justify;
                  letter-spacing: 0.02em;
                  color: #333;
               }
            }
         }

         >p {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            text-align: justify;
            letter-spacing: 0.02em;
            color: #333;
         }
      }

      &__footer {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         background-color: #f1f8ff;
         margin-top: 27px;

         >p {
            margin-top: 15px;
            margin-bottom: 12px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #000;
         }

         >div {
            text-align: center;
            font-size: 12px;
            letter-spacing: 0.02em;
            padding: 5px 0 45px 0;
            line-height: 17px;
            color: #000;

            >a {
               text-decoration: underline !important;
               color: #0b59a5;
            }
         }
      }
   }
}

.astra.container {
   p {
      margin: 0;
   }
}