.joinUs {
  background: #fff;
  min-height: 293px;
  padding: 92px 191px;
  position: relative;

  @include respondTo(tablet) {
    padding: 16px;
  }
}

.join-us__card {
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 64px 61px 28px;
  margin: auto;
  z-index: 20;

  &--doofit {
    background: #17948C;
  }

  &--doofood {
    background: #EFD50C;
  }

  >img, > .lazyImageWrapper {
    position: absolute;
    bottom: -8rem;
    right: -5rem;
    z-index: -1;

    @include respondTo(tablet) {
      top: 0;
      right: -3rem;
      width: 125%;
      object-fit: contain;
      transform: rotate(180deg);
    }
  }
}

.join-us__action {
  @include respondTo(tablet) {
    display: flex;
    margin: 0 auto;
  }
}

.join-us__content {
  display: grid;
  grid-template-columns: 50% auto;

  @include respondTo(tablet) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    margin-top: 150px;
  }

  >img {
    position: absolute;
    top: -2rem;
    right: 0;
    z-index: 1;
    width: 550px;
    height: 366px;
    object-fit: cover;

    @include respondTo(tablet) {
      position: absolute;
      width: 100%;
      height: 258px;
      object-fit: contain;
    }
  }
}

.join-us__btn {
  font-weight: bold;
  font-size: 18px;
  line-height: 113%;
  background: #052523;
  border-radius: 10px;
  padding: 13px 16px;

  @include respondTo(tablet) {
    margin: 0 auto;
  }

  &--doofit,
  &--doofood {
    color: #FFFFFF;
  }
}

.text__join-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;

  @include respondTo(tablet) {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }

  &--doofit {
    color: #FFFFFF;
  }

  &--doofood {
    color: #4F4F4F;
  }
}

.text__join-info {
  font-size: 18px;
  line-height: 32px;
  margin: 8px 0;

  @include respondTo(tablet) {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin: 12px 0;
  }

  &--doofit {
    color: #FFFFFF;
  }

  &--doofood {
    color: #4F4F4F;
  }
}