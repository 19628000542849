.about-header {
    background-image: url('../../Assets/about/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 390px;
    margin-bottom: 100px;

    >div {
        padding: 100px 150px 50px;
        font-size: 41px;
        font-weight: 400;

        @include respondTo(phone) {
            padding: 150px 50px 50px;
        }
    }
}

.about-main {
    margin-bottom: 100px;

    &__desc {
        text-align: center;
        margin-bottom: 50px;
    }

    &__emploies {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &__item {
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 100px;

            @include respondTo(phone) {
                width: 100%;
            }

            >img {
                align-self: center;

            }

            >span {
                text-align: center;
            }

            >span:nth-of-type(1) {
                font-size: 18px;
                text-transform: capitalize;
                font-weight: 500;
                margin-bottom: 5px;
            }

            >span:nth-of-type(2) {
                font-size: 16px;
                text-transform: uppercase;
            }
        }
    }
}

.about-footer {
    position: relative;
    min-height: 700px;

    >img {
        position: absolute;
        bottom: -280px;
        left: 0;
        z-index: -1;

        @include respondTo(desktop) {
            width: 100%;
        }

        @include respondTo(phone) {
            height: 1000px;
            object-fit: cover;
        }
    }

    span {
        font-size: 45px;
        position: absolute;
        bottom: 100px;
        left: 130px;
        color: #fff;
        max-width: 500px;

        @include respondTo(desktop) {
            bottom: 7%;
            left: 8%;
        }

        @include respondTo(phone) {
            font-size: 32px;
        }
    }
}