%tnc-navigation {
    padding: 30px 20px;
    font-size: 12px;
    width: calc(100% - 4px);
}

.tnc-main {
    display: flex;
    padding: 100px 0;

    @include respondTo(tablet) {
        flex-direction: column;
        padding: 100px 5%;
    }
}

.tnc-main__nav {
    display: flex;
    flex-direction: column;
    min-width: 175px;
    max-width: 175px;
    margin-right: 50px;

    @include respondTo(tablet) {
        width: 100%;
        min-width: unset;
        max-width: unset;
    }

    >div {
        text-align: center;
    }

    >div:nth-child(1) {
        font-size: 12px;
        border-radius: 5px 5px 0 0;
        background: #e1e1e1;
        padding: 30px 20px;
        padding: 8px 0;
        border-bottom: 1px solid #fff;
        width: 100%;
    }

    &--idle {
        @extend %tnc-navigation;
        cursor: pointer;
        color: #666;
        background: #f5f5f5;

    }

    &--active {
        @extend %tnc-navigation;
        cursor: pointer;
        color: #666;
        font-weight: 500;
        border-right: 4px solid #1f7999;
        border-image-source: linear-gradient(0deg, #67d77d, #1f7999);
        border-image-slice: 4;
        background-color: #fff;
    }

    &--seperator {
        @extend %tnc-navigation;
        color: #222;
        background-color: #e1e1e1
    }
}

.tnc-main__content {
    color: #555;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    text-align: justify;
    width: 100%;

    h2 {
        margin-bottom: 21px;
    }

    >ol {
        padding-left: 12px;

        li {
            margin-bottom: 6px;
        }
    }

    p {
        padding-bottom: 6px;
    }

    b {
        color: #222;
    }
}