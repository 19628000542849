.detailPromo{
    background-color: #F5F9F9;

    &__header{
        &__banner{
            background: url('../../Assets/background/promo-detail-background.png');
            background-size: cover;
            display: flex;
            justify-content: center;
            height: 534px;
            
            @include respondTo(tablet){
                height: 132px;
                margin-top: 63px;
            }
            
            >img{
                margin-top: 54px;
                margin-bottom: 65px;
                height: 343px;
                width: 893px;
                border-radius: 20px;
                background-color: #ddd;
                object-fit: cover;
                
                @include respondTo(tablet){
                    width: 343px;
                    height: 114px;
                    margin-top: 16px;
                    border-radius: 20px 20px 0 0;
                }
            }
        }

        &__info{
            margin-top: -32px;
            padding: 32px 113px;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
            
            background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
            
            @include respondTo(tablet){
                margin-top: 0;
                border-radius: 0;
                height: 81px;
                padding: 0 16px;
            }

            >section{
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include respondTo(tablet){
                    position: absolute;
                    top: 72px;
                    background-color: #fff;
                    height: 63px;
                    width: 100%;
                    left: 0;
                    padding: 0 16px;
                }

                >img{
                    display: none;

                    @include respondTo(tablet){
                        display: block;
                        margin-right: 16px;
                    }
                }

                >h2{
                    font-size: 36px;
                    font-weight: 600;
                    color: #000;

                    @include respondTo(tablet){
                        content: "Promo Detail";
                        font-size: 24px;
                        font-weight: 500;

                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                    }
                }

                >.titleDesktop{
                    @include respondTo(tablet){
                        display: none;
                    }
                }

                >.titleMobile{
                    display: none;

                    @include respondTo(tablet){
                        display: block;
                        width: 80%;
                    }
                }

                >aside{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @include respondTo(tablet){
                        height: 36px;
                        width: 36px;
                        border-radius: 50%;
                        background-color: #F5F9F9;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    >svg{
                        @include respondTo(tablet){
                            width: 18px;
                            height: 19.92px;
                        }

                        >path{
                            @include respondTo(tablet){
                                fill: #4F6060;
                            }
                        }
                    }

                    >span{
                        margin-left: 16px;
                        font-size: 18px;
                        color: #17948C;
                        font-weight: 600;

                        @include respondTo(tablet){
                            display: none;
                        }
                    }
                }
            }   

            >div{
                margin-top: 16px;
                display: flex;
                align-items: center;

                @include respondTo(tablet){
                    justify-content: space-between;
                    width: 343px;
                    height: 63px;
                    margin-top: 0;

                    background-color: #ddd;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
                    background-color: #fff;

                    &::before, &::after{
                        content: "";
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        background-color: #f8f8f8;
                    }

                    &::before{
                        margin-left: -14px;
                        border-bottom-right-radius: 26px;
                        border-top-right-radius: 26px;
                        box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.2);
                    }

                    &::after{
                        margin-right: -14px;
                        border-bottom-left-radius: 26px;
                        border-top-left-radius: 26px;
                        box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.2);
                    }

                }

                >section{
                    display: flex;
                    align-items: center;
                    margin-right: 22px;

                    @include respondTo(tablet){
                        display: none;
                    }

                    >svg{
                        margin-right: 12px;
                        width: 16px;
                        height: 16px;
                        
                        >path{
                            fill: #9AA4A4;
                        }
                    }
                    
                    >span{
                        font-size: 12px;
                        color: #17948C;
                        font-weight: 600;
                    }
                }
                >p{
                    color: #666666;
                    font-weight: 400;
                    font-size: 12px;
                    margin: 0;

                    @include respondTo(tablet){
                        width: 80%;
                    }
                    
                    >span{
                        color: #17948C;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    &__body{
        display: flex;

        @include respondTo(tablet){
            flex-direction: column;
        }

        >div{
            @include respondTo(tablet){
                width: 100%;
            }    
        }

        &__description{
            background-color: #fff;
            width: 50%;
            padding: 110px 139px 82px 113px;
            margin-top: 8px;
            
            @include respondTo(tablet){
                padding: 18px 17px;
            }
            
            >h4{
                font-size: 18px;
                color: #031615;
                font-weight: 500;

                @include respondTo(tablet){
                    font-size: 14px;
                }
            }
            
            >p{
                margin-top: 16px;
                color: #323232;
                font-size: 14px;
                font-weight: 400;

                @include respondTo(tablet){
                    margin-top: 6px;
                    font-size: 14px;
                }
            }
            
            >div{
                margin-top: 47px;
                display: flex;
                align-items: center;

                @include respondTo(tablet){
                    margin-top: 19px;
                    font-size: 14px;
                }

                >p{
                    margin: 0;
                    margin-left: 10px;
                    color: #666666;
                    font-size: 14px;
                }
            }
        }  
        
        &__hint{
            width: 50%;
            padding: 110px 98px 82px 95px;
            display: flex;
            flex-direction: column;

            @include respondTo(tablet){
                padding: 0;
                padding-top: 16px;
            }

            &__promoCode{
                background-color: #fff;
                padding: 14px 0;
                border: 1px solid #D0E9E8;

                display: flex;
                align-items: center;
                justify-content: space-between;

                width: 337px;
                align-self: center;

                >div{
                    >h4{
                        font-size: 14px;
                        color: #323232;
                        font-weight: 500;
                    }

                    >div{
                        margin-top: 8px;
                        display: flex;

                        >div{
                            margin-right: 7px;
                            background-color: #E7F8FE;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 11.5px 52.5px;  
                            border-radius: 5px;
                            
                            color: #127670;
                            font-size: 14px;
                            font-weight: 600;
                        }
                        
                        >button{
                            border-radius: 10px;  
                            border: 1px solid #DBE1E1;
                            padding: 10.5px 20px;  
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            color: #127670;
                            font-size: 14px;
                            font-weight: 600;

                            @include respondTo(tablet){
                                font-size: 12px;
                            }
                        }
                    }
                }

                >aside{
                    height: 30px;
                    width: 30px;
                    border-radius: 30px;
                    background-color: #D0E9E8;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >div{
                        background-color: #F5F9F9;
                        height: 28px;
                        width: 27px;
                        border-radius: 27px;
                    }
                }

                >.clipLeft{
                    margin-left: -12px;  
                    
                    >div{
                        margin-left: -4px;
                        margin-top: -1px;
                    }
                }
                
                >.clipRight{
                    margin-right: -12px;
                    
                    >div{
                        margin-right: -4px;
                        margin-top: -1px;
                        height: 29.5px;
                    }
                }
            }
            
            &__steps{
                margin-top: 61px;
                background-color: #fff;
                padding: 31px 43px;
                border-radius: 10px;
                border: 1px solid #DBE1E1;

                @include respondTo(tablet){
                    margin-top: 16px;
                    padding: 0;
                    padding-top: 18px;
                    border: none;
                    border-radius: 0;
                }
                
                >section{
                    border-bottom: 1px solid #DBE1E1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @include respondTo(tablet){
                        margin: 0;
                    }
                    
                    >div{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-bottom: 14px;

                        color: #666666;
                        font-size: 14px;
                        font-weight: 500;

                        cursor: pointer;

                        width: 50%;

                        @include respondTo(tablet){
                            padding-left: 8px;
                            padding-right: 8px;
                        }
                        
                        &.active{
                            padding-bottom: 11px;
                            border-bottom: 3px solid #17948C;
                            color: #17948C;
                        }
                    }
                }

                >div{
                    margin-top: 37px;

                    @include respondTo(tablet){
                        margin-top: 24px;
                        margin-bottom: 103px;
                        padding: 0 16px;
                    }
                    
                    >span{
                        color: #323232;
                        font-size: 14px;
                    }


                    >ol{
                        @include respondTo(tablet){
                            padding-left: 30px;
                        }

                        >li{
                            color: #323232;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}