.detailReservasi {
	display: flex;
	justify-content: space-between;
	padding: 48px 113px;

	@media #{$tabletMQ} {
		flex-direction: column;
		padding: 0;
	}

	&__left {
		@media #{$tabletMQ} {
			width: 100%;
		}
		width: 65%;

		&__banner {
			@media #{$tabletMQ} {
				border-radius: 0;
				margin-bottom: 0;
				z-index: 0;
				position: relative;
			}

			margin-bottom: 32px;
			display: flex;
			border-radius: 20px;
			width: 100%;
			min-height: 229px;
			background-color: #ddd;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			&__image {
				border-radius: 20px;

				@media #{$tabletMQ} {
					border-radius: 0;
					margin-bottom: 0;
					display: none;
				}

				> img {
					border-radius: 20px;
					position: relative;
					height: 230px;
				}

				> div {
					position: absolute;
					top: 190px;
					left: 27%;

					> img {
						position: absolute;
						left: 16px;
						top: 12px;
					}

					> div {
						position: absolute;
						left: 76px;
						top: 12px;

						> p {
							display: flex;
							align-items: center;

							> div {
								margin-right: 9px;
								font-style: normal;
								font-weight: normal;
								font-size: 8px;
								line-height: 17px;
								letter-spacing: 0.01em;
								color: #000000;
							}

							> span {
								font-style: normal;
								font-weight: normal;
								font-size: 8px;
								line-height: 17px;
								letter-spacing: 0.01em;
								color: #828282;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								max-width: 6rem;
							}
						}

						> h2 {
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 17px;
							letter-spacing: 0.01em;
							color: #000000;
							margin-top: 12px;
						}
					}
				}
			}

			&__slide {
				border-radius: 20px;
				display: none;

				@media #{$tabletMQ} {
					border-radius: 0;
					display: block;
				}

				&__container {
					position: relative;

					> img {
						position: relative;
					}

					> div {
						position: absolute;
						top: 40px;
						left: 20%;

						> svg {
							display: block !important;
						}

						> img {
							position: absolute;
							left: 16px;
							top: -90px;
							width: 8%;
							object-fit: none;
						}

						> div {
							position: absolute;
							left: 76px;
							top: 12px;

							> p {
								display: flex;
								align-items: center;

								> div {
									margin-right: 9px;
									font-style: normal;
									font-weight: normal;
									font-size: 8px;
									line-height: 17px;
									letter-spacing: 0.01em;
									color: #000000;
								}

								> span {
									font-style: normal;
									font-weight: normal;
									font-size: 8px;
									line-height: 17px;
									letter-spacing: 0.01em;
									color: #828282;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									max-width: 6rem;
								}
							}

							> h2 {
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 17px;
								letter-spacing: 0.01em;
								color: #000000;
								margin-top: 12px;
							}
						}
					}
				}
			}
		}

		&__information {
			@media #{$tabletMQ} {
				display: none;
			}
			width: 100%;
			background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
			border-radius: 20px;
			padding: 32px 48px;

			&__title {
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 36px;
				display: flex;
				align-items: center;
				letter-spacing: 0.03em;
				color: #000000;
				margin-bottom: 47px;
			}

			&__reservationid {
				display: flex;
				justify-content: space-between;
				margin-bottom: 47px;

				&__title {
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 17px;
					letter-spacing: 0.01em;
					color: #495050;
				}

				&__id {
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 24px;
					letter-spacing: 0.03em;
					color: #828282;
				}
			}

			&__paymentTitle {
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #495050;
				margin-bottom: 16px;
			}

			&__paymentInfo {
				display: flex;
				justify-content: center;
				margin-bottom: 30px;

				&__container {
					background: #d1f1ef;
					border-radius: 10px;
					padding: 16px 32px;
					text-align: center;
					width: 100%;

					> p {
						font-style: normal;
						font-weight: 600;
						font-size: 12px;
						line-height: 17px;
						text-align: center;
						letter-spacing: 0.03em;
						color: #052523;
						margin-bottom: 10px;
					}

					> div {
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 17px;
						text-align: center;
						letter-spacing: 0.01em;
						color: #052523;
					}
				}
			}

			&__workTitle {
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #495050;
			}

			&__timeline {
				display: flex;
				justify-content: center;
				margin-top: 40px;
				align-items: flex-start;
				margin-bottom: 10px;

				&__wrap {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 138px;

					&__item {
						border: 1px solid #bdbdbd;
						box-sizing: border-box;
						border-radius: 19px;
						width: 138px;
						height: 52px;
						padding: 8px 32px;

						@media #{$tabletMQ} {
							padding: 8px 10px;
							height: 60px;
						}

						> p {
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 19px;
							text-align: center;
							letter-spacing: 0.01em;

							color: #000000;
						}
					}

					&__desc {
						font-style: normal;
						font-weight: 500;
						font-size: 10px;
						line-height: 15px;
						text-align: center;
						letter-spacing: 0.01em;
						color: #828282;
						margin-top: 10px;
					}
				}

				&__divider {
					border: 1px solid #bdbdbd;
					width: 34px;
					height: 0px;
					margin-top: 30px;
				}
			}
		}
	}

	&__bookingdetail {
		&__line {
			display: none;

			@media #{$tabletMQ} {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 10px;
			}

			> div {
				width: 42px;
				height: 2px;
				background: #bdbdbd;
				border-radius: 2px;
			}
		}

		&__reservasionID {
			display: none;

			@media #{$tabletMQ} {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 16px;
			}

			> div {
				padding: 6px 18px;
				background: #d5f6e3;
				border-radius: 10px;

				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 14px;
				letter-spacing: 0.03em;
				color: #27ae60;
			}

			> p {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.03em;
				color: #828282;
			}
		}

		@media #{$tabletMQ} {
			width: 100%;
			box-shadow: none;
			margin-top: -25px;
			position: relative;
			padding: 10px 16px;
			height: auto;
		}

		width: 33%;
		background: #ffffff;
		box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.2);
		border-radius: 20px;
		padding: 31px 23px;
		height: 454px;

		&__guestTitle {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			letter-spacing: 0.03em;
			color: #333333;
		}

		&__guestContent {
			margin-top: 12px;
			background: #ffffff;
			border: 1px solid #e0e0e0;
			box-sizing: border-box;
			border-radius: 10px;
			padding: 2px 18px 18px 18px;
			margin-bottom: 32px;

			@media #{$tabletMQ} {
				margin-bottom: 16px;
			}

			&__item {
				display: flex;
				align-items: center;
				margin-top: 12px;

				> p {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 17px;
					display: flex;
					align-items: center;
					letter-spacing: 0.01em;
					color: #000000;
				}

				> div {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 17px;
					display: flex;
					align-items: center;
					letter-spacing: 0.01em;
					color: #828282;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 14rem;
				}
			}
		}

		&__classTitle {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			letter-spacing: 0.03em;
			color: #333333;
		}

		&__informationTitle {
			display: none;

			@media #{$tabletMQ} {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
				letter-spacing: 0.03em;
				color: #333333;
				display: block;
				margin: 16px 0;
			}
		}

		&__classContent {
			margin-top: 12px;
			background: #ffffff;
			border: 1px solid #e0e0e0;
			box-sizing: border-box;
			border-radius: 10px;
			padding: 18px;
			display: flex;
			align-items: center;

			&__icon {
				width: 62px;
				height: 62px;
				background: #e9fcfa;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 24px;
				border-radius: 62px;
			}

			&__info {
				> p {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 19px;
					letter-spacing: 0.03em;
					color: #000000;
					mix-blend-mode: normal;
				}

				&__venue {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 19px;
					letter-spacing: 0.03em;
					color: #828282;
					mix-blend-mode: normal;
					margin-top: 12px;
					margin-bottom: 15px;
				}

				&__time {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					margin-bottom: 15px;
					margin-left: -4px;

					> img {
						margin-left: -4px;
						margin-right: 9px;
					}

					> p {
						margin-right: 7px;
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 14px;
						letter-spacing: 0.03em;
						color: #2a2a2a;
					}

					> div {
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 18px;
						letter-spacing: 0.01em;
						color: #2a2a2a;
						mix-blend-mode: normal;
						opacity: 0.4;
					}
				}

				&__date {
					display: flex;
					align-items: center;
					justify-content: flex-start;

					> img {
						margin-right: 12px;
					}

					> p {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 14px;
						letter-spacing: 0.03em;
						color: #2a2a2a;
					}
				}
			}
		}

		&__infoContent {
			display: none;

			@media #{$tabletMQ} {
				margin-top: 12px;
				background: #ffffff;
				border: 1px solid #e0e0e0;
				box-sizing: border-box;
				border-radius: 10px;
				padding: 18px 18px 36px 18px;
				display: block;
				margin-bottom: 32px;
			}

			> p {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #000000;
				margin-top: 16px;
			}

			> span {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.01em;
				color: #828282;
				margin-top: 10px;
			}

			&__status {
				background: #d1f1ef;
				border-radius: 10px;
				padding: 30px 30px;
				margin-top: 22px;
				margin-bottom: 7px;

				> p {
					font-style: normal;
					font-weight: 600;
					font-size: 12px;
					line-height: 17px;
					text-align: center;
					letter-spacing: 0.03em;
					color: #052523;
				}

				> div {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 17px;
					text-align: center;
					letter-spacing: 0.01em;
					color: #052523;
					margin-top: 10px;
				}
			}

			&__step {
				margin-top: 31px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				&__shape {
					width: 45%;
					display: flex;
					justify-content: space-between;
					flex-direction: column;
					align-items: center;

					> div {
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 19px;
						text-align: center;
						letter-spacing: 0.01em;

						color: #000000;

						border: 1px solid #bdbdbd;
						box-sizing: border-box;
						border-radius: 19px;
						padding: 8px 31px;
					}

					> span {
						height: 34px;
						width: 1px;
						border: 1px solid #bdbdbd;
					}
				}

				&__desc {
					width: 50%;
					display: flex;
					justify-content: space-between;
					flex-direction: column;

					> p {
						font-style: normal;
						font-weight: 500;
						font-size: 10px;
						line-height: 15px;
						letter-spacing: 0.01em;
						color: #828282;
					}

					> span {
						height: 62px;
					}
				}
			}
		}
	}
}
