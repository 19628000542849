.cta-download {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 110px 25px 90px;

    background-size: cover;
    background-repeat: no-repeat;

    @include respondTo(tablet) {
        background-position: 100%;
    }


    &__desc {

        font-weight: 400;
        font-size: 41px;
        color: #fff;
        margin-bottom: 40px;

        @include respondTo(tablet) {
            font-size: 38px;
        }

        >span:nth-of-type(1) {
            font-weight: 600;
        }

        >span:nth-of-type(2) {
            font-weight: 500;
        }
    }


    &__download {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        span {
            color: #fff;
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 14px;
        }

        >div {
            display: flex;

            @include respondTo(phone) {
                flex-direction: column;
            }

            >a {
                &:not(:last-child) {
                    margin-right: 21px;

                    @include respondTo(tablet) {
                        margin-right: unset;
                        margin-bottom: 21px;
                    }
                }
            }

            img {
                align-self: center;

                @include respondTo(phone) {
                    align-self: flex-start;
                }
            }
        }
    }
}