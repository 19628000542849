.healthy {
  background: #17948C;
  position: relative;
  padding: 124px 165px;
  overflow: hidden;

  @include respondTo(tablet) {
    padding: 48px 16px;
  }
}

.healthy__asset {
  img {
    position: absolute;
    height: 464px;
    width: auto;
    object-fit: contain;

    @include respondTo(tablet) {
      height: 160.05px;
      width: auto;
    }

    &:nth-of-type(1) {
      top: -7.5rem;
      right: 20.5rem;

      @include respondTo(tablet) {
        top: unset;
        bottom: 1rem;
        right: 7.5rem;
        z-index: 1;
      }
    }

    &:nth-of-type(2) {
      bottom: -6rem;
      right: 5rem;

      @include respondTo(tablet) {
        bottom: -2rem;
        right: 2rem;
      }
    }
  }
}

.healthy__header {
  margin-bottom: 48px;
  width: 50%;

  @include respondTo(tablet) {
    width: 100%;
    margin-bottom: 30px;
    z-index: 2;
    position: relative;
  }
}

.healthy__action {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #FFFFFF;

  @include respondTo(tablet) {
    display: flex;
    flex-direction: column;
  }

  span {
    display: block;
    margin-bottom: 8px;
  }

  img {
    width: 135px;
    height: 40px;
    object-fit: cover;

    @include respondTo(tablet) {
      width: 108.61px;
      height: 32px;
    }

    &:first-of-type {
      margin-right: 12px;


      @include respondTo(tablet) {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
}

.text__healthy-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 48px;
  color: #FFFFFF;

  @include respondTo(tablet) {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
    font-weight: 600;
  }
}

.text__healthy-info {
  font-size: 18px;
  line-height: 20px;
  color: #FFFFFF;
  margin-top: 8px;

  @include respondTo(tablet) {
    font-size: 12px;
    line-height: 20px;
  }
}

.text__healthy-action {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #FFFFFF;
  display: inline-block;

  @include respondTo(tablet) {
    font-size: 14px;
    margin-bottom: 0.8rem !important;
    line-height: 20px;
    width: 119px;
  }
}