* {
   box-sizing: border-box;
   margin: 0px;
   font-family: "Poppins", sans-serif;
}

*:focus {
   outline: none;
}

#zmmtg-root {
   // display: none;

   height: auto;
   position: static;
   // background-color : transparent
}

html {
   overflow: auto !important;
   min-width: 0 !important;
   height: auto !important;
   width: auto !important;
   font-size: 16px;
}

body {
   background: #fafafa;
   overflow: auto !important;
   min-width: 0 !important;
   height: auto !important;
   width: auto !important;
}

a {
   text-decoration: none !important;
   color: inherit;
}

button {
   border: none;
   background-color: transparent;
   cursor: pointer;
   padding: 0;
}

input {
   margin: 0;
   border: none;
   background-color: transparent;

   /* Chrome, Safari, Edge, Opera */
   &::-webkit-outer-spin-button,
   &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }

   /* Firefox */
   &[type=number] {
      -moz-appearance: textfield;
   }
}

h1,
h2,
h3,
h4,
h5,
h6 {
   margin: 0;
}