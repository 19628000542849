.custom-scroll {
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #C4C4C4;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        @media #{$tabletMQ} {
            display: none;
        }
    }
}